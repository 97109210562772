import { iterateShadowDomDeep } from './iterateShadowDomDeep';

type UnsubscribeIframe = () => void;
export type IframeEventsManagerListeners = [
  keyof DocumentEventMap,
  (event: any) => void,
  {
    attachToShadowDom?: boolean;
    capture?: boolean;
  }
][];

class IframeEventsManager {
  private iframes: Map<HTMLIFrameElement, UnsubscribeIframe> = new Map();

  constructor(options: {
    entryPoint: Document;
    listeners: IframeEventsManagerListeners;
  }) {
    this.collectIframes(options.entryPoint);
    this.addListeners(options.listeners);
  }

  /**
   * Collect all "ready" iframes in a document
   */
  private collectIframes(doc: Document) {
    const docIframes = doc.querySelectorAll('iframe');
    for (const iframe of docIframes) {
      // check if iframe is ready
      if (this.isIframeDocumentReady(iframe)) {
        this.iframes.set(iframe, () => undefined);
        this.collectIframes(iframe.contentDocument as Document);
      }
    }
  }

  private isIframeDocumentReady(iframe: HTMLIFrameElement) {
    try {
      return (
        iframe.contentDocument &&
        iframe.contentDocument.readyState === 'complete'
      );
    } catch (error) {
      /**
       * User could embed custom <iframe /> in EDIT mode,
       * THEN we may have no access to it.
       */
      return false;
    }
  }

  /**
   * Add event listeners to collected iframes
   */
  private addListeners(listeners: IframeEventsManagerListeners): void {
    if (this.iframes.size > 0) {
      for (const iframe of this.iframes.keys()) {
        if (iframe.contentDocument) {
          const shadowDomListeners: IframeEventsManagerListeners = [];
          for (const listener of listeners) {
            iframe.contentDocument.addEventListener(
              listener[0],
              listener[1],
              typeof listener[2].capture === 'boolean'
                ? listener[2].capture
                : true
            );
            if (listener[2].attachToShadowDom) {
              shadowDomListeners.push(listener);
            }
          }

          // Attach listeners to shadow dom
          const unsubscribeShadowDomListeners: (() => void)[] = [];
          if (shadowDomListeners.length) {
            const attachListenersToShadowDOM = (
              target: Document | ShadowRoot
            ) => {
              for (const listener of shadowDomListeners) {
                target.addEventListener(
                  listener[0],
                  listener[1],
                  typeof listener[2].capture === 'boolean'
                    ? listener[2].capture
                    : true
                );
                unsubscribeShadowDomListeners.push(() =>
                  target.removeEventListener(
                    listener[0],
                    listener[1],
                    typeof listener[2].capture === 'boolean'
                      ? listener[2].capture
                      : true
                  )
                );
              }
            };
            iterateShadowDomDeep(attachListenersToShadowDOM)(
              iframe.contentDocument
            );
          }

          this.iframes.set(iframe, () => {
            if (iframe?.contentDocument) {
              for (const listener of listeners) {
                iframe.contentDocument.removeEventListener(
                  listener[0],
                  listener[1],
                  typeof listener[2].capture === 'boolean'
                    ? listener[2].capture
                    : true
                );
              }
              unsubscribeShadowDomListeners.forEach((unsubscribe) =>
                unsubscribe()
              );
            }
          });
        }
      }
    }
  }

  /**
   * Remove all listeners and clear collected iframes
   */
  public destroy(): void {
    this.iframes.forEach((unsubscribe) => {
      unsubscribe();
    });
    this.iframes.clear();
  }
}

export default IframeEventsManager;
