import { CSSProperties, FC, useMemo, memo, ReactNode } from 'react';
import cx from 'classnames';

import { DisplayConfigType } from '../../../features/project/project.types';
import styles from './RendererImageSizeContainer.module.css';

/**
 * The `RendererImageSizeContainer` component is a static <div> element
 * specifically designed to wrap the `RendererImage` component.
 *
 * This container acts as a visual frame for the `RendererImage`,
 * ensuring its dimensions and placement remain consistent,
 * contributing to a polished and organized layout.
 */
interface RendererImageSizeContainerProps {
  dimensions: {
    width: number;
    height: number;
  };
  rendererDimensions: {
    width: number;
    height: number;
  };
  rendererMargin: DisplayConfigType['renderer_margin'];
  children: ReactNode;
  isFullscreen?: boolean;
}
const RendererImageSizeContainer: FC<RendererImageSizeContainerProps> = (
  props
) => {
  const {
    children,
    rendererMargin,
    dimensions,
    rendererDimensions,
    isFullscreen = false,
  } = props;
  const rootStyles = useMemo<CSSProperties>(() => {
    const renderAspectRatio =
      dimensions.width && dimensions.height
        ? `${dimensions.width} / ${dimensions.height}`
        : undefined;
    return {
      '--renderer-fit-into': rendererMargin ? 'contain' : 'fill',
      '--renderer-margin': rendererMargin,
      '--renderer-aspect-ratio': renderAspectRatio,
    } as CSSProperties;
  }, [rendererMargin, dimensions]);
  const matchesRendererSize =
    (dimensions.width === rendererDimensions.width &&
      dimensions.height === rendererDimensions.height) ||
    (dimensions.width / dimensions.height).toFixed(2) ===
      (rendererDimensions.width / rendererDimensions.height).toFixed(2);
  const className = cx(styles.root, {
    [styles.rootFitInto]: rendererMargin !== null, // TODO: explain the idea behind it.
    [styles.rootPageMatchesRenderer]: matchesRendererSize && !isFullscreen,
  });

  return (
    <div className={className} style={rootStyles}>
      {children}
    </div>
  );
};

export default memo(RendererImageSizeContainer);
