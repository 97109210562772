import {
  PageLink,
  PageLinkExternal,
  PageLinkInternal,
  ParticularPageLink,
} from './pageLink.types';
import { PageId } from '../projectPages/projectPages.types';

export const isPageLinkAvailableInPage = (
  link: PageLink,
  pageId: PageId
): boolean => {
  return (
    (!isParticularPageLink(link) || link.options.pageId === pageId) &&
    (isExternalPageLink(link) ||
      (isInternalPageLink(link) && !isSelfTargetInternalPageLink(link, pageId)))
  );
};

export const isPageLinkAvailableInEditorPage = (
  link: PageLink,
  pageId: PageId
): boolean => {
  return !isParticularPageLink(link) || link.options.pageId === pageId;
};

export const isParticularPageLink = (
  link: PageLink
): link is ParticularPageLink => {
  return typeof link.options?.pageId === 'string';
};

export const isExternalPageLink = (
  link: PageLink
): link is PageLinkExternal => {
  return link.type === 'external';
};

export const isInternalPageLink = (
  link: PageLink
): link is PageLinkInternal => {
  return link.type === 'page';
};

export const isSelfTargetInternalPageLink = (
  link: PageLinkInternal,
  pageId: PageId
): boolean => {
  return link.target === pageId;
};

export const getPrioritizedPageLink = (
  link1: PageLink,
  link2: PageLink
): PageLink => {
  if (isParticularPageLink(link2) && !isParticularPageLink(link1)) {
    return link2;
  }

  return link1;
};

export const isAHrefSelector = (selector: string): boolean => {
  const isAHrefLink = /^a\[href(\^)?="\S+"\](,a\[href="\S+"\])?$/;
  return Boolean(selector.match(isAHrefLink));
};
