import { FC, ReactNode } from 'react';
import cx from 'classnames';

import { BaseWidget } from '../../../features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isModalLikeWidget,
} from '../../../features/widgets/widgets.utils';
import styles from './WidgetFooterLayout.module.css';

interface WidgetFooterLayoutProps {
  widget: BaseWidget;
  children: ReactNode;
  hasPrev: boolean;
  id?: string;
}

function WidgetFooterLayout({
  widget,
  children,
  hasPrev,
  id,
}: WidgetFooterLayoutProps): JSX.Element {
  return (
    <div
      id={id}
      aria-label="Widget Footer Layout"
      className={cx(styles.root, {
        [styles.rootModalLike]: isModalLikeWidget(widget),
        [styles.rootTooltipLike]: isAnchorBasedWidget(widget),
        [styles.rootNoPrev]: !hasPrev,
      })}
    >
      {children}
    </div>
  );
}

const GridArea: FC<{ type: 'buttons' | 'prev' | 'step' }> = ({
  children,
  type,
}) => {
  return (
    <div aria-label={`Grid Area with type of ${type}`} className={styles[type]}>
      {children}
    </div>
  );
};
WidgetFooterLayout.Area = GridArea;

export default WidgetFooterLayout;
