import Icon from 'shared-components/src/components/Icon';
import Tooltip from 'shared-components/src/components/Tooltip';

import styles from './Watermark.module.css';

const Watermark: React.VFC<{
  href: string;
  testId?: string;
  tooltipDisabled?: boolean;
}> = (props) => {
  const { tooltipDisabled = false, href, testId } = props;

  return (
    <Tooltip placement="top-start" triggerOn="hover" disabled={tooltipDisabled}>
      <Tooltip.Trigger>
        <a
          href={href}
          target="_blank"
          rel="noreferrer noopener"
          className={styles.watermark}
          data-testid={testId}
          aria-label='Watermark "Try Storylane Free"'
          id="storylane_watermark_logo_horizontal"
        >
          <Icon name="logo-horizontal" className={styles.icon} />
        </a>
      </Tooltip.Trigger>
      <Tooltip.Content variant="dark">
        <div className={styles.tooltipFontSize}>Try Storylane Free</div>
      </Tooltip.Content>
    </Tooltip>
  );
};

export default Watermark;
