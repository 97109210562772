import cx from 'classnames';
import { FC } from 'react';

import styles from './WidgetBackdropAnimation.module.css';

interface WidgetBackdropAnimationProps {
  in: boolean;
  delayIn: number;
  durationIn: number;
  durationOut: number;
  delayOut?: number;
}

/**
 *
 * @returns
 * @see {@link https://storylane.atlassian.net/l/cp/K6ajNZNA Docs}
 */
const WidgetBackdropAnimation: FC<WidgetBackdropAnimationProps> = ({
  delayIn,
  delayOut = 0,
  durationIn,
  durationOut,
  children,
  in: inFlag,
}) => {
  return (
    <div
      className={cx(styles.root, {
        [styles.rootMounted]: inFlag,
      })}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--widget-animation-delay-in': `${delayIn}ms`,
        '--widget-animation-delay-out': `${delayOut}ms`,
        '--widget-animation-duration-in': `${durationIn}ms`,
        '--widget-animation-duration-out': `${durationOut}ms`,
      }}
    >
      {children}
    </div>
  );
};

export default WidgetBackdropAnimation;
