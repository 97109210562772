const observeIntersection = (
  args: {
    element: Element;
    cb: (data: IntersectionObserverEntry[], isInitial: boolean) => void;
    triggerOnce?: boolean;
  } & IntersectionObserverInit
): (() => void) | never => {
  // feature detection
  if (!('IntersectionObserver' in window)) {
    throw new Error('IntersectionObserver is not available');
  }

  const {
    element,
    cb,
    threshold,
    root,
    rootMargin,
    triggerOnce = false,
  } = args;
  const config: IntersectionObserverInit = {
    root,
    rootMargin,
    threshold,
  };
  let isInitial = true;
  let isTriggered = false;
  const observer = new IntersectionObserver((data) => {
    // check if observer should be trigger once only
    if (triggerOnce && isTriggered) {
      observer.disconnect();
      return;
    }
    isTriggered = true;

    // invoke callback
    cb(data, isInitial);
    isInitial = false;
  }, config);
  observer.observe(element);

  return () => {
    observer.disconnect();
  };
};

export default observeIntersection;
