import {
  useMemo,
  useRef,
  CSSProperties,
  useState,
  useEffect,
  VFC,
} from 'react';
import cx from 'classnames';
import {
  BaseWidget,
  WidgetId,
} from 'shared-components/src/features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isHotspot,
  isTooltip,
} from 'shared-components/src/features/widgets/widgets.utils';
import { isLightColor } from 'shared-components/src/utils/isLightColor';

import styles from './WidgetCircleAnimation.module.css';
import { DemoPlayerWidget } from '../../types';
import { WidgetAnimationTranslationDelayMs } from '../../constants';

interface WidgetDotAnimationProps {
  widget: DemoPlayerWidget<BaseWidget>;
}

interface RootStyles extends CSSProperties {
  '--translate-x'?: string;
  '--translate-y'?: string;
  '--widget-circle-animation-duration'?: string;
}

/**
 * @returns
 * @see {@link https://storylane.atlassian.net/l/cp/K6ajNZNA Docs}
 */
const WidgetCircleAnimation: VFC<WidgetDotAnimationProps> = ({ widget }) => {
  const previousWidget = useRef<DemoPlayerWidget<BaseWidget> | null>(null);
  const currentWidget = useRef(widget);
  if (widget.id !== currentWidget.current.id) {
    previousWidget.current = currentWidget.current;
  }
  currentWidget.current = widget;

  const circleRef = useRef<HTMLDivElement>(null);
  const offset = useMemo<{
    x: number; // x%
    y: number; // y%
  }>(() => {
    return isAnchorBasedWidget(widget)
      ? widget.autoOffset ?? widget.options.root.offset
      : {
          x: 50, // 50%
          y: 50, // 50%
        };
  }, [widget]);

  const [transitionEndId, setTransitionEndId] = useState<WidgetId | null>(null);
  useEffect(() => {
    const transitionElement = circleRef.current?.parentElement;
    if (transitionElement) {
      const transitionEndCallback = () => {
        setTransitionEndId(currentWidget.current.id);
      };
      transitionElement.addEventListener(
        'transitionend',
        transitionEndCallback
      );
      return () => {
        transitionElement.removeEventListener(
          'transitionend',
          transitionEndCallback
        );
      };
    }
  }, []);

  /**
   * We need to show circle translation only between "arrow" widgets.
   * "hotspot" => "tooltip"
   * "tooltip" => "tooltip"
   * "hotspot" => "hotspot"
   * "hotspot" => "tooltip"
   *
   *  if `widget` is hotspot, then it stays visible.
   *  if `widget` is tooltip, then it should fade-out after circle reaches it's position.
   */
  const isBetweenAnchorWidgets =
    previousWidget.current &&
    isAnchorBasedWidget(previousWidget.current) &&
    isAnchorBasedWidget(widget);

  const isHotspotFirst = previousWidget.current === null && isHotspot(widget);

  // Tooltip doesn't have a beacon, so after translation is done — it should fade-out.
  const translationToTooltipIsFinished =
    isTooltip(widget) && transitionEndId === widget.id;

  const isVisible =
    (isBetweenAnchorWidgets || isHotspotFirst) &&
    !translationToTooltipIsFinished;

  const circleStyles = useMemo(() => {
    return cx(styles.circle, {
      [styles.circleHidden]: !isVisible,
    });
  }, [isVisible]);
  const circleColor =
    transitionEndId === currentWidget.current.id || isHotspotFirst
      ? getCircleColor(currentWidget.current)
      : previousWidget.current
      ? getCircleColor(previousWidget.current)
      : undefined;

  const circleOpacity = getCircleOpacity(currentWidget.current);

  return (
    <div
      className={styles.root}
      style={
        {
          '--translate-x': offset ? `${offset.x}%` : undefined,
          '--translate-y': offset ? `${offset.y}%` : undefined,
          '--widget-circle-color': circleColor,
          '--widget-circle-opacity': circleOpacity,
          '--widget-circle-animation-duration': `${WidgetAnimationTranslationDelayMs}ms`, // widget appear animation DELAY is the time we can use to playback current animation
        } as RootStyles
      }
    >
      <div ref={circleRef} className={circleStyles} />
    </div>
  );
};

export default WidgetCircleAnimation;

const getCircleOpacity = (widget: BaseWidget) => {
  if (isHotspot(widget)) {
    return widget.options.root.beaconOpacity || 1;
  }

  return 1;
};

function getCircleColor(widget: BaseWidget) {
  if (isHotspot(widget)) {
    return widget.options.root.beaconColor;
  }

  if (isTooltip(widget)) {
    if (!isLightColor(widget.options.root.backgroundColor)) {
      return widget.options.root.backgroundColor;
    }

    if (widget.cta) {
      return widget.options.cta.backgroundColor.hex;
    }
  }

  return undefined;
}
