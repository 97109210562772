import {
  useRef,
  useEffect,
  useState,
  useCallback,
  CSSProperties,
  VFC,
} from 'react';
import flow from 'lodash-es/flow';
import cx from 'classnames';

import { usePrevious } from '../../../hooks/react-use/usePrevious';
import IframeLoadManager from '../../../utils/IframeLoadManager';
import cssStyles from './RendererHtmlNew.module.css';
import {
  disableInputAutocomplete,
  injectHtmlIntoIframe,
  isAnchorElement,
  removeNoscriptElements,
} from '../../../utils/dom';
import { injectRendererStyles } from '../../../features/renderer/renderer.utils';
import IframeEventsManager from '../../../utils/IframeEventsManager';
import { rendererIframeName } from '../../../features/renderer/renderer.constants';
import { iterateShadowDomDeep } from '../../../utils/iterateShadowDomDeep';

interface RendererHtmlProps {
  // TODO: replace with `value` and `defaultValue` props
  content: string;
  /**
   * Util flag to force React component re-render when `content` prop doesn't change.
   * We need it to handle page duplicates(when different pages have same `content` prop).
   */
  contentKey: string;
  styles?: CSSProperties;
  refCB?: (node: HTMLIFrameElement) => void;
  onlyMountInject?: boolean;
  isResetScroll?: boolean;
  isPreventDefaultClick?: boolean;
  isPointerEventsDisabled?: boolean;
  onReady?: (isReady: boolean) => void;
  /**
   * `onDocumentLoad` is invoked every time any document is loaded. (root document + "nested" iframes document)
   */
  onDocumentLoad?: (loadedDocument: Document) => Document;
  injectUtilStyles?: boolean;
  isShadowDomEnabled?: boolean;
}

const RendererHtmlNew: VFC<RendererHtmlProps> = ({
  styles = {},
  refCB,
  content,
  contentKey,
  onDocumentLoad,
  onlyMountInject = false,
  isPreventDefaultClick = false,
  isPointerEventsDisabled = false,
  isResetScroll = true,
  onReady,
  injectUtilStyles,
  isShadowDomEnabled = false,
}) => {
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  const secondaryIframeRef = useRef<HTMLIFrameElement | null>(null);
  const [isSecondaryIframeVisible, setIsSecondaryIframeVisible] =
    useState(false);

  const [isReady, setIsReady] = useState<boolean>(false);
  const isFirstInject = useRef(true);
  const prevIsReady = usePrevious(isReady);

  useEffect(() => {
    if (!isFirstInject.current) {
      setIsSecondaryIframeVisible(true);
    }

    if (secondaryIframeRef.current && isFirstInject.current) {
      injectHtmlIntoIframe({
        iframeElement: secondaryIframeRef.current,
        html: content,
      });
    }

    if (iframeRef.current && (!onlyMountInject || isFirstInject.current)) {
      // inject content into iframe
      setIsReady(false);
      injectHtmlIntoIframe({ iframeElement: iframeRef.current, html: content });

      isFirstInject.current = false;
    }
  }, [content, contentKey, onDocumentLoad, onlyMountInject]);

  useEffect(() => {
    if (isResetScroll && iframeRef.current) {
      iframeRef.current.contentDocument?.defaultView?.scrollTo(0, 0); // reset scroll on html injection
    }
  }, [isResetScroll, onlyMountInject, contentKey]);

  useEffect(() => {
    /**
     * set `isReady` flag when all documents are loaded.
     * Process every loaded document(add styles, remove noscripts, ...)
     */
    if (iframeRef.current?.contentDocument && !isReady) {
      const processDocumentAndShadowDomFnCreator =
        (opts: { isShadowDomEnabled: boolean }) =>
        (processFn: (d: Document | ShadowRoot) => unknown) => {
          return (doc: Document) => {
            processFn(doc);
            if (opts.isShadowDomEnabled) {
              iterateShadowDomDeep(processFn)(doc);
            }
            return doc;
          };
        };
      const processDocumentAndSDWrapper = processDocumentAndShadowDomFnCreator({
        isShadowDomEnabled,
      });
      const processFunctions: ((d: Document) => Document)[] = [
        processDocumentAndSDWrapper(removeNoscriptElements),
        processDocumentAndSDWrapper(disableInputAutocomplete),
      ];
      if (injectUtilStyles) {
        processFunctions.push(
          processDocumentAndSDWrapper(injectRendererStyles)
        );
      }
      if (onDocumentLoad) {
        processFunctions.push(onDocumentLoad);
      }
      const processDocument: (d: Document) => Document = flow(processFunctions);
      processDocument(iframeRef.current.contentDocument);
      const manager = new IframeLoadManager({
        entryPoint: iframeRef.current.contentDocument,
        onReady: () => {
          setIsReady(true);
          setIsSecondaryIframeVisible(false);
          if (secondaryIframeRef.current) {
            injectHtmlIntoIframe({
              iframeElement: secondaryIframeRef.current,
              html: content,
            });
          }
        },
        onDocumentLoad: (d: Document) => processDocument(d),
      });

      return () => {
        manager.destroy();
      };
    }
    // TODO: iframeRef.contentDocument doesn't change when new "content" injection happens, so we need `content` and `contentKey` deps here
  }, [
    content,
    contentKey,
    isReady,
    onDocumentLoad,
    injectUtilStyles,
    isShadowDomEnabled,
  ]);

  useEffect(() => {
    /**
     * Prevent "default" click behavior in a root document and all "nested"
     * when renderer `isReady`.
     */
    const iframeDocument = iframeRef.current?.contentDocument;
    if (iframeDocument) {
      const preventDefaultClickListener = (e: MouseEvent) => {
        if (
          isPreventDefaultClick ||
          e
            .composedPath()
            .find((element) => isAnchorElement(element as Element))
        ) {
          e.preventDefault();
          e.stopPropagation();
        }
      };
      const preventDefaultSubmitListener = (e: SubmitEvent) => {
        e.preventDefault();
        e.stopPropagation();
      };
      let manager: IframeEventsManager | null = null;
      iframeDocument.addEventListener('click', preventDefaultClickListener);
      iframeDocument.addEventListener('submit', preventDefaultSubmitListener);
      manager = new IframeEventsManager({
        entryPoint: iframeDocument,
        listeners: [
          ['click', preventDefaultClickListener, { attachToShadowDom: false }],
          [
            'submit',
            preventDefaultSubmitListener,
            { attachToShadowDom: false },
          ],
        ],
      });
      return () => {
        if (iframeDocument) {
          iframeDocument.removeEventListener(
            'click',
            preventDefaultClickListener
          );
          iframeDocument.removeEventListener(
            'submit',
            preventDefaultSubmitListener
          );
        }
        if (manager) {
          manager.destroy();
          manager = null;
        }
      };
    }
    // TODO: iframeRef.current.contentDocument doesn't change when new "content" injection happens, so we need `content` and `contentKey` deps here
  }, [content, contentKey, isReady, isPreventDefaultClick]);

  useEffect(() => {
    if (onReady && prevIsReady !== isReady) {
      onReady(isReady);
    }
  }, [onReady, isReady, prevIsReady]);

  const handleSetRef = useCallback(
    (node: HTMLIFrameElement) => {
      iframeRef.current = node;
      if (refCB) refCB(node);
    },
    [refCB]
  );

  return (
    <div className={cssStyles.framesWrapper}>
      <iframe
        className={cx(cssStyles.iframe, {
          [cssStyles.pointerEventsDisabled]: isPointerEventsDisabled,
        })}
        sandbox="allow-same-origin allow-scripts allow-modals"
        ref={handleSetRef}
        style={styles}
        /**
         * `sl-renderer` name is used to retrieve window from `window.frames` by name.
         */
        name={rendererIframeName}
        data-testid="renderer-iframe"
      />
      <iframe
        className={cx(cssStyles.secondaryIframe, cssStyles.iframe, {
          [cssStyles.secondaryIframeVisible]: isSecondaryIframeVisible,
        })}
        ref={secondaryIframeRef}
        sandbox="allow-same-origin allow-scripts allow-modals"
        style={styles}
      />
    </div>
  );
};

export default RendererHtmlNew;
