import {
  createContext,
  Dispatch,
  SetStateAction,
  MutableRefObject,
} from 'react';

type VideoContextType = {
  isPlaying: boolean;
  setIsPlaying: Dispatch<SetStateAction<boolean>>;
  isMuted: boolean;
  setIsMuted: Dispatch<SetStateAction<boolean>>;
  currentTime: number;
  currentTimeRef: MutableRefObject<number>;
  setCurrentTime: Dispatch<SetStateAction<number>>;
  playbackRate: number;
  setPlaybackRate: Dispatch<SetStateAction<number>>;
  isHlsVideo: boolean; // handle live streaming videos (.m3u8)
  duration: number;
  setDuration: Dispatch<SetStateAction<number>>;
  hasAudio: boolean;
  setHasAudio: Dispatch<SetStateAction<boolean>>;
};

const VideoContext = createContext<VideoContextType>({
  isPlaying: false,
  setIsPlaying: () => undefined,
  isMuted: false,
  setIsMuted: () => undefined,
  currentTime: 0,
  currentTimeRef: {
    current: 0,
  },
  setCurrentTime: () => undefined,
  playbackRate: 1,
  setPlaybackRate: () => undefined,
  isHlsVideo: false,
  duration: 0,
  setDuration: () => undefined,
  hasAudio: false,
  setHasAudio: () => undefined,
});

export default VideoContext;
