import { useState, useMemo } from 'react';

import { useDebounce } from './react-use/useDebounce';
import {
  PageLink,
  PageLinkId,
  PageLinkWithId,
} from '../features/pageLink/pageLink.types';
import { ProjectPageId } from '../features/projectPages/projectPages.types';
import { getElementByUniqueSelector } from '../utils/unique-selector';
import {
  getPrioritizedPageLink,
  isAHrefSelector,
  isPageLinkAvailableInPage,
  isPageLinkAvailableInEditorPage,
} from '../features/pageLink/pageLink.util';
import { iterateDocumentDeep } from '../utils/iterateDocumentDeep';

interface useLinkedElementsArgs {
  links: Record<PageLinkId, PageLink>;
  pageId: ProjectPageId;
  rendererElement: HTMLIFrameElement | null;
  key?: number;
  activeElement?: Element | null;
  debounceDelay?: number;
  /**
   * If true, then "self-target internal" page links are ignored.
   *
   * @see: src/features/pageLink/pageLink.util.ts `isSelfTargetInternalPageLink`
   */
  isSelfTargetInternalPageLinkDisabled?: boolean;
  shadowDom?: boolean;
}

export type LinkedElementsMap = Map<Element, PageLinkWithId>;

/**
 * Search for elements which match "page-link"-s selectors.
 * @param args
 */
// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function useLinkedElements(args: useLinkedElementsArgs) {
  const {
    pageId,
    rendererElement,
    key,
    links,
    activeElement = null,
    debounceDelay = 1000, // 1s
    isSelfTargetInternalPageLinkDisabled,
    shadowDom = false,
  } = args;
  const [isReady, setIsReady] = useState(false);
  const [linkedElements, setLinkedElements] = useState<LinkedElementsMap>(
    new Map()
  );

  // get links that can be used in a current page
  const currentPageLinks = useMemo(() => {
    return Object.keys(links)
      .map((id) => ({
        id,
        ...links[id],
      }))
      .filter((link) =>
        isSelfTargetInternalPageLinkDisabled
          ? isPageLinkAvailableInPage(link, pageId)
          : isPageLinkAvailableInEditorPage(link, pageId)
      );
  }, [links, pageId, isSelfTargetInternalPageLinkDisabled]);

  useDebounce(
    () => {
      if (rendererElement?.contentDocument) {
        const linkedElementsMap: LinkedElementsMap = new Map();
        const addElementToMap = (el: Element, link: PageLinkWithId) => {
          // element that matches page link's unique selector is found and has dimensions
          if (el /* && hasDims(el) && isElementVisible(el)*/) {
            const anotherPageLink = linkedElementsMap.get(el); // element may also match another page link's unique selector
            // first matching encounted OR current link is prioritized
            if (
              !anotherPageLink ||
              getPrioritizedPageLink(anotherPageLink, link) === link
            ) {
              linkedElementsMap.set(el, link);
            }
          }
        };
        currentPageLinks.forEach((link) => {
          if (isAHrefSelector(link.selector)) {
            // use document deep iterator to visit all elements and try a link search
            const elements: Element[] = [];
            iterateDocumentDeep({
              doc: rendererElement.contentDocument as Document,
              cb: (d: Document | ShadowRoot) => {
                elements.push(...d.querySelectorAll(link.selector));
              },
              options: {
                iterateShadowDom: shadowDom,
              },
            });
            elements.forEach((el) => addElementToMap(el, link));
          } else {
            const element = rendererElement.contentDocument
              ? getElementByUniqueSelector(
                  link.selector,
                  rendererElement.contentDocument
                )
              : null;
            if (element) {
              addElementToMap(element, link);
            }
          }
        });
        setLinkedElements(linkedElementsMap);
        setIsReady(true);
      }
    },
    debounceDelay,
    [rendererElement, key, currentPageLinks, shadowDom]
  );

  const linkedElement = useMemo(() => {
    if (activeElement) {
      if (linkedElements.has(activeElement)) {
        return activeElement;
      }

      for (const linkedElement of linkedElements.keys()) {
        if (linkedElement.contains(activeElement)) {
          return linkedElement;
        }
      }
    }

    return null;
  }, [linkedElements, activeElement]);

  return {
    isReady,
    linkedElement,
    linkedElements,
    pageLink: activeElement ? linkedElements.get(activeElement) : null,
  };
}

export default useLinkedElements;
