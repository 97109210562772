import { parseHtmlString } from './parseHtmlString';
import isScriptElement from './isScriptElement';
import { idAttrName } from '../features/ui/ui.constants';

/**
 * @see {@link https://storylane.atlassian.net/l/cp/LVs4C416 Docs}
 *
 */
export const injectProjectScriptsIntoDocument = (
  targetDocument: Document,
  script: {
    id: string;
    content: string;
    position: 'head' | 'body' | 'iframe_head' | 'iframe_body';
  }
): void => {
  if (targetDocument.querySelector(`[${idAttrName}="${script.id}"]`)) {
    console.error('Trying to inject script with same id multiple times');
    return;
  }

  /**
   * An HTMLCollection in the HTML DOM is live;
   * it is automatically updated when the underlying document is changed.
   * For this reason it is a good idea to make a copy (e.g., using Array.from) to iterate over if adding, moving, or removing nodes.
   */
  const parsedHtmlElements = Array.from(
    parseHtmlString(
      `<div id="util-script-content">${script.content}</div>`
    ).getElementById('util-script-content')?.children || []
  );

  for (
    let childIndex = 0;
    childIndex < parsedHtmlElements.length;
    childIndex++
  ) {
    const element = parsedHtmlElements[childIndex];
    let appendedElement = element;

    // script elements parsed from html-string are not-executable, so we need to create a new one in memory and inject content into it
    if (isScriptElement(element)) {
      const scriptInnerHtml = element.innerHTML;
      const scriptSrc = element.attributes.getNamedItem('src')?.value;
      const scriptElement = targetDocument.createElement('script');
      scriptElement.setAttribute(idAttrName, script.id);
      if (scriptSrc) {
        scriptElement.setAttribute('src', scriptSrc);
      }
      if (scriptInnerHtml) {
        scriptElement.innerHTML = scriptInnerHtml;
      }
      appendedElement = scriptElement;
    }

    switch (script.position) {
      case 'head':
      case 'iframe_head':
        targetDocument.head.appendChild(appendedElement);
        break;
      case 'iframe_body':
      case 'body':
        targetDocument.body.appendChild(appendedElement);
        break;
    }
  }
};

export default injectProjectScriptsIntoDocument;
