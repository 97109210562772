import { ExtUrlTarget } from '../components/DemoPlayer/DemoPlayer';
import { DemoPlayerLead } from '../types';
import { createEventFn } from './emitter';

export const requestPageChangeEvent = createEventFn<{
  pageId: string;
}>('requestPageChange');

export const recordingStartReadyEvent = createEventFn<{
  pageId: string;
}>('recordingStartReady');

export const pageChangeEvent = createEventFn<{
  pageId: string;
}>('pageChange');

export const flowChangeEvent = createEventFn<{
  flowId: string;
}>('flowChange');

export const lastWidgetEnterEvent = createEventFn<{
  flowId: string;
  widgetId: string;
}>('lastWidgetEnter');

export const flowlistItemClickEvent = createEventFn<{
  itemId: string;
}>('flowlistItemClick');

export const widgetChangeEvent = createEventFn<{
  widgetId: string;
}>('widgetChange');

export const widgetReadyEvent = createEventFn<{
  widgetId: string;
}>('widgetReady');

export const widgetCtaClickEvent = createEventFn<{
  widgetId: string;
}>('widgetCtaClick');

export const widgetSecondaryClickEvent = createEventFn<{
  widgetId: string;
}>('widgetSecondaryClick');

export const customCtaClickEvent = createEventFn<{
  data: { url: string; target: ExtUrlTarget };
  widgetId?: string;
  projectId?: string;
}>('customCtaClick');

export const leadCaptureEvent = createEventFn<{
  inputLead: DemoPlayerLead;
}>('leadCapture');

export const walkthroughFinishedEvent = createEventFn<void>(
  'walkthroughFinished'
);

export const demoplayerEventCreators = {
  requestPageChangeEvent,
  flowChangeEvent,
  recordingStartReadyEvent,
  lastWidgetEnterEvent,
  flowlistItemClickEvent,
  widgetChangeEvent,
  pageChangeEvent,
  widgetReadyEvent,
  widgetCtaClickEvent,
  walkthroughFinishedEvent,
  leadCaptureEvent,
  widgetSecondaryClickEvent,
  customCtaClickEvent,
};
