import replace from 'lodash-es/replace';
import {
  addDays,
  addHours,
  addMinutes,
  addSeconds,
  addMonths,
  addYears,
  subDays,
  subHours,
  subMinutes,
  subSeconds,
  subMonths,
  subYears,
  format,
} from 'date-fns';

import {
  SubstitutionDateOperationUnit,
  SubstitutionDateOperationType,
  SubstitutionsTextDict,
} from './substitution.types';
import {
  substitutionCustomReg,
  substitutionKeyBreadcrumb,
  substitutionKeyBreadcrumbReg,
  substitutionVariableReg,
} from './substitution.constants';

export type VisitedSubstitutionsDict = Record<string, string | null>;

/**
 * Replace all custom substitutions {{...}} in provided html string
 * @param htmlString raw html string which contains substitutions {{...}}
 * @param substitutions substitutions dictionary
 * @param emptyReplacer If `true`, Then replace with empty string; If `false`, Then leave it as it is; Otherwise apply function to replace substring
 * @param addHtmlBreadcrumb If `true`, Then add extra html comment before the replaced string, so it can be found later with a NodeIterator
 * @returns html string
 */
export function fillCustomSubstitutions(
  htmlString: string,
  substitutions: Record<string, string>,
  emptyReplacer?: boolean | ((key: string) => string),
  addHtmlBreadcrumb?: boolean
): { result: string; substitutions: VisitedSubstitutionsDict } {
  const replacer =
    (visitedSubstitutions: VisitedSubstitutionsDict) => (key: string) => {
      visitedSubstitutions[key] = substitutions[key] ?? null;
      // If no substitution value for a key
      if (!substitutions[key]) {
        if (typeof emptyReplacer === 'function') {
          return emptyReplacer(key);
        } else if (emptyReplacer === true) {
          return '';
        }
        return key;
      }
      if (addHtmlBreadcrumb) {
        return replaceSubstitutionKeyWithHtmlCommentBreadcrumb(
          key,
          substitutions[key]
        );
      }
      return substitutions[key];
    };
  const visitedSubstitutions = {};
  const result = replace(
    htmlString,
    substitutionCustomReg,
    replacer(visitedSubstitutions)
  );

  return { result, substitutions: visitedSubstitutions };
}
export const replaceSubstitutionKeyWithHtmlComment = (key: string): string =>
  `<!--${key}-->`;

export const replaceSubstitutionKeyWithHtmlCommentBreadcrumb = (
  key: string,
  value: string
): string => `<!--${substitutionKeyBreadcrumb}-${key}-->${value}`;

export const retrieveSubstitutionKeyFromBreadcrumbHtmlComment = (
  value: string
) => replace(value, substitutionKeyBreadcrumbReg, (...match) => match[1]);

const mutateSubstitutionDate =
  (date: Date) =>
  (unit: SubstitutionDateOperationUnit | undefined) =>
  (type: SubstitutionDateOperationType) =>
  (value: number) => {
    if (unit === SubstitutionDateOperationUnit.Hour) {
      return (type === SubstitutionDateOperationType.Add ? addHours : subHours)(
        date,
        value
      );
    } else if (
      unit === SubstitutionDateOperationUnit.Day ||
      typeof unit === 'undefined'
    ) {
      return (type === SubstitutionDateOperationType.Add ? addDays : subDays)(
        date,
        value
      );
    } else if (unit === SubstitutionDateOperationUnit.Minute) {
      return (
        type === SubstitutionDateOperationType.Add ? addMinutes : subMinutes
      )(date, value);
    } else if (unit === SubstitutionDateOperationUnit.Second) {
      return (
        type === SubstitutionDateOperationType.Add ? addSeconds : subSeconds
      )(date, value);
    } else if (unit === SubstitutionDateOperationUnit.Month) {
      return (
        type === SubstitutionDateOperationType.Add ? addMonths : subMonths
      )(date, value);
    } else if (unit === SubstitutionDateOperationUnit.Year) {
      return (type === SubstitutionDateOperationType.Add ? addYears : subYears)(
        date,
        value
      );
    }
    return date;
  };

/**
 * Replace {{$...}} with a date
 * @param htmlString raw html string which contains substitutions {{$...}}
 * @returns html string
 */
export function fillDateSubstitutions(htmlString: string): string {
  return replace(
    htmlString,
    substitutionVariableReg,
    /**
     * Replace date-variable with a current date according to format.
     * @see: https://gitlab.com/storylane-devs/product/-/wikis/Date-substitution
     * @param match full match. E.g. yyyy-MM-dd|+5
     * @param g1 date format group. E.g. yyyy-MM-dd
     * @param g2 date add/subtract group. E.g. |+5
     * @returns
     */
    (
      match,
      g1: string,
      g2: string,
      operationUnit: SubstitutionDateOperationUnit | undefined
    ) => {
      try {
        let date = new Date();
        /**
         * Handle add/subtract days.
         * e.g. g2 === "|-5", then g2[1] is a sign "+"|"-"
         */
        if (g2) {
          const operationText = g2.replaceAll(' ', '');
          const operationType =
            operationText[1] as SubstitutionDateOperationType;
          const operationValue = parseInt(operationText.substring(2));

          date =
            mutateSubstitutionDate(date)(operationUnit)(operationType)(
              operationValue
            );
        }

        /**
         * Handle format shortcuts.
         * @see: https://gitlab.com/storylane-devs/product/-/wikis/Date-substitution
         * e.g. g1 === "LT"
         */
        switch (g1) {
          case 'LT': {
            return format(date, 'h:mm a');
          }
          case 'LTS': {
            return format(date, 'h:mm:ss a');
          }
          case 'l': {
            return format(date, 'M/d/yyyy');
          }
          case 'll': {
            return format(date, 'MMM d, yyyy');
          }
          default:
            return format(date, g1);
        }
      } catch (error) {
        console.error(error);
        return match;
      }
    }
  );
}

export const fillAllSubstitutions = (
  ...args: Parameters<typeof fillCustomSubstitutions>
): { result: string; substitutions: VisitedSubstitutionsDict } => {
  const fillCustomSubstitutionsResult = fillCustomSubstitutions(...args);
  return {
    result: fillDateSubstitutions(fillCustomSubstitutionsResult.result),
    substitutions: fillCustomSubstitutionsResult.substitutions,
  };
};

/**
 * In-text substitution keys should be wrapper with {{...}} in order to be replaced
 * @param key substitution key
 * @param isVariable flag
 */
export function wrapSubstitutionKey(key: string, isVariable?: boolean): string {
  return `{{${isVariable ? '$' : ''}${key}}}`;
}

export const getDateSubstitutionsDict = (): {
  key: string;
  value: string;
}[] => [
  {
    key: 'LT',
    value: format(new Date(), 'h:mm a'),
  },
  {
    key: 'LTS',
    value: format(new Date(), 'h:mm:ss a'),
  },
  {
    key: 'M/d/yyyy',
    value: format(new Date(), 'M/d/yyyy'),
  },
  {
    key: 'M/d/yy|+1',
    value: format(addDays(new Date(), 1), 'M/d/yy'),
  },
  {
    key: 'MMM d',
    value: format(new Date(), 'MMM d'),
  },
  {
    key: 'MMM d, yyyy',
    value: format(new Date(), 'MMM d, yyyy'),
  },
  {
    key: 'eee',
    value: format(new Date(), 'eee'),
  },
  {
    key: 'eeee',
    value: format(new Date(), 'eeee'),
  },
];

/**
 * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Lead-substitutions
 * @param lead captured lead in "public demo" page
 * @returns
 */
export const getSubstitutionsFromLead = (
  lead: {
    email?: string;
    externalId?: string;
  } & Partial<Record<string, string>>
): SubstitutionsTextDict => {
  const leadSubstitutions: SubstitutionsTextDict = {};

  /**
   * If there is "name" AND no "first_name|last_name", then split "name" by space;
   * If there are "first_name|last_name" AND no "name", then concatenate "first_name" with "last_name"
   */
  if (lead.name && !lead.first_name && !lead.last_name) {
    lead.first_name = lead.name.split(' ').slice(0, 1).join(' ');
    lead.last_name = lead.name.split(' ').slice(1).join(' ');
  } else if (!lead.name && lead.first_name && lead.last_name) {
    lead.name = `${lead.first_name} ${lead.last_name}`;
  }

  for (const [key, value] of Object.entries(lead)) {
    if (!value) continue; // skip empty values
    leadSubstitutions[wrapSubstitutionKey(`lead.${key}`)] = value;
    leadSubstitutions[wrapSubstitutionKey(key)] = value;
  }
  return leadSubstitutions;
};
