import { memo, CSSProperties, VFC } from 'react';
import cx from 'classnames';

import styles from './WidgetButton.module.css';

export interface WidgetButtonProps {
  value: string;
  multiline?: boolean;
  className?: string;
  style?: CSSProperties;
  onChange?: never;
  disabled?: never;
  placeholder?: never;
}

const WidgetButton: VFC<WidgetButtonProps> = (props) => {
  const { value, className, multiline, ...restProps } = props;

  return value.length !== 0 ? (
    <div
      className={cx(className, { [styles.singleLine]: !multiline })}
      dangerouslySetInnerHTML={{ __html: value }}
      {...restProps}
    />
  ) : null;
};

export default memo(WidgetButton);
