import { useCallback, useEffect, useMemo } from 'react';
import { WidgetOffset } from 'shared-components/src/features/widgets/widgets.types';
import { getElementByUniqueSelector } from 'shared-components/src/utils/unique-selector';
import {
  getNestedBoundingClientRect,
  hasDims,
} from 'shared-components/src/utils/dom';
import useRendererHtmlObservers from 'shared-components/src/components/renderer/RendererHtml/useRendererHtmlObservers';
import {
  scrollIntoView,
  getScrollableAncestors,
  isIntersectRect,
} from 'shared-components/src/utils/scroller';
import observeIntersection from 'shared-components/src/utils/IntersectionObserver';

import { DemoPlayerArrowWidgetId } from '../../../constants';

interface useHtmlAnchorPositionProps {
  rendererElement: HTMLIFrameElement;
  anchorElementSelector: string;
  offset: WidgetOffset;
  offsetFromOriginPx: WidgetOffset;
  widgetPositionElement: HTMLDivElement | null;
  onPositionChange: (values: {
    '--translate-x': string;
    '--translate-y': string;
  }) => void;
  isAnimated: boolean;
}

/**
 * `useHtmlAnchorPosition` returns boolean flag that indicates if anchor element was not found(available) in the renderer's document.
 *
 * @return
 *
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/9109505/Scrolling+widget+into+view  Docs}
 */
export const useHtmlAnchorPosition = ({
  rendererElement,
  anchorElementSelector,
  offset,
  offsetFromOriginPx,
  widgetPositionElement,
  onPositionChange,
  isAnimated,
}: useHtmlAnchorPositionProps): boolean => {
  const rendererDocument = rendererElement.contentDocument;

  const anchorElement = useMemo(
    () =>
      rendererDocument
        ? getElementByUniqueSelector(anchorElementSelector, rendererDocument)
        : null,
    [rendererDocument, anchorElementSelector]
  );

  /**
   * Reposition widget by updating element styles attribute on "scroll", "resize", "animationend"
   */
  const handleReposition = useCallback(() => {
    if (!widgetPositionElement) return;

    if (!anchorElement || !hasDims(anchorElement)) {
      // if no acnhor element OR no dimensions(usually hidden by display: none) - show in the center
      onPositionChange({
        '--translate-x': 'calc(50vw - 50%)',
        '--translate-y': 'calc(50vh - 50%)',
      });
      return;
    }

    if (!rendererDocument?.defaultView) {
      // if anchor point is not in viewport - hide widget
      onPositionChange({
        '--translate-x': '-999px',
        '--translate-y': '-999px',
      });
      return;
    }

    // calculate widget position styles and make it visible
    const anchorDims = getNestedBoundingClientRect(
      anchorElement,
      rendererDocument.defaultView
    );
    onPositionChange({
      '--translate-x': `${Math.round(
        anchorDims.left +
          offsetFromOriginPx.x +
          (offset.x * anchorDims.width) / 100
      )}px`,
      '--translate-y': `${Math.round(
        anchorDims.top +
          offsetFromOriginPx.y +
          (offset.y * anchorDims.height) / 100
      )}px`,
    });
  }, [
    anchorElement,
    widgetPositionElement,
    rendererDocument?.defaultView,
    offset,
    offsetFromOriginPx,
    onPositionChange,
  ]);

  useEffect(() => {
    if (!widgetPositionElement) return; // do nothing

    if (!anchorElement) {
      // If anchor element is not found, then place widget in the center of the demoplayer
      handleReposition();
    } else {
      try {
        // Listen initial intersection to scroll widget into view --- START
        const unobserveInit = observeIntersection({
          element: anchorElement,
          root: null,
          rootMargin: getRootMarginFromWidget(
            widgetPositionElement,
            offsetFromOriginPx
          ),
          threshold: 0,
          triggerOnce: true,
          cb: (data) => {
            handleReposition();
            if (
              isScrollRequired(
                data[0],
                anchorElement,
                offset,
                offsetFromOriginPx
              )
            ) {
              scrollWidgetAndAnchorIntoView({
                anchorElement,
                anchorInnerOffset: offset,
                offsetFromOriginPx,
                positionElement: widgetPositionElement,
                isAnimated,
              });
            }
          },
        });
        // Listen initial intersection to scroll widget into view --- FINISH

        return () => {
          unobserveInit();
        };
      } catch (error) {
        scrollWidgetAndAnchorIntoView({
          anchorElement,
          anchorInnerOffset: offset,
          offsetFromOriginPx,
          positionElement: widgetPositionElement,
          isAnimated,
        });
      }
    }
  }, [
    anchorElement,
    handleReposition,
    offset,
    offsetFromOriginPx,
    rendererDocument,
    widgetPositionElement,
    isAnimated,
  ]);

  useRendererHtmlObservers({
    rendererElement,
    onFire: handleReposition,
  });

  return !anchorElement;
};

function isScrollRequired(
  data: IntersectionObserverEntry,
  anchorElement: Element,
  innerOffset: WidgetOffset,
  offsetFromOriginPx: WidgetOffset
) {
  // anchor element is fully out of an IntersectionRect
  if (!data.isIntersecting) {
    return true;
  }

  const anchorDims = data.boundingClientRect; // anchor element dims
  const anchorTopOffset =
    anchorDims.top +
    offsetFromOriginPx.y +
    (anchorDims.height * innerOffset.y) / 100;
  const anchorLeftOffset =
    anchorDims.left +
    offsetFromOriginPx.x +
    (anchorDims.width * innerOffset.x) / 100;
  const anchorInnerPoint = {
    top: anchorTopOffset,
    bottom: anchorTopOffset,
    left: anchorLeftOffset,
    right: anchorLeftOffset,
  }; // rect point

  // check if point is out of IntersectionRect
  if (!isIntersectRect(anchorInnerPoint, data.intersectionRect)) {
    return true;
  }

  // only part of an anchor element is VISIBLE + anchor point is VISIBLE
  if (data.intersectionRatio < 1) {
    // element is smaller than the first scrollable ancestor
    const [firstScrollableAncestor] = getScrollableAncestors(
      anchorElement,
      window.document.scrollingElement || window.document.documentElement
    );
    const visibleHeight = firstScrollableAncestor
      ? firstScrollableAncestor.clientHeight
      : anchorElement.ownerDocument.defaultView?.visualViewport?.height || 0;
    const visibleWidth = firstScrollableAncestor
      ? firstScrollableAncestor.clientWidth
      : anchorElement.ownerDocument.defaultView?.visualViewport?.width || 0;
    if (
      visibleWidth >= anchorDims.width &&
      visibleHeight >= anchorDims.height
    ) {
      return true;
    }
    // biggest part of the anchor element is visible
    else if (data.intersectionRatio < 0.5) {
      return true;
    }
  }

  return false;
}

/**
 * Clalculate intersection observer `rootMargin` value from a widget element
 *
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/9109505/Scrolling+widget+into+view#1.-Check-if-scroll-is-needed  Docs}
 */
function getRootMarginFromWidget(
  widget: Element,
  offsetFromOriginPx: WidgetOffset
) {
  /**
   * get widget wrapper box dimensions
   */
  const dims = widget.getBoundingClientRect();
  /**
   * get widget body dimensions after transform applied based on `widget.options.root.align`
   */
  const innerDims = widget
    .querySelector(`#${DemoPlayerArrowWidgetId}`)
    ?.getBoundingClientRect?.() || {
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    x: 0,
    y: 0,
  };

  // viewport 20% dimensions
  const viewport20PercentDims = {
    height: (window?.visualViewport?.height || window.innerHeight) / 5,
    width: (window?.visualViewport?.width || window.innerWidth) / 5,
  };

  // dims & innerDims are expected to have same width & height
  const widgetInnerOffset = {
    x: innerDims.x - dims.x,
    y: innerDims.y - dims.y,
  };
  const rootMargin = {
    top: Math.max(
      viewport20PercentDims.height,
      Math.abs(Math.min(0, widgetInnerOffset.y + offsetFromOriginPx.y))
    ),
    right: Math.max(
      viewport20PercentDims.width,
      Math.abs(
        Math.max(0, widgetInnerOffset.x + dims.width + offsetFromOriginPx.x)
      )
    ),
    bottom: Math.max(
      viewport20PercentDims.height,
      Math.abs(
        Math.max(0, widgetInnerOffset.y + dims.height + offsetFromOriginPx.y)
      )
    ),
    left: Math.max(
      viewport20PercentDims.width,
      Math.abs(Math.min(0, widgetInnerOffset.x + offsetFromOriginPx.x))
    ),
  };

  return `-${rootMargin.top}px -${rootMargin.right}px -${rootMargin.bottom}px -${rootMargin.left}px`;
}

/**
 * Perform suitable scroll.
 *
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/9109505/Scrolling+widget+into+view#2.-(Optional)-Perform-animated-scroll  Docs}
 */
function scrollWidgetAndAnchorIntoView(arg: {
  /**
   * `positionElement` is a wrapper for a widget(arrow, body, ...) element.
   * It is used to place a widget next to "anchor-element" in a viewport.
   */
  positionElement: Element;
  /**
   * `anchorInnerOffset` is a point within an "anchor-element".
   * User clicked there to place the widget. Widget points to this place.
   */
  anchorInnerOffset: WidgetOffset;
  /**
   * `offsetFromOriginPx` is an offset from `anchorInnerOffset` presented in pixels.
   */
  offsetFromOriginPx: WidgetOffset;
  /**
   * `anchorElement` is an element associated with a "tooltip"|"hotspot" widget.
   */
  anchorElement: Element;
  isAnimated: boolean;
}) {
  const {
    positionElement,
    offsetFromOriginPx,
    anchorInnerOffset,
    anchorElement,
    isAnimated,
  } = arg;
  /**
   * `positionElement` inner div applies `transform: translate`(based on `widget.options.root.align`) on a widget.
   * This "inner-shift" is needed to connect "anchor-element" with a widget pointing arrow.
   */
  const {
    x: positionElementX,
    y: positionElementY,
    width: positionElementWidth,
    height: positionElementHeight,
  } = positionElement.getBoundingClientRect();
  const { x: widgetX = 0, y: widgetY = 0 } =
    positionElement
      .querySelector(`#${DemoPlayerArrowWidgetId}`)
      ?.getBoundingClientRect?.() || {};
  // dims & innerDims are expected to have same width & height
  const widgetInnerOffset = {
    x: widgetX - positionElementX,
    y: widgetY - positionElementY,
  };

  // Calculate "shared rectangle" dimensions
  const { width: anchorElementWidth, height: anchorElementHeight } =
    anchorElement.getBoundingClientRect();
  const xMax = Math.max(
    anchorElementWidth,
    (anchorElementWidth * anchorInnerOffset.x) / 100 +
      widgetInnerOffset.x +
      offsetFromOriginPx.x +
      positionElementWidth
  );
  const xMin = Math.min(
    0,
    (anchorElementWidth * anchorInnerOffset.x) / 100 +
      widgetInnerOffset.x +
      offsetFromOriginPx.x
  );
  const yMax = Math.max(
    anchorElementHeight,
    (anchorElementHeight * anchorInnerOffset.y) / 100 +
      widgetInnerOffset.y +
      offsetFromOriginPx.y +
      positionElementHeight
  );
  const yMin = Math.min(
    0,
    (anchorElementHeight * anchorInnerOffset.y) / 100 +
      widgetInnerOffset.y +
      offsetFromOriginPx.y
  );
  const sharedWidth = xMax - xMin;
  const sharedHeight = yMax - yMin;
  const sharedCenterX = xMin + (xMax - xMin) / 2;
  const sharedCenterY = yMin + (yMax - yMin) / 2;

  const [firstScrollableAncestor] = getScrollableAncestors(
    anchorElement,
    window.document.scrollingElement || window.document.documentElement
  );
  const visibleHeight = firstScrollableAncestor
    ? firstScrollableAncestor.clientHeight
    : anchorElement.ownerDocument.defaultView?.visualViewport?.height || 0;
  const visibleWidth = firstScrollableAncestor
    ? firstScrollableAncestor.clientWidth
    : anchorElement.ownerDocument.defaultView?.visualViewport?.width || 0;

  let innerOffset: WidgetOffset;
  let offset: WidgetOffset;
  if (visibleWidth >= sharedWidth && visibleHeight >= sharedHeight) {
    // "first scrollable ancestor" is BIGGER than "shared rectangle"
    innerOffset = {
      x: 50,
      y: 50,
    };
    offset = {
      x: sharedCenterX - anchorElementWidth / 2,
      y: sharedCenterY - anchorElementHeight / 2,
    };
  } else {
    // "first scrollable ancestor" is SMALLER than "shared rectangle"
    innerOffset = anchorInnerOffset;
    offset = offsetFromOriginPx;
  }

  scrollIntoView({
    targetElement: anchorElement,
    boundaryDocument: window.document,
    isAnimated,
    innerOffset,
    scrollSpeed: 1000,
    extraOffsetInPx: offset,
  });
}
