/**
 * @see {@link https://storylane.atlassian.net/wiki/x/CgAI Docs}
 */
export const StorylaneLeadSubmitMessage = 'storylane-lead-submit';

/**
 * CustomEvent that we no longer use, but have to support because of some old forms
 * @deprecated
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/524298/Vendor+Lead+Form#%5BDEPRECATED%5D-Custom-Event Docs}
 */
export const StorylaneLeadFormSubmitEvent = 'storylane-lead-form-submit';

export const StorylaneDivId = 'sl-lead-form-root';
export const StorylaneDivWidth = 400;
