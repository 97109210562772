import reduce from 'lodash-es/reduce';
import assign from 'lodash-es/assign';
import isObject from 'lodash-es/isObject';
import isArray from 'lodash-es/isArray';

export function flattenObject(
  obj: Record<string, unknown>,
  parentKey = '',
  sep = '_'
) {
  try {
    return reduce(
      obj,
      (acc, value, key) => {
        const newKey = parentKey ? `${parentKey}${sep}${key}` : key;

        if (isObject(value) && !isArray(value)) {
          assign(
            acc,
            flattenObject(value as Record<string, unknown>, newKey, sep)
          );
        } else if (
          typeof value === 'string' ||
          typeof value === 'number' ||
          typeof value === 'boolean'
        ) {
          acc[newKey] = value;
        }
        return acc;
      },
      {} as Record<string, unknown>
    );
  } catch (error) {
    console.error(error);
    return {};
  }
}

export default flattenObject;
