import { CSSProperties } from 'react';
import cx from 'classnames';
import { FlowlistItem } from 'shared-components/src/features/flowlist/flowlist.types';
import Icon from 'shared-components/src/components/Icon';
import Tooltip from 'shared-components/src/components/Tooltip';

import styles from './Item.module.css';

interface ItemProps {
  item: FlowlistItem;
  position: number;
  isActive: boolean;
  completed: boolean;
  onClick: () => void;
  style?: CSSProperties;
  completedStepsCount: number;
  totalStepsCount: number;
  onReplayClick?: VoidFunction;
}

function Item({
  item,
  position,
  isActive,
  onClick,
  style,
  completed,
  totalStepsCount,
  completedStepsCount,
  onReplayClick,
}: ItemProps): JSX.Element {
  return (
    <div
      className={cx(styles.item, {
        [styles.itemActive]: isActive,
        [styles.itemNoDesc]: !item.description,
        [styles.itemCompleted]: completed,
      })}
      onClick={onClick}
      style={style}
      data-testid={`flowlist-item-${item.id}`}
      aria-label={item.name}
      id={`flowlist-item-${item.id}`}
    >
      <div className={styles.overlay} />
      <div
        className={cx(styles.circle, {
          [styles.circleActive]: isActive,
        })}
      >
        {position}
      </div>
      <div className={styles.textBox}>
        <div className={styles.name}>{item.name}</div>
        {item.description && (
          <div className={styles.description}>{item.description}</div>
        )}
      </div>
      <div className={styles.replayStepWrapper}>
        {Boolean(totalStepsCount) && (
          <div className={styles.progress}>
            {Math.min(completedStepsCount, totalStepsCount)}/{totalStepsCount}
          </div>
        )}

        {completedStepsCount > 0 && (
          <Tooltip triggerOn="hover" placement="top">
            <Tooltip.Trigger>
              <Icon
                data-testid={`flowlist-item-${item.id}-replay`}
                className={styles.replayIcon}
                name="replay-frame"
                onClick={onReplayClick}
              />
            </Tooltip.Trigger>
            <Tooltip.Content variant="dark">Replay</Tooltip.Content>
          </Tooltip>
        )}
      </div>
    </div>
  );
}

export default Item;
