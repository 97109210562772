import { FC } from 'react';
import { CSSTransition } from 'react-transition-group';

import styles from './CenterElementWithOverlay.module.css';

interface CenterElementWithOverlayProps {
  enabled: boolean;
  children: React.ReactNode;
  onExit?: VoidFunction;
}

const CenterElementWithOverlay: FC<CenterElementWithOverlayProps> = ({
  enabled,
  children,
  onExit,
}) => (
  <CSSTransition
    in={enabled}
    mountOnEnter
    unmountOnExit
    classNames={{
      enter: styles.rootEnter,
      enterActive: styles.rootEnterActive,
      exit: styles.rootExit,
      exitActive: styles.rootExitActive,
    }}
    timeout={{
      enter: 500,
      exit: 300,
    }}
    onExit={onExit}
  >
    <div className={styles.root}>{children}</div>
  </CSSTransition>
);

export default CenterElementWithOverlay;
