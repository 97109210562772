import { FC } from 'react';

import styles from './Beacon.module.css';

const Beacon: FC = ({ children }) => {
  return (
    <div
      id="hotspot-beacon"
      aria-label="HotSpot Beacon"
      className={styles.root}
    >
      {children}
    </div>
  );
};

export default Beacon;
