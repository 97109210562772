import { CSSProperties } from 'react';

interface UseDemoZoomParams {
  position: {
    x: number;
    y: number;
  };
  container: HTMLElement | null;
  zoom: number | null;
  enabled: boolean;
}

export function useDemoZoom(params: UseDemoZoomParams) {
  const zoom = Math.max(params.zoom ?? 1, 1);

  const zoomOffsetX = params.position.x * zoom;
  const zoomOffsetY = params.position.y * zoom;
  const zoomDiff = { x: 0, y: 0 };
  if (zoomOffsetX > 50) {
    const afterZoomX = Math.max(zoomOffsetX - (zoom - 1) * 100, 50);
    zoomDiff.x = afterZoomX - zoomOffsetX;
  }
  if (zoomOffsetY > 50) {
    const afterZoomY = Math.max(zoomOffsetY - (zoom - 1) * 100, 50);
    zoomDiff.y = afterZoomY - zoomOffsetY;
  }

  const styles: CSSProperties = {
    transform: params.enabled
      ? `translate3d(${zoomDiff.x}%, ${zoomDiff.y}%, 0) scale(${zoom})`
      : `translate3d(0px, 0px, 0) scale(1)`,
    willChange: 'transform',
  };

  return { styles };
}
