import cx from 'classnames';
import { VFC } from 'react';

import commonStyles from './WidgetFooterSteps.module.css';

type WidgetFooterStepsProps = {
  color: string;
  curStep: number;
  totalSteps: number;
  className?: string;
};

const WidgetFooterSteps: VFC<WidgetFooterStepsProps> = ({
  color,
  curStep,
  totalSteps,
  className,
}) => {
  return (
    <div
      className={cx(commonStyles.stepsCount, className)}
      style={{
        color,
      }}
    >
      <span className={commonStyles.currentStep}>{curStep}</span>/{totalSteps}
    </div>
  );
};

export default WidgetFooterSteps;
