import { CSSProperties, VFC } from 'react';

import { WidgetHotspotBeaconId } from './WidgetHotspotBeacon.constants';
import styles from './WidgetHotspotBeacon.module.css';

interface BeaconCssProperties extends CSSProperties {
  '--color': string | undefined;
  '--opacity': number | undefined;
}

interface BeaconProps {
  color: string;
  opacity?: number;
}

const Beacon: VFC<BeaconProps> = ({ color, opacity }) => {
  return (
    <div
      className={styles.beacon}
      style={
        {
          '--color': color,
          '--opacity': opacity,
        } as BeaconCssProperties
      }
    >
      <div className={styles.beaconCenter} id={WidgetHotspotBeaconId} />
      <div className={styles.beaconRipple} />
    </div>
  );
};

export default Beacon;
