import { FlowId } from 'shared-components/src/features/flow/flow.types';
import { FlowlistItemTargetKind } from 'shared-components/src/features/flowlist/flowlist.constants';
import { ProjectPageId } from 'shared-components/src/features/projectPages/projectPages.types';

import {
  DemoPlayerFlowlist,
  DemoPlayerFlowsDict,
  DemoPlayerPageLinksDict,
  DemoPlayerProjectPage,
  DemoPlayerWidgetsDict,
} from '../types';

/**
 * Calculate a preload-queue of project pages.
 * See: https://gitlab.com/storylane-devs/product/-/wikis/Demo-Player-preloading-of-pages
 * @param props
 */
export function generatePageIdSequence(props: {
  startPageId: ProjectPageId;
  flowlist: DemoPlayerFlowlist | null;
  pages: DemoPlayerProjectPage[];
  flows: DemoPlayerFlowsDict;
  widgets: DemoPlayerWidgetsDict;
  pageLinks: DemoPlayerPageLinksDict;
  flowIdSequence: FlowId[];
}): ProjectPageId[] {
  const { startPageId, flowlist, pageLinks, flowIdSequence, flows, widgets } =
    props;
  let result: Set<ProjectPageId> = new Set([startPageId]);

  if (flowlist) {
    /**
     * If there is a checklist,
     * Then return pages connected to checklist's flows-widgets.
     */
    const checklistFlows = flowlist.items
      .filter(
        (checklistItem) =>
          checklistItem.target_kind === FlowlistItemTargetKind.Flow &&
          checklistItem.target_value
      )
      .map((checklistItem) => checklistItem.target_value);
    const remainingPages: Set<ProjectPageId> = new Set([]);

    checklistFlows.forEach((flowId) => {
      const flow = flows[flowId];

      if (flow) {
        const { widgets: flowWidgets } = flow;
        flowWidgets.forEach((widgetId, index) => {
          const { page_id } = widgets[widgetId];
          if (index === 0) {
            result.add(page_id); // add first page of every checklist item
          } else {
            remainingPages.add(page_id);
          }
        });
      }
    });
    result = new Set([...result, ...remainingPages]);
  } else if (flowIdSequence.length > 0) {
    /**
     * If flow(-s) AND no checklist,
     * Then return pages connected to flows-widgets in sequential order.
     */
    flowIdSequence.forEach((flowId) => {
      const flow = flows[flowId];

      if (flow) {
        const { widgets: flowWidgets } = flow;
        flowWidgets.forEach((widgetId) => {
          const { page_id } = widgets[widgetId];
          result.add(page_id);
        });
      }
    });
  }

  if (Object.keys(pageLinks).length) {
    /**
     * If there are page links,
     * then return target pages of links.
     */
    Object.keys(pageLinks).forEach((key) => {
      const pageLink = pageLinks[key];
      if (pageLink.type === 'page') {
        result.add(pageLink.target);
      }
    });
  }

  return [...result];
}
