import cx from 'classnames';
import { useTimeoutFn } from 'shared-components/src/hooks/react-use/useTimeoutFn';
import { useState, VFC } from 'react';

import CustomizableButton from '../CustomizableButton';
import styles from './ResumeButton.module.css';
import { DemoPlayerButtonConfig } from '../../types';

interface ResumeButtonProps {
  config: DemoPlayerButtonConfig | null;
  onClick: () => void;
  isResponsive: boolean;
  isHtmlProject?: boolean;
}
/**
 * "Resume" button to exit "interactive" tour and continue "walkthrough" from last visited widget.
 *
 * @returns
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Resume-Button Docs}
 */
const ResumeButton: VFC<ResumeButtonProps> = (props) => {
  const { onClick, config, isHtmlProject, isResponsive } = props;
  const [isTooltipVisible, setIsTooltipVisible] = useState(true);

  useTimeoutFn(() => setIsTooltipVisible(false), 2000);

  return (
    <>
      <CustomizableButton
        aria-label="Resume demo button"
        id="resume_demo_button"
        className={cx({
          [styles.resumeButton]: isHtmlProject,
        })}
        text={config?.value || 'restart demo'}
        bgColor={config?.backgroundColor || '#E0007A'}
        onClick={onClick}
        color={config?.color || '#FFFFFF'}
        isResponsive={isResponsive}
      />
      {isTooltipVisible && (
        <div className={styles.popup}>
          <span className={styles.popupHighlightedText}>Click</span> around to
          explore the product.
        </div>
      )}
    </>
  );
};

export default ResumeButton;
