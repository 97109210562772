import { useEffect } from 'react';

import IframeEventsManager, {
  IframeEventsManagerListeners,
} from '../../utils/IframeEventsManager';
import { iterateShadowDomDeep } from '../../utils/iterateShadowDomDeep';

export interface UseRendererHtmlListenerArgs {
  /**
   * Html renderer-element(`iframe`)
   */
  rendererElement: HTMLIFrameElement | null;
  /**
   * `isEnabled` flag turns on\off hook
   */
  isEnabled?: boolean;
  /**
   * Helps to identify that hook should be re-mounted
   */
  key?: string;
  listeners: IframeEventsManagerListeners;
}

export function useRendererHtmlListener({
  rendererElement,
  key,
  isEnabled = true,
  listeners,
}: UseRendererHtmlListenerArgs): void {
  const rendererWindow = rendererElement?.contentWindow;
  const rendererDocument = rendererElement?.contentDocument;

  useEffect(() => {
    let iframeManager: IframeEventsManager | null;
    const unsubscribeListeners: (() => void)[] = [];
    if (rendererDocument && isEnabled) {
      // Add listeners to nested iframes
      iframeManager = new IframeEventsManager({
        entryPoint: rendererDocument,
        listeners,
      });
      // Add listeners to root document
      const shadowDomListeners: IframeEventsManagerListeners = [];
      listeners.forEach((listener) => {
        const [type, callback, options] = listener;
        rendererDocument.addEventListener(
          type,
          callback,
          typeof options.capture === 'boolean' ? options.capture : true
        );
        unsubscribeListeners.push(() =>
          rendererDocument.removeEventListener(
            type,
            callback,
            typeof options.capture === 'boolean' ? options.capture : true
          )
        );
        if (options.attachToShadowDom) {
          shadowDomListeners.push(listener);
        }
      });
      // Attach listeners to shadow dom
      if (shadowDomListeners.length) {
        const attachListenersToShadowDOM = (target: Document | ShadowRoot) => {
          for (const listener of shadowDomListeners) {
            target.addEventListener(
              listener[0],
              listener[1],
              typeof listener[2].capture === 'boolean'
                ? listener[2].capture
                : true
            );
            unsubscribeListeners.push(() =>
              target.removeEventListener(
                listener[0],
                listener[1],
                typeof listener[2].capture === 'boolean'
                  ? listener[2].capture
                  : true
              )
            );
          }
        };
        iterateShadowDomDeep(attachListenersToShadowDOM)(rendererDocument);
      }
    }

    return () => {
      if (rendererDocument) {
        unsubscribeListeners.forEach((callback) => callback());
      }
      if (iframeManager) {
        iframeManager.destroy();
        iframeManager = null;
      }
    };
  }, [rendererWindow, rendererDocument, isEnabled, key, listeners]);
}
