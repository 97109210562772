import { VFC, useContext, useMemo, useCallback } from 'react';
import { ProjectPageKind } from 'shared-components/src/features/projectPages/projectPages.constants';
import {
  BaseWidget,
  HotspotWidget,
  TooltipWidget,
} from 'shared-components/src/features/widgets/widgets.types';
import Button from 'shared-components/src/components/Button';
import RendererImage from 'shared-components/src/components/renderer/RendererImage';
import { RendererImageRenderFnType } from 'shared-components/src/components/renderer/RendererImage/RendererImage';
import DemoImage from 'shared-components/src/components/renderer/RendererImage/DemoImage';
import RendererImageSizeContainer from 'shared-components/src/components/renderer/RendererImageSizeContainer';
import RendererWidgetSlot from 'shared-components/src/components/renderer/RendererWidgetSlot';
import { isAnchorBasedWidget } from 'shared-components/src/features/widgets/widgets.utils';
import { captureException } from '@sentry/react';

import usePageImages from './usePageImages';
import styles from './DemoPlayerRendererImage.module.css';
import DemoVideo from '../DemoVideo';
import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';
import { useDemoZoom } from '../../hooks/useDemoZoom';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';

interface DemoPlayerRendererImageProps {
  onReady: (isReady: boolean) => void;
  isReady: boolean;
  isFullscreen: boolean;
}

const DemoPlayerRendererImage: VFC<DemoPlayerRendererImageProps> = ({
  onReady,
  isFullscreen,
}) => {
  const {
    setWidgetSlotElement,
    setRendererElement,
    goNextWidget,
    currentPageId,
    currentWidgetId,
    widgetsDict,
    rendererElement,
  } = useRendererPlayerState();

  const { image, allImages } = usePageImages();

  const { projectPages, playerConfig, rendererDimensions } =
    useContext(DemoPlayerContext);

  const currentPageDimensions = useMemo(() => {
    const currentPage = projectPages.find((p) => p.id === currentPageId);

    if (!currentPage) {
      captureException(
        new Error('Could not find currentPage with id = ' + currentPageId)
      );
    }

    return (
      currentPage?.dimensions || {
        width: 0,
        height: 0,
      }
    );
  }, [currentPageId, projectPages]);

  const currentWidget: BaseWidget | null =
    widgetsDict[currentWidgetId || ''] || null;
  const anchorBasedWidget: HotspotWidget | TooltipWidget | null =
    currentWidget && isAnchorBasedWidget(currentWidget) ? currentWidget : null;
  const zoom = anchorBasedWidget?.options.root.zoom ?? null;

  const { styles: zoomStyles } = useDemoZoom({
    zoom,
    enabled: Boolean(zoom && zoom > 1),
    position: {
      x: anchorBasedWidget?.options.root.offset.x || 0,
      y: anchorBasedWidget?.options.root.offset.y || 0,
    },
    container: rendererElement,
  });

  const renderFn = useCallback<RendererImageRenderFnType>(
    ({
      imageValue,
      isMatchingUrl,
      isLoadingCompleted,
      isMatchingVideoWidgetId,
    }) => {
      if (imageValue.type === 'video') {
        return (
          <DemoVideo
            key={imageValue.url + imageValue.videoWidgetId}
            src={imageValue.url}
            onEnded={goNextWidget}
            isPreloadingHidden={!isMatchingUrl || !isMatchingVideoWidgetId}
            startTime={imageValue.startTime}
            endTime={imageValue.endTime}
            isMutedInitial={
              typeof imageValue.isVideoMuted === 'undefined'
                ? true
                : imageValue.isVideoMuted
            }
            playbackRateInitial={imageValue.videoPlaybackRate || 1}
            isHlsVideo={!imageValue.isFromLocalStorage}
            thumbnailUrl={imageValue.thumbnailUrl}
          />
        );
      } else if (imageValue.type === 'image') {
        return (
          <DemoImage
            key={imageValue.url}
            isPreloadingHidden={!isMatchingUrl || !isLoadingCompleted}
            src={imageValue.url}
          />
        );
      }

      return null;
    },
    [goNextWidget]
  );

  return (
    <RendererImageSizeContainer
      rendererDimensions={rendererDimensions}
      rendererMargin={playerConfig.renderer_margin}
      dimensions={currentPageDimensions}
      isFullscreen={isFullscreen}
    >
      {image?.type === ProjectPageKind.Video && !image.url && (
        <div className={styles.videoProcessingOverlay}>
          Video is processing...
          <Button onClick={goNextWidget} variant="purple">
            Go next
          </Button>
        </div>
      )}
      <RendererImage
        refCB={setRendererElement}
        value={image}
        allValues={allImages}
        onReady={onReady}
        renderFn={renderFn}
        style={zoomStyles}
      />
      <RendererWidgetSlot ref={setWidgetSlotElement} />
    </RendererImageSizeContainer>
  );
};

export default DemoPlayerRendererImage;
