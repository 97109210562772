import { useCallback, useMemo, VFC } from 'react';
import debounce from 'lodash-es/debounce';
import { WidgetTriggerIfType } from 'shared-components/src/features/widgets/widgets.constants';
import {
  HotspotWidget,
  TooltipWidget,
} from 'shared-components/src/features/widgets/widgets.types';
import { getElementByUniqueSelector } from 'shared-components/src/utils/unique-selector';
import { useRendererHtmlListener } from 'shared-components/src/features/renderer/renderer.hooks';
import { IframeEventsManagerListeners } from 'shared-components/src/utils/IframeEventsManager';
import { getWidgetBackdropSelector } from 'shared-components/src/features/widgets/widgets.utils';
import { widgetShadowContainerId } from 'shared-components/src/features/renderer/renderer.constants';
import { isInputElement } from 'shared-components/src/utils/isInputElement';
import { isTextAreaElement } from 'shared-components/src/utils/isTextAreaElement';

import { DemoPlayerWidget } from '../../types';

interface WidgetBackdropHtmlElementClickManagerProps {
  rendererElement: HTMLIFrameElement;
  widget: DemoPlayerWidget<HotspotWidget | TooltipWidget>;
  onInteraction: () => void;
}

/**
 * @returns
 * @see {@link https://storylane.atlassian.net/l/cp/aHuG5qQM Widget Trigger DOcs}
 */
const WidgetBackdropHtmlElementClickManager: VFC<
  WidgetBackdropHtmlElementClickManagerProps
> = (props) => {
  const { rendererElement, widget, onInteraction } = props;

  const htmlElementSelector = getWidgetBackdropSelector(widget);
  const clickListener = useCallback(
    (event: MouseEvent) => {
      try {
        const clickedTarget = event.target as unknown as Element;

        if (clickedTarget.id === widgetShadowContainerId) return;

        if (htmlElementSelector && rendererElement?.contentDocument) {
          const anchorElement = getElementByUniqueSelector(
            htmlElementSelector,
            rendererElement.contentDocument
          );
          if (
            anchorElement &&
            (anchorElement === clickedTarget ||
              anchorElement.contains(clickedTarget))
          ) {
            onInteraction();
          }
        }
      } catch (error) {
        console.error(error);
      }
    },
    [htmlElementSelector, onInteraction, rendererElement.contentDocument]
  );

  const triggerIfInputTextValue =
    widget.options.root.trigger?.if.type === WidgetTriggerIfType.InputText
      ? widget.options.root.trigger.if.value
      : null;

  const changeListener = useMemo(() => {
    return debounce((event: InputEvent) => {
      try {
        const target = event.target as unknown as Element;
        if (isInputElement(target) || isTextAreaElement(target)) {
          const isTextMatched =
            triggerIfInputTextValue === '' ||
            triggerIfInputTextValue === target.value;

          if (isTextMatched && rendererElement.contentDocument) {
            const anchorElement = getElementByUniqueSelector(
              widget.dom_id,
              rendererElement.contentDocument
            );
            if (
              anchorElement &&
              (anchorElement === target || anchorElement.contains(target))
            ) {
              target.blur();
              onInteraction();
            }
          }
        }
      } catch (error) {
        console.error(error);
      }
    }, 600);
  }, [
    onInteraction,
    rendererElement.contentDocument,
    triggerIfInputTextValue,
    widget.dom_id,
  ]);

  const triggerIfClick =
    widget.options.root.trigger?.if.type === WidgetTriggerIfType.Click ||
    widget.options.root.trigger === null;
  const triggerIfInputText =
    widget.options.root.trigger?.if.type === WidgetTriggerIfType.InputText;
  const listeners = useMemo(() => {
    const result: IframeEventsManagerListeners = [];

    const clickLegacyWidgetElementListener = (event: MouseEvent) => {
      try {
        const clickedTarget = event.target as unknown as Element;

        if (clickedTarget.id === widgetShadowContainerId) return;
      } catch (error) {
        console.error(error);
      }
    };

    result.push([
      'click',
      clickLegacyWidgetElementListener,
      { attachToShadowDom: false },
    ]);
    if (triggerIfClick) {
      result.push([
        'click',
        clickListener,
        {
          attachToShadowDom: false,
        },
      ]);
    } else if (triggerIfInputText) {
      result.push([
        'input',
        changeListener,
        {
          attachToShadowDom: false,
        },
      ]);
    }
    return result;
  }, [clickListener, changeListener, triggerIfInputText, triggerIfClick]);

  useRendererHtmlListener({
    rendererElement,
    listeners,
  });

  return null;
};

export default WidgetBackdropHtmlElementClickManager;
