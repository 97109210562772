import { useEffect } from 'react';

import IframeEventsManager from '../../../utils/IframeEventsManager';

interface UseRendererHtmlObserversArgs {
  /**
   * Helps to identify that hook should be re-mounted
   */
  key?: string;
  /**
   * Html renderer-element(`iframe`)
   */
  rendererElement: HTMLIFrameElement | null;
  /**
   * Invoked on `animationend`, `scroll`, `resize`
   */
  onFire: () => void;
  /**
   * `isEnabled` flag turns on\off hook
   */
  isEnabled?: boolean;
}

function useRendererHtmlObservers({
  key,
  rendererElement,
  onFire,
  isEnabled = true,
}: UseRendererHtmlObserversArgs): void {
  const rendererWindow = rendererElement?.contentWindow;
  const rendererDocument = rendererElement?.contentDocument;

  useEffect(() => {
    if (!isEnabled) return;

    let iframeManager: IframeEventsManager | null;
    if (rendererDocument) {
      /**
       * Add listeners to nested iframes
       */
      iframeManager = new IframeEventsManager({
        entryPoint: rendererDocument,
        listeners: [
          ['scroll', onFire, { attachToShadowDom: false }],
          ['animationend', onFire, { attachToShadowDom: false }],
        ],
      });
    }

    if (rendererWindow) {
      rendererWindow.addEventListener('scroll', onFire, true);
      rendererWindow.addEventListener('resize', onFire, true);
      rendererWindow.addEventListener('animationend', onFire, true);
    }

    return () => {
      if (rendererWindow) {
        rendererWindow.removeEventListener('scroll', onFire, true);
        rendererWindow.removeEventListener('resize', onFire, true);
        rendererWindow.removeEventListener('animationend', onFire, true);
      }
      if (iframeManager) {
        iframeManager.destroy();
        iframeManager = null;
      }
    };
  }, [onFire, rendererWindow, rendererDocument, isEnabled, key]);
}

export default useRendererHtmlObservers;
