import { useState, useEffect } from 'react';

interface UsePageRenderStatusParams {
  onFirstRender?: () => void;
}

export const usePageRenderStatus = ({
  onFirstRender,
}: UsePageRenderStatusParams) => {
  const [isPageRendered, setIsPageRendered] = useState<boolean>(false);
  const [isStartReady, setIsStartReady] = useState<boolean>(false);

  useEffect(() => {
    if (!isStartReady && isPageRendered) {
      setIsStartReady(true);
    }
  }, [isPageRendered, isStartReady]);

  useEffect(() => {
    if (isStartReady) {
      onFirstRender?.();
    }
  }, [isStartReady, onFirstRender]);

  return [isPageRendered, setIsPageRendered] as const;
};
