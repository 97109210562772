import { idAttrName } from '../features/ui/ui.constants';

/**
 * @see {@link https://storylane.atlassian.net/l/cp/LVs4C416 Docs}
 */
export const removeProjectScriptsFromDocument = (
  targetDocument: Document
): void => {
  const scriptElements = targetDocument.querySelectorAll(`[${idAttrName}]`);

  scriptElements.forEach((script) => script.remove());
};

export default removeProjectScriptsFromDocument;
