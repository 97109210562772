import animateScrollTo from 'animated-scroll-to';

import { getNestedBoundingClientRect } from '../dom';

/**
 * get iframe element if available
 */
function getFrameElement(node: Node) {
  return node.ownerDocument?.defaultView?.frameElement;
}

/**
 * indicates if an element has scrollable space in the provided axis
 */
function hasScrollableSpace(el: Element, axis: 'Y' | 'X') {
  if (axis === 'Y') {
    return el.clientHeight /* + ROUNDING_TOLERANCE*/ < el.scrollHeight;
  }

  if (axis === 'X') {
    return el.clientWidth /* + ROUNDING_TOLERANCE*/ < el.scrollWidth;
  }
}

/**
 * indicates if an element has a scrollable overflow property in the axis
 */
function canOverflow(el: Element, axis: 'X' | 'Y') {
  const cssRule = `overflow${axis}` as const;
  const overflowValue = getComputedStyle(el, null)[cssRule];

  return (
    overflowValue === 'auto' ||
    overflowValue === 'scroll' ||
    overflowValue === 'overlay'
  );
}

/**
 * indicates if an element can be scrolled in either axis
 */
function isScrollable(el: Element): boolean {
  if (isRootOverflow(el)) {
    return false;
  }

  const isScrollableY = hasScrollableSpace(el, 'Y') && canOverflow(el, 'Y');
  const isScrollableX = hasScrollableSpace(el, 'X') && canOverflow(el, 'X');

  return Boolean(isScrollableY || isScrollableX);
}

/**
 *  indicates if it is root's child element overflow
 */
function isRootOverflow(el: Element) {
  // https://www.w3.org/TR/CSS22/visufx.html#propdef-overflow
  // UAs must apply the 'overflow' property set on the root element to the viewport.
  // When the root element is an HTML "HTML" element or an XHTML "html" element,
  // and that element has an HTML "BODY" element or an XHTML "body" element as a child,
  // user agents must instead apply the 'overflow' property from the first such child element
  // to the viewport, if the value on the root element is 'visible'.
  // The 'visible' value when used for the viewport must be interpreted as 'auto'.
  // The element from which the value is propagated must have a used value for 'overflow' of 'visible'.
  const rootElement = el.ownerDocument.documentElement;

  // normally it should be a BODY
  if (rootElement === el.parentElement) {
    const isRootOverflowVisible =
      getComputedStyle(rootElement).overflow === 'visible';
    return isRootOverflowVisible;
  }
}

interface InnerOffset {
  x: number;
  y: number;
}

interface Offset {
  x: number;
  y: number;
}

interface scrollIntoViewOptions {
  /**
   * element that should be scrolled into view
   */
  targetElement: Element;
  /**
   * point within a target element in percentage
   */
  innerOffset: InnerOffset;
  /**
   * top-level #document
   */
  boundaryDocument: Document;
  isAnimated?: boolean;
  /**
   * scroll speed is applied only if `isAnimated` equals `true`
   */
  scrollSpeed?: number;
  /**
   * extra offset in px from calculated inner point
   */
  extraOffsetInPx?: Offset;
  /**
   * set desired position of the element after the scroll within the viewport.
   */
  viewportPosition?: Offset;
}

/**
 * scroll element's inner position into view
 */
export async function scrollIntoView(
  options: scrollIntoViewOptions
): Promise<boolean> {
  try {
    const {
      boundaryDocument,
      targetElement,
      innerOffset,
      extraOffsetInPx = {
        y: 0,
        x: 0,
      },
      viewportPosition,
      isAnimated = false,
      scrollSpeed = 1000,
    } = options;
    // Used to handle the top most element that can be scrolled
    const scrollingElement =
      boundaryDocument.scrollingElement || boundaryDocument.documentElement;
    const scrollableBoxes = getScrollableAncestors(
      targetElement,
      scrollingElement
    );

    /**
     * Get target element's bounding box.
     * The bounding box is calculated relative to the main frame viewport - which is usually the same as the browser window.
     */
    const targetPosition = getTargetPosition(
      targetElement,
      innerOffset,
      extraOffsetInPx,
      boundaryDocument
    );

    const scrollComputations = getScrollComputations(
      scrollableBoxes,
      targetPosition,
      boundaryDocument,
      viewportPosition
    );

    if (isAnimated) {
      await animateScroll(scrollComputations, scrollSpeed);
    } else {
      immediateScroll(scrollComputations);
    }
    return true;
  } catch (error) {
    console.error(error);
    return false;
  }
}

function getTargetPosition(
  targetElement: Element,
  innerOffset: InnerOffset,
  offset: Offset,
  boundaryDocument: Document
) {
  const targetPosition = {
    top: 0,
    left: 0,
  };
  const boundaryWindow = boundaryDocument.defaultView;
  let tempTargetElement: Element | null = null;
  do {
    if (tempTargetElement === null) {
      /**
       * initial target element
       */
      tempTargetElement = targetElement;
      // important: inside iframes ClientRect is relative to it's own window
      const targetRect = tempTargetElement.getBoundingClientRect();

      // add inner point offset
      targetPosition.top +=
        targetRect.top + (innerOffset.y * targetRect.height) / 100;
      targetPosition.left +=
        targetRect.left + (innerOffset.x * targetRect.width) / 100;

      // add external offset
      targetPosition.top += offset.y;
      targetPosition.left += offset.x;
    } else {
      /**
       *  get iframe element if exists
       */
      tempTargetElement = getFrameElement(tempTargetElement) || null;
      if (tempTargetElement === null) {
        break;
      }
      const frameRect = tempTargetElement.getBoundingClientRect();

      targetPosition.top += frameRect.top;
      targetPosition.left += frameRect.left;
    }
  } while (
    tempTargetElement !== null &&
    tempTargetElement.ownerDocument.defaultView !== boundaryWindow
  );
  return {
    ...targetPosition,
  };
}

function isDocument(node: Node): node is Document {
  return node.nodeType === 9;
}

function isDocumentScrollingElement(node: Node) {
  /**
   * The scrollingElement read-only property of the Document interface returns a reference to the Element that scrolls the document.
   * In standards mode, this is the root element of the document, document.documentElement.
   *
   * Note: It is only POTENTIALLY scrollable
   *
   * @see {@link https://developer.mozilla.org/en-US/docs/Web/API/document/scrollingElement Docs}
   */
  const scrollingElement =
    node.ownerDocument?.scrollingElement || node.ownerDocument?.documentElement;

  return scrollingElement === node;
}

/**
 * Collect all the scrolling boxes, as defined in the spec: https://drafts.csswg.org/cssom-view/#scrolling-box
 */
export function getScrollableAncestors(
  el: Element,
  boundaryEl: Element
): Element[] {
  const collection: Element[] = [];
  let cursor: Node | null | undefined = el;

  while (cursor) {
    // Move cursor to parent
    cursor = cursor.parentNode || (cursor as unknown as ShadowRoot).host;

    if (!cursor) break;

    if (isDocument(cursor)) {
      cursor = cursor.defaultView?.frameElement;
      if (!cursor) break;
    }

    // Stop when we reach the viewport
    if (cursor === boundaryEl) {
      collection.push(cursor as Element);
      break;
    }

    if (isDocumentScrollingElement(cursor)) {
      if (
        hasScrollableSpace(cursor as Element, 'Y') ||
        hasScrollableSpace(cursor as Element, 'X')
      ) {
        collection.push(cursor as Element);
      }
    } else if (isScrollable(cursor as Element)) {
      collection.push(cursor as Element);
    }
  }

  return collection;
}

interface ScrollComputation {
  el: Element;
  top: number;
  left: number;
}

function getScrollComputations(
  scrollableBoxes: Element[],
  targetPosition: {
    top: number;
    left: number;
  },
  boundaryDocument: Document,
  viewportPosition?: Offset
): ScrollComputation[] {
  /**
   * Values are relative to the top-most viewport.
   * Subtract computed scrolls while iterating through scrollable boxes.
   */
  let totalScrollToTargetY: number = targetPosition.top;
  let totalScrollToTargetX: number = targetPosition.left;

  const computations: ScrollComputation[] = [];

  scrollableBoxes.forEach((scrollableBox) => {
    let computedScrollY = 0;
    let computedScrollX = 0;

    const scrollingElement =
      scrollableBox.ownerDocument.scrollingElement ||
      scrollableBox.ownerDocument.documentElement;
    if (scrollingElement === scrollableBox) {
      const scrollableBoxWindow = scrollableBox.ownerDocument
        .defaultView as Window;
      const { visualViewport, innerHeight, innerWidth } = scrollableBoxWindow;
      const viewportWidth = visualViewport ? visualViewport.width : innerWidth;
      const viewportHeight = visualViewport
        ? visualViewport.height
        : innerHeight;

      /**
       * if it is a nested window(iframe),
       * then we need to calculate ClientRect relative to the top-most window.
       */
      const scrollableBoxOffset = getNestedBoundingClientRect(
        scrollableBox,
        boundaryDocument.defaultView as Window
      );

      const viewportFinalPosition = {
        y: viewportPosition ? viewportPosition.y : viewportHeight / 2,
        x: viewportPosition ? viewportPosition.x : viewportWidth / 2,
      };
      computedScrollY =
        totalScrollToTargetY -
        (scrollableBoxOffset.top + viewportFinalPosition.y);

      computedScrollX =
        totalScrollToTargetX -
        (scrollableBoxOffset.left + viewportFinalPosition.x);

      // Viewport current scroll offsets
      const { scrollX, scrollY, pageXOffset, pageYOffset } =
        scrollableBoxWindow;
      const currentScrollX = scrollX || pageXOffset;
      const currentScrollY = scrollY || pageYOffset;

      computedScrollY = Math.max(0, computedScrollY);
      computedScrollX = Math.max(0, computedScrollX);

      totalScrollToTargetY += currentScrollY - computedScrollY;
      totalScrollToTargetX += currentScrollX - computedScrollX;
    } else {
      // scrollable box
      const scrollableBoxRect = scrollableBox.getBoundingClientRect();
      const scrollableStyle = (
        scrollableBox.ownerDocument.defaultView as Window
      ).getComputedStyle(scrollableBox);
      const scrollableBorderLeft = parseInt(
        scrollableStyle.borderLeftWidth as string,
        10
      );
      const scrollableBorderTop = parseInt(
        scrollableStyle.borderTopWidth as string,
        10
      );
      const scrollableBorderRight = parseInt(
        scrollableStyle.borderRightWidth as string,
        10
      );
      const scrollableBorderBottom = parseInt(
        scrollableStyle.borderBottomWidth as string,
        10
      );

      // Calculate scrollable box scrollbar dimnesions
      // The property existance checks for offfset[Width|Height] is because only HTMLElement objects have them, but any Element might pass by here
      const scrollbarWidth =
        'offsetWidth' in scrollableBox
          ? (scrollableBox as HTMLElement).offsetWidth -
            (scrollableBox as HTMLElement).clientWidth -
            scrollableBorderLeft -
            scrollableBorderRight
          : 0;
      const scrollbarHeight =
        'offsetHeight' in scrollableBox
          ? (scrollableBox as HTMLElement).offsetHeight -
            (scrollableBox as HTMLElement).clientHeight -
            scrollableBorderTop -
            scrollableBorderBottom
          : 0;

      computedScrollY =
        totalScrollToTargetY -
        (scrollableBoxRect.top + scrollableBoxRect.height / 2) +
        scrollbarHeight / 2;

      computedScrollX =
        totalScrollToTargetX -
        (scrollableBoxRect.left + scrollableBoxRect.width / 2) +
        scrollbarWidth / 2;

      const { scrollLeft, scrollTop } = scrollableBox;
      // Ensure scroll coordinates are not out of bounds while applying scroll offsets
      computedScrollY = Math.max(
        0,
        Math.min(
          scrollTop + computedScrollY,
          scrollableBox.scrollHeight -
            scrollableBoxRect.height +
            scrollbarHeight
        )
      );
      computedScrollX = Math.max(
        0,
        Math.min(
          scrollLeft + computedScrollX,
          scrollableBox.scrollWidth - scrollableBoxRect.width + scrollbarWidth
        )
      );

      totalScrollToTargetY += scrollTop - computedScrollY;
      totalScrollToTargetX += scrollLeft - computedScrollX;
    }

    computations.push({
      el: scrollableBox,
      top: computedScrollY,
      left: computedScrollX,
    });
  });

  return computations;
}

function animateScroll(
  computations: ScrollComputation[],
  scrollSpeed?: number
) {
  const scrollPromises: Promise<boolean>[] = [];

  computations.forEach((computation) => {
    // requestAnimationFrame is used in animated-scroll-to library
    scrollPromises.push(
      animateScrollTo([computation.left, computation.top], {
        elementToScroll: computation.el,
        speed: scrollSpeed, // see https://www.npmjs.com/package/animated-scroll-to#options
      })
    );
  });

  return Promise.all(scrollPromises);
}

function immediateScroll(computations: ScrollComputation[]) {
  computations.forEach((computation) => {
    computation.el.scrollTop = computation.top;
    computation.el.scrollLeft = computation.left;
  });
}

type Rect = Record<'bottom' | 'top' | 'right' | 'left', number>;
export function isIntersectRect(r1: Rect, r2: Rect): boolean {
  return !(
    r2.left > r1.right ||
    r2.right < r1.left ||
    r2.top > r1.bottom ||
    r2.bottom < r1.top
  );
}

export default scrollIntoView;
