import {
  Dispatch,
  SetStateAction,
  useCallback,
  useContext,
  useMemo,
} from 'react';
import { FlowId } from 'shared-components/src/features/flow/flow.types';
import { ProjectKind } from 'shared-components/src/features/project/project.constants';
import { ProjectId } from 'shared-components/src/features/project/project.types';
import { WidgetAlignPosition } from 'shared-components/src/features/widgets/widgets.constants';
import {
  WidgetId,
  WidgetPlacement,
} from 'shared-components/src/features/widgets/widgets.types';
import merge from 'lodash-es/merge';
import { useSelector } from '@xstate/react';
import {
  fillAllSubstitutions,
  fillCustomSubstitutions,
} from 'shared-components/src/features/substitution/substitution.utils';
import {
  isAnchorBasedWidget,
  isHotspot,
  isLeadCaptureLikeWidget,
  isMedia,
  isModalLikeWidget,
} from 'shared-components/src/features/widgets/widgets.utils';

import { Dictionary } from '../global';
import { DemoPlayerLead, DemoPlayerWidget, HostInfoRef } from '../types';
import { demoPlayerModel } from '../machine/machine.model';
import { isHotspotOnlyBeacon } from '../utils/isHotspotOnlyBeacon';
import { retrievePrimaryColorFromWidget } from '../utils/retrievePrimaryColorFromWidget';
import {
  DemoPlayerContext,
  DemoPlayerContextValue,
} from '../contexts/DemoPlayerContext';
import { DemoPlayerWidgetContext } from '../contexts/DemoPlayerWidgetContext';

interface UseWidgetPlayerStateReturn {
  widget: DemoPlayerWidget | null;
  currentPageId: string;
  currentFlowId: FlowId | null;
  currentWidgetId: WidgetId | null;
  rendererElement: HTMLElement | null;
  goNextWidget: () => void;
  goToFlow: (flowId: FlowId, widgetId?: WidgetId | null) => void;
  goPrevWidget: () => void;
  onDismiss: () => void;
  projectKind: ProjectKind;
  projectId: ProjectId;
  isFlowPlaybackState: boolean;
  widgetSlotElement: HTMLDivElement | null;
  onLeadCapture: (lead: DemoPlayerLead) => void;
  isPreview: boolean;
  isRecording: boolean;
  setArrowWidgetAutoPlacement: Dispatch<
    SetStateAction<Dictionary<WidgetPlacement>>
  >;
  isWidgetTranslationAnimationEnabled: DemoPlayerContextValue['isWidgetTranslationAnimationEnabled'];
  isVendorLeadFormScaleEnabled: DemoPlayerContextValue['isVendorLeadFormScaleEnabled'];
  widgetAnimationDelayMs: DemoPlayerContextValue['widgetAnimationDelayMs'];
  widgetAnimationDurationOutMs: DemoPlayerContextValue['widgetAnimationDurationOutMs'];
  widgetAnimationDurationInMs: DemoPlayerContextValue['widgetAnimationDurationInMs'];
  hostInfo: HostInfoRef | null;
  isChecklistEnabled: boolean;
}

export function useWidgetPlayerState(): UseWidgetPlayerStateReturn {
  const {
    projectKind,
    projectId,
    demoPlayerService,
    rendererElement,
    widgetSlotElement,
    textSubstitutions,
    isWidgetTranslationAnimationEnabled,
    widgetAnimationDelayMs,
    shouldAddWatermark,
    widgetAnimationDurationInMs,
    widgetAnimationDurationOutMs,
    hostInfo,
    watermarkTextHref,
    isRecording,
    isVendorLeadFormScaleEnabled,
  } = useContext(DemoPlayerContext);
  const { arrowWidgetAutoPlacement, setArrowWidgetAutoPlacement } = useContext(
    DemoPlayerWidgetContext
  );
  const { send } = demoPlayerService;

  const currentPageId = useSelector(
    demoPlayerService,
    (state) => state.context.currentPageId
  );
  const currentFlowId = useSelector(
    demoPlayerService,
    (state) => state.context.currentFlowId
  );
  const currentWidgetId = useSelector(
    demoPlayerService,
    (state) => state.context.currentWidgetId
  );
  const widgetsDict = useSelector(
    demoPlayerService,
    (state) => state.context.widgetsDict
  );
  const flowsDict = useSelector(
    demoPlayerService,
    (state) => state.context.flowsDict
  );
  const isPreview = useSelector(
    demoPlayerService,
    (state) => state.context.config.isPreview
  );

  const isFlowPlaybackState = useSelector(demoPlayerService, (state) =>
    state.matches('tour.walkthrough.playFlow')
  );

  const isChecklistEnabled = useSelector(
    demoPlayerService,
    (state) =>
      state.matches('checklist.maximized') ||
      state.matches('checklist.minimized')
  );

  const goNextWidget = useCallback(() => {
    send(demoPlayerModel.events.goNextWidget());
  }, [send]);
  const goPrevWidget = useCallback(() => {
    send(demoPlayerModel.events.goPrevWidget());
  }, [send]);
  const dismissWidget = useCallback(() => {
    send(demoPlayerModel.events.dismissWidget());
  }, [send]);
  const goToFlow = useCallback(
    (flowId: FlowId, widgetId?: WidgetId | null) => {
      send(demoPlayerModel.events.startFlowFromButton(flowId, widgetId));
    },
    [send]
  );
  const onLeadCapture = useCallback(
    (lead: DemoPlayerLead) => {
      send(demoPlayerModel.events.captureLead(lead));
    },
    [send]
  );

  const originalWidget = currentWidgetId ? widgetsDict[currentWidgetId] : null;
  const widgetWithMeta: Omit<
    DemoPlayerWidget,
    'autoAlignment' | 'autoOffset' | 'anchorBackdropRect'
  > | null = useMemo(() => {
    if (!originalWidget) return null;

    const flow = flowsDict[originalWidget.flowId];
    if (!flow) return null;

    const flowWidgetIds = flow.widgets;
    const widgetIndex = flowWidgetIds.findIndex(
      (widgetId) => widgetId === originalWidget.id
    );

    const widgetValues: {
      video_url?: string;
      options: {
        description?: { value: string };
        cta?: { extUrl: string };
        secondary?: { extUrl: string };
      };
    } = { options: {} };
    const replaceEmpty = !isPreview; // @see: https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/10551301/Substitutions#Editor-vs-Preview-vs-Public
    if ('description' in originalWidget.options) {
      widgetValues.options.description = {
        value: fillAllSubstitutions(
          originalWidget.options.description.value,
          textSubstitutions,
          replaceEmpty
        ).result,
      };
    }
    if ('cta' in originalWidget.options && originalWidget.options.cta.extUrl) {
      widgetValues.options.cta = {
        extUrl: fillCustomSubstitutions(
          originalWidget.options.cta.extUrl,
          textSubstitutions,
          replaceEmpty
        ).result,
      };
    }
    if (
      'secondary' in originalWidget.options &&
      originalWidget.options.secondary.extUrl
    ) {
      widgetValues.options.secondary = {
        extUrl: fillCustomSubstitutions(
          originalWidget.options.secondary.extUrl,
          textSubstitutions,
          replaceEmpty
        ).result,
      };
    }
    if (isMedia(originalWidget) && originalWidget.video_url) {
      widgetValues.video_url = fillCustomSubstitutions(
        originalWidget.video_url,
        textSubstitutions,
        replaceEmpty
      ).result;
    }

    const prevWidgetId = flowWidgetIds[widgetIndex - 1];
    const nextWidgetId = flowWidgetIds[widgetIndex + 1];
    let primaryColor = retrievePrimaryColorFromWidget(originalWidget);
    if (!primaryColor) {
      if (prevWidgetId) {
        primaryColor = retrievePrimaryColorFromWidget(
          widgetsDict[prevWidgetId]
        );
      } else if (nextWidgetId) {
        primaryColor = retrievePrimaryColorFromWidget(
          widgetsDict[nextWidgetId]
        );
      }
    }

    return merge({}, originalWidget, widgetValues, {
      _progress: ((widgetIndex + 1) * 100) / flowWidgetIds.length,
      _totalStepsCount: flowWidgetIds.length,
      _currentStepNumber: widgetIndex + 1,
      _nextId: nextWidgetId,
      _prevId: prevWidgetId,
      _isLast: widgetIndex + 1 === flowWidgetIds.length,
      _isFirst: widgetIndex === 0,
      watermarkEnabled:
        widgetIndex === 0 &&
        isModalLikeWidget(originalWidget) &&
        !isLeadCaptureLikeWidget(originalWidget) &&
        !isChecklistEnabled &&
        shouldAddWatermark,
      watermarkTextHref,
      hotspotOnlyBeacon: isHotspot(originalWidget)
        ? isHotspotOnlyBeacon(originalWidget)
        : null,
      /**
       * Defines background color of the widget bottom tray.
       * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/83001365/DRAFT+Responsive#Accent-color Docs}
       */
      primaryColor,
    });
  }, [
    originalWidget,
    isChecklistEnabled,
    shouldAddWatermark,
    flowsDict,
    textSubstitutions,
    isPreview,
    watermarkTextHref,
    widgetsDict,
  ]);
  const demoplayerWidget: DemoPlayerWidget | null = useMemo(() => {
    if (widgetWithMeta === null) {
      return null;
    }

    const arrowWidgetPlacement = widgetWithMeta
      ? arrowWidgetAutoPlacement[widgetWithMeta.id]
      : undefined;

    if (!isAnchorBasedWidget(widgetWithMeta)) {
      return merge({}, widgetWithMeta, {
        autoAlignment: null,
        autoOffset: null,
        anchorBackdropRect: null,
      });
    }

    // apply zoom
    const appliedZoomOffset = merge({}, widgetWithMeta.options.root.offset);
    const anchorBackdropRect = widgetWithMeta.options.root.backdropRect
      ? merge({}, widgetWithMeta.options.root.backdropRect)
      : null;

    const zoom = widgetWithMeta.options.root.zoom ?? 1;
    if (zoom > 1) {
      const zoomOffsetX = widgetWithMeta.options.root.offset.x * zoom;
      const zoomOffsetY = widgetWithMeta.options.root.offset.y * zoom;
      if (zoomOffsetX > 50) {
        appliedZoomOffset.x = Math.max(zoomOffsetX - (zoom - 1) * 100, 50);
      } else {
        appliedZoomOffset.x = zoomOffsetX;
      }
      if (zoomOffsetY > 50) {
        appliedZoomOffset.y = Math.max(zoomOffsetY - (zoom - 1) * 100, 50);
      } else {
        appliedZoomOffset.y = zoomOffsetY;
      }

      if (anchorBackdropRect) {
        anchorBackdropRect.x =
          anchorBackdropRect.x * zoom + appliedZoomOffset.x - zoomOffsetX;
        anchorBackdropRect.y =
          anchorBackdropRect.y * zoom + appliedZoomOffset.y - zoomOffsetY;
        anchorBackdropRect.width *= zoom;
        anchorBackdropRect.height *= zoom;
      }
    }

    return merge({}, widgetWithMeta, {
      autoAlignment:
        arrowWidgetPlacement?.alignment ??
        (widgetWithMeta.options.root.alignment as WidgetAlignPosition),
      autoOffset: arrowWidgetPlacement?.offset ?? appliedZoomOffset,
      anchorBackdropRect,
    });
  }, [widgetWithMeta, arrowWidgetAutoPlacement]);

  return {
    isChecklistEnabled,
    widget: demoplayerWidget,
    setArrowWidgetAutoPlacement,
    rendererElement,
    currentPageId,
    currentFlowId,
    currentWidgetId,
    goNextWidget,
    goToFlow,
    goPrevWidget,
    onDismiss: dismissWidget,
    projectKind,
    projectId,
    isFlowPlaybackState,
    widgetSlotElement,
    onLeadCapture,
    isPreview,
    isRecording,
    isWidgetTranslationAnimationEnabled,
    widgetAnimationDelayMs,
    widgetAnimationDurationInMs,
    widgetAnimationDurationOutMs,
    hostInfo,
    isVendorLeadFormScaleEnabled,
  };
}
