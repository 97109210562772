import { useEffect, useState } from 'react';

export const useLoadImage = (src: string | null) => {
  const [image, setImage] = useState<HTMLImageElement | null>(null);
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!src) {
      return;
    }

    const imageElement = new Image();

    imageElement.onload = () => {
      setLoading(false);
    };

    imageElement.onerror = () => {
      setLoading(false);
    };

    setImage(imageElement);
    setLoading(true);

    imageElement.src = src;
  }, [src]);

  return [image, loading] as const;
};
