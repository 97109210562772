import { Substitution } from './substitution.types';

/**
 * Substitution template delimiters.
 * Copied from: https://lodash.com/docs/4.17.15#template
 */
export const substitutionReg = /{{([\s\S]+?)}}/g;

/**
 * Variable substitution regular expression.
 * E.g. {{$yyyy-MM-dd}}
 */
export const substitutionVariableReg =
  /{{\$([\s\S]+?)(\|\s*[+-]\s*\d+([hmsdyM])?\s*)?}}/g;

export const substitutionKeyBreadcrumb = 'sl-substitution-key-breadcrumb';
export const substitutionKeyBreadcrumbReg = new RegExp(
  `${substitutionKeyBreadcrumb}-({{([A-Za-z0-9._]+?)}})`
);

export const DEFAULT_SUBSTITUTIONS: Substitution[] = [];

/**
 * Custom substitution regular expression.
 * E.g. {{custom}}
 */
export const substitutionCustomReg = /{{([^$][A-Za-z0-9._]+?)}}/g;

export const substitutionTextCustomKeyReg = /^[a-z0-9_]+$/;
export const substitutionImageCustomKeyReg = /^[A-Za-z0-9\s]+$/;

export enum SubstitutionKind {
  Text = 'text',
  Image = 'image',
}
