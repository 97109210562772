import intersection from 'lodash-es/intersection';

interface IframeLoadManagerOptions {
  entryPoint: Document;
  onReady: () => void;
  onDocumentLoad: (loadedDoc: Document) => void;
}

/**
 * Wait until all nested iframes are loaded and rendered.
 * Pending iframe is an iframe that is not rendered yet.
 * Important: expect all nested iframes to be "inline" - <iframe srcdoc="<html>...">, so it shouldn't take much time.
 * TODO: https://storylane.atlassian.net/browse/STORY-3946
 */
class IframeLoadManager {
  private pendingIframes: Map<HTMLIFrameElement, () => void> = new Map();
  private isDestroyed = false;
  private cb; // ready callback
  private onDocumentLoadCB;

  constructor(options: IframeLoadManagerOptions) {
    const {
      entryPoint, // top level document of a captured page
      onReady, // ready callback
      onDocumentLoad,
    } = options;
    this.cb = onReady;
    this.onDocumentLoadCB = onDocumentLoad;
    this.collectPendingIframes(entryPoint);
    this.isIframesLoaded(); // check if no pending iframes after synchronous go through
  }

  /**
   * Collect all pending iframes synchronously and add "load" listeners to them
   * "pending iframe" is an iframe that is not rendered yet.
   */
  private collectPendingIframes(doc: Document) {
    const foundIframes = doc.querySelectorAll('iframe');
    if (foundIframes.length) {
      for (const iframe of foundIframes) {
        if (this.isIgnoreIframe(iframe)) {
          continue;
        }

        if (this.isPendingIframe(iframe)) {
          const listenIframeLoad = (event: Event) => {
            if (!this.isDestroyed && event.target) {
              // proccess just rendered "current iframe"
              const iframeDocument = this.getIframeDocument(iframe);
              if (iframeDocument) {
                // collect children pending iframes within "current iframe" synchronously
                this.collectPendingIframes(iframeDocument);
                this.onDocumentLoadCB(iframeDocument);
              }
              // remove "current iframe" from pending (children pending iframes do not prevent parent from being ready)
              this.pendingIframes.delete(event.target as HTMLIFrameElement);
              this.isIframesLoaded(); // check if "current iframe" was last pending
            }
          };
          iframe.addEventListener('load', listenIframeLoad);
          this.pendingIframes.set(iframe, () => {
            if (iframe) {
              iframe.removeEventListener('load', listenIframeLoad);
            }
          });
        } else if (this.getIframeDocument(iframe)) {
          this.onDocumentLoadCB(this.getIframeDocument(iframe) as Document);
        }
      }
    }
  }

  private isIgnoreIframe(iframe: HTMLIFrameElement) {
    const isHiddenBySF = iframe.classList.contains('sf-hidden'); // TODO: coupling to "sf-hidden" class. Can be removed once all demos cleaned up from iframes with that classs.
    const noIframeSrc = iframe.srcdoc.length === 0 && iframe.src.length === 0;

    return isHiddenBySF || noIframeSrc;
  }

  private isPendingIframe(iframe: HTMLIFrameElement) {
    try {
      return (
        this.hasAccessToIframe(iframe) &&
        (!iframe.contentDocument ||
          !this.isDocumentReady(iframe.contentDocument)) // no document at all OR document is not ready
      );
    } catch (error) {
      return false;
    }
  }

  private getIframeDocument(iframe: HTMLIFrameElement): Document | null {
    try {
      return iframe.contentDocument;
    } catch (error) {
      return null;
    }
  }

  /**
   * check if iframe element has expected sandbox attribute that we set during capture process.
   * <iframe ... sandbox="allow-scripts allow-same-origin ..." />
   */
  private hasAccessToIframe(iframe: HTMLIFrameElement) {
    return (
      intersection(
        ['allow-scripts', 'allow-same-origin'],
        iframe.getAttribute('sandbox')?.split(' ') || []
      ).length === 2
    );
  }

  private isDocumentReady(document: Document | null): boolean {
    if (document?.readyState === 'complete') {
      /**
       * If the readyState is complete, we can normally assume that the iframe has already been loaded.
       * However, checking `readyState` in Chrome for an alredy fired onload event is meaningless,
       * as Chrome initializes every iframe with an "about:blank" empty page.
       * The readyState of this page may be complete, but it's not the readyState of the page you expect.
       */
      return document.URL !== 'about:blank';
    }

    return false;
  }

  /**
   * Invoke callback once all iframes are ready
   */
  private isIframesLoaded() {
    if (!this.pendingIframes.size) {
      this.cb();
    }
  }

  /**
   * unsubscribe unfinished "load" events
   */
  public destroy(): void {
    this.pendingIframes.forEach((unsubscribe) => {
      if (unsubscribe) {
        unsubscribe();
      }
    });
    this.pendingIframes.clear();
    this.isDestroyed = true;
  }
}

export default IframeLoadManager;
