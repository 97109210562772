import { CSSProperties, FC, ReactNode } from 'react';
import cx from 'classnames';

import { BaseWidget } from '../../../features/widgets/widgets.types';
import Icon from '../../Icon';
import WatermarkInline from '../../ui//WatermarkInline';
import {
  calculateWidgetTextColor,
  isAnchorBasedWidget,
  isLeadCaptureLikeWidget,
  isModalLikeWidget,
  isMedia,
} from '../../../features/widgets/widgets.utils';
import { WidgetAlignPosition } from '../../../features/widgets/widgets.constants';
import styles from './WidgetBody.module.css';
import { WidgetBodyArrowId } from './WidgetBody.constants';

interface WidgetBodyProps {
  id?: string;
  widget: BaseWidget;
  children: ReactNode;
  maxWidth?: string;
  width?: string;
  height?: string;
  rootClassName?: string;
  className?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  shouldAddWatermark?: boolean;
  autoAlignment?: WidgetAlignPosition;
  onDismiss?: () => void;
  watermarkHref?: string;
  testId?: string;
}

const WidgetBody: FC<WidgetBodyProps> = ({
  widget,
  width,
  height,
  maxWidth,
  onDismiss,
  className,
  children,
  id,
  autoAlignment,
  shouldAddWatermark = false,
  watermarkHref,
  testId,
}) => {
  const showClose = widget.options.root.close_button;
  const borderColor = widget.options.root.borderColor;
  const showArrow =
    isAnchorBasedWidget(widget) && !widget.options.root.hide_arrow;
  const showFooter =
    isModalLikeWidget(widget) || // modal-like widgets always have "cta" button
    widget.options.root.next_button || // "cta" button is enabled
    (widget.options.root.prev_button && widget.options._prevId) || // previous button is enabled and there is a prev. widget
    widget.options.root.show_step_number; // step number label is presented

  const widgetBackgroundColor = widget.options.root.backgroundColor || '#fff';
  return (
    <div
      id={id}
      className={cx(styles.widget, className, {
        [styles.widgetTooltip]: isAnchorBasedWidget(widget),
        [styles.widgetModalLike]: isModalLikeWidget(widget),
        [styles.widgetMedia]: isMedia(widget),
        [styles.widgetLeadCapture]: isLeadCaptureLikeWidget(widget),
        [styles.widgetHasClose]: showClose,
        [styles.widgetHasFooter]: showFooter,
      })}
      data-testid={testId}
      aria-label={`Widget Body with widget id ${widget.id}`}
      style={
        {
          '--widget-body-width': width,
          '--widget-body-max-width': width ? width : maxWidth,
          backgroundColor: widgetBackgroundColor,
          borderColor,
          height,
        } as CSSProperties
      }
    >
      {showClose && (
        <div
          className={styles.widgetClose}
          onClick={(e) => {
            e.stopPropagation();
            onDismiss && onDismiss();
          }}
          style={{
            color: calculateWidgetTextColor(widgetBackgroundColor, 'secondary'),
          }}
        >
          <svg viewBox="0 0 12 12" fill="currentColor">
            <path
              d="M11.674,9.829l-9.5-9.5A1.112,1.112,0,0,0,.6.326L.326.6a1.111,1.111,0,0,0,0,1.573l9.5,9.5a1.114,1.114,0,0,0,1.574,0l.273-.274a1.111,1.111,0,0,0,0-1.573"
              transform="translate(0 -0.001)"
            />
            <path
              d="M11.675.6,11.4.325a1.113,1.113,0,0,0-1.573,0l-9.5,9.5a1.113,1.113,0,0,0,0,1.573l.272.274a1.112,1.112,0,0,0,1.574,0l9.5-9.5a1.113,1.113,0,0,0,0-1.573"
              transform="translate(0 0)"
            />
          </svg>
        </div>
      )}
      {showArrow && (
        <Icon
          className={cx(
            styles.widgetArrow,
            styles[
              `widgetArrow--${autoAlignment || widget.options.root.alignment}`
            ]
          )}
          style={{
            color: widget.options.root.backgroundColor,
            stroke: borderColor,
          }}
          name="walkthrough-widget-arrow"
          id={WidgetBodyArrowId}
        />
      )}
      {children}
      {shouldAddWatermark && (
        <WatermarkInline
          href={watermarkHref}
          className={styles.watermark}
          style={{
            color: calculateWidgetTextColor(widgetBackgroundColor, 'secondary'),
          }}
        />
      )}
    </div>
  );
};

export default WidgetBody;
