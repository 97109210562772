import { forwardRef, PropsWithChildren } from 'react';
import cx from 'classnames';

import {
  WidgetNeedCalcAlignment,
  WidgetAlignPosition,
} from '../../../features/widgets/widgets.constants';
import styles from './WidgetTooltipAlignment.module.css';

interface WidgetTooltipAlignmentProps {
  alignment: WidgetAlignPosition | typeof WidgetNeedCalcAlignment;
  isHidden?: boolean;
  isDemoPlayerSmallScreen: boolean; // TODO: https://storylane.atlassian.net/browse/STORY-2570
}

const WidgetTooltipAlignment = forwardRef<
  HTMLDivElement,
  PropsWithChildren<WidgetTooltipAlignmentProps>
>(({ isHidden, alignment, isDemoPlayerSmallScreen, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cx(styles.root, {
        [styles.rootPending]: isHidden,
        [styles.rootSmallScreen]: isDemoPlayerSmallScreen,
      })}
      data-alignment={alignment}
    >
      {children}
    </div>
  );
});

WidgetTooltipAlignment.displayName = 'WidgetTooltipAlignment';

export default WidgetTooltipAlignment;
