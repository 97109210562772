import { VFC } from 'react';

import styles from './DemoPlayerFallback.module.css';

const DemoPlayerFallback: VFC = () => {
  return (
    <div className={styles.root}>
      <h1 className={styles.title}>
        We couldn&apos;t playback the demo.
        <br /> There is a problem with it.
      </h1>
    </div>
  );
};

export default DemoPlayerFallback;
