import cx from 'classnames';
import { VFC } from 'react';

import styles from './DemoImage.module.css';

interface ImageProps {
  src: string;
  isPreloadingHidden: boolean;
}

const DemoImage: VFC<ImageProps> = ({ isPreloadingHidden, src }) => {
  return (
    <img
      className={cx(styles.image, {
        [styles.imagePreloadingHidden]: isPreloadingHidden,
      })}
      src={src}
      alt=""
    />
  );
};

export default DemoImage;
