import { ReactNode, Component, ErrorInfo } from 'react';
import { withScope, captureException } from '@sentry/react';

export interface ErrorBoundaryState {
  hasError: boolean;
  error: string | null;
}

export interface ErrorBoundaryProps {
  fallback?: JSX.Element;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state = { hasError: false, error: null };
  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    return {
      hasError: true,
      error: error.message,
    };
  }
  componentDidCatch(error: Error, errorInfo: ErrorInfo): void {
    // Log the error to an error reporting service
    withScope((scope) => {
      scope.setExtras({ componentStack: errorInfo.componentStack });
      captureException(error);
    });
  }
  render(): ReactNode {
    const { hasError } = this.state;
    const { children, fallback = null } = this.props;

    if (hasError) {
      return fallback;
    }
    return children;
  }
}

export default ErrorBoundary;
