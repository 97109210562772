import { FC, useCallback, useContext } from 'react';
import cx from 'classnames';
import Watermark from 'shared-components/src/components/ui/Watermark';
import { useTypewriter } from 'shared-components/src/features/typewriter/typewriter.hooks';
import IframeLoadManager from 'shared-components/src/utils/IframeLoadManager';

// import VoiceOverAudio from '../VoiceOverAudio';
import { DemoPlayerProps } from '../DemoPlayer';
import DemoPlayerRendererHtml from '../DemoPlayerRendererHtml';
import FlowlistLaunchManager from '../FlowlistLaunchManager';
import FlowlistManager from '../FlowlistManager';
import InteractiveManagerHtml from '../InteractiveManagerHtml';
import Restart from '../Restart';
import WidgetManagerHtml from '../WidgetManagerHtml';
import styles from './DemoPlayerHtml.module.css';
import CustomCtaLaunchButton from '../CustomCtaLaunchButton';
import WidgetBottomTray from '../WidgetBottomTray';
import NavigationBarManager from '../NavigationBarManager';
import { usePlayerState } from '../../hooks/usePlayerState';
import { useFlowlistPlayerState } from '../../hooks/useFlowlistPlayerState';
import { useDemoIsSmallScreen } from '../../hooks/useDemoIsSmallScreen';
// import { useWidgetPlayerState } from '../../hooks/useWidgetPlayerState';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import { ExtUrlTarget } from '../DemoPlayer/DemoPlayer';
import { demoplayerEventCreators } from '../../events/eventCreators';

const DemoPlayerHtml: FC<{
  isRendererReady: boolean;
  onRendererReady: (isReady: boolean) => void;
  onOpenExtUrl: DemoPlayerProps['onOpenExtUrl'];
  isCustomCtaEnabled?: boolean;
}> = (props) => {
  const { isRendererReady, onRendererReady, isCustomCtaEnabled, onOpenExtUrl } =
    props;
  const {
    rendererElement,
    isResponsiveEnabled,
    shouldDisplayBrandWatermark,
    watermarkLogoHref,
    emitter,
  } = useContext(DemoPlayerContext);
  const {
    projectId,
    isChecklistEnabled,
    isNavigationEnabled,
    isInteractiveState,
    autoplayTimeoutSeconds,
    currentWidgetId,
    playerConfig,
  } = usePlayerState();
  const {
    checklist,
    maximizeChecklist,
    checklistIsMinimized,
    minimizeChecklist,
  } = useFlowlistPlayerState();
  // const { widget } = useWidgetPlayerState();

  const isDemoPlayerSmallScreen = useDemoIsSmallScreen();
  const handleFlowlistLaunchClick = useCallback(() => {
    (checklistIsMinimized ? maximizeChecklist : minimizeChecklist)();
  }, [checklistIsMinimized, maximizeChecklist, minimizeChecklist]);
  const showNavigationBar = isNavigationEnabled && !isDemoPlayerSmallScreen;
  const showFlowlistLaunch = isChecklistEnabled && !isDemoPlayerSmallScreen;

  const handleCustomCTAClick = useCallback(() => {
    emitter.emit(demoplayerEventCreators.customCtaClickEvent, {
      data: {
        url: playerConfig.cta_url,
        target: playerConfig.cta_url_target as ExtUrlTarget, // We expect `_target` value to be presented for enabled cta
      },
      widgetId: currentWidgetId ?? undefined,
      projectId,
    });
  }, [
    emitter,
    currentWidgetId,
    projectId,
    playerConfig.cta_url,
    playerConfig.cta_url_target,
  ]);
  const { startTypewriter } = useTypewriter();
  const handleRendererReady = useCallback(
    (isReady: boolean) => {
      onRendererReady(isReady);
      if (isReady && rendererElement) {
        const renderedDocument = (rendererElement as HTMLIFrameElement)
          .contentDocument;
        if (renderedDocument) {
          startTypewriter(renderedDocument);

          new IframeLoadManager({
            entryPoint: renderedDocument,
            onReady: () => undefined,
            onDocumentLoad: (d: Document) => {
              startTypewriter(d);
            },
          });
        }
      }
    },
    [onRendererReady, rendererElement, startTypewriter]
  );

  return (
    <div
      className={cx(styles.root, {
        [styles.rootResponsive]: isResponsiveEnabled,
      })}
    >
      <Restart projectId={projectId} />
      <div className={styles.renderer}>
        <DemoPlayerRendererHtml
          isRendererReady={isRendererReady}
          onReady={handleRendererReady}
        />
      </div>
      {isInteractiveState && (
        <InteractiveManagerHtml
          isRendererReady={isRendererReady}
          onOpenExtUrl={onOpenExtUrl}
        />
      )}
      {showFlowlistLaunch && (
        <FlowlistLaunchManager isResponsive={isResponsiveEnabled} />
      )}
      {isChecklistEnabled && (
        <div className={styles.flowlist}>
          <FlowlistManager onOpenExtUrl={onOpenExtUrl} />
        </div>
      )}
      {shouldDisplayBrandWatermark && (
        <div className={styles.watermark}>
          <Watermark
            href={watermarkLogoHref}
            tooltipDisabled={isDemoPlayerSmallScreen}
          />
        </div>
      )}
      {isDemoPlayerSmallScreen && (
        <WidgetBottomTray
          onFlowlistLaunch={handleFlowlistLaunchClick}
          flowlist={checklist}
          showFlowlistLaunch={isChecklistEnabled}
          rounded
        />
      )}
      <WidgetManagerHtml
        isRendererReady={isRendererReady}
        onOpenExtUrl={onOpenExtUrl}
        autoplayTimeoutSeconds={autoplayTimeoutSeconds}
      />
      {isCustomCtaEnabled && isRendererReady && (
        <CustomCtaLaunchButton
          isResponsiveEnabled={isResponsiveEnabled}
          onCustomCtaExtUrl={handleCustomCTAClick}
          playerConfig={playerConfig}
        />
      )}

      {/* {widget?.audio_url && (
        <VoiceOverAudio
          audioUrl={widget?.audio_url}
          alignment={widget?.media_position}
          isAudioAutoplayEnabled={playerConfig.auto_play !== -1}
        />
      )} */}

      {showNavigationBar && <NavigationBarManager />}
    </div>
  );
};

export default DemoPlayerHtml;
