import { useContext } from 'react';
import { ScreenBreakpointMobile } from 'shared-components/src/features/ui/ui.constants';
import { useWindowSize } from 'shared-components/src/hooks/react-use/useWindowSize';

import { DemoPlayerContext } from '../contexts/DemoPlayerContext';

export function useDemoIsSmallScreen() {
  const { isResponsiveEnabled } = useContext(DemoPlayerContext);
  const { width } = useWindowSize();

  return isResponsiveEnabled ? width <= ScreenBreakpointMobile : false;
}
