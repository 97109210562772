import { forwardRef } from 'react';

import styles from './RendererWidgetSlot.module.css';

/**
 * `RendererWidgetSlot` is a parent `<div />` size-matching container
 * for rendering and positioning widgets.
 */
const RendererWidgetSlot = forwardRef<HTMLDivElement>((props, ref) => {
  return <div ref={ref} className={styles.root} />;
});

RendererWidgetSlot.displayName = 'RendererWidgetSlot';

export default RendererWidgetSlot;
