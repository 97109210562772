import cx from 'classnames';
import { VFC, useMemo } from 'react';
import noop from 'lodash-es/noop';

import { ProjectId } from '../../../features/project/project.types';
import { WidgetId } from '../../../features/widgets/widgets.types';
import styles from './VendorLeadForm.module.css';
import {
  useVendorFormSubmit,
  UseVendorFormSubmitProps,
  useVendorFormScale,
} from './VendorLeadForm.hooks';

interface VendorLeadFormProps {
  projectId: ProjectId;
  onSubmit?: UseVendorFormSubmitProps['onSubmit'];
  width?: number;
  height?: number;
  backgroundColor?: string;
  widgetId?: WidgetId;
  urlQuery?: string;
  isScaleEnabled?: boolean;
}
/**
 * @see {@link https://storylane.atlassian.net/wiki/x/CgAI Docs}
 * @see {@link https://storylane.atlassian.net/wiki/x/AQDaBQ Vendor Form in Development docs}
 */
const VendorLeadForm: VFC<VendorLeadFormProps> = ({
  projectId,
  onSubmit,
  width,
  height,
  backgroundColor,
  widgetId,
  urlQuery,
  isScaleEnabled = false,
}) => {
  useVendorFormSubmit({ onSubmit: onSubmit || noop });
  const { ref: iframeRef } = useVendorFormScale({
    disabled: !isScaleEnabled,
  });

  /**
   * Stub <iframe /> in localhost AS there is going to be a CORS issue.
   */
  const iframeSrcQuery = useMemo(() => {
    return `?${[urlQuery, widgetId ? `widget_id=${widgetId}` : undefined]
      .filter(Boolean)
      .join('&')}`;
  }, [urlQuery, widgetId]);

  return (
    <div
      className={cx({
        [styles.root]: !widgetId,
        [styles.inWidgetRoot]: widgetId,
      })}
      style={{
        backgroundColor,
        width: width ? `${width}px` : '',
        height: height ? `${height}px` : '',
      }}
    >
      <iframe
        ref={iframeRef}
        data-testid="vendor-form"
        className={styles.iframe}
        sandbox="allow-forms allow-scripts allow-same-origin allow-popups allow-popups-to-escape-sandbox"
        src={`/api/v1/shared/projects/${projectId}/lead_form.html${iframeSrcQuery}`}
      />
    </div>
  );
};

export default VendorLeadForm;
