import { ReactNode } from 'react';
import cx from 'classnames';

import {
  WidgetAlignPosition,
  WidgetNeedCalcAlignment,
} from '../../../features/widgets/widgets.constants';
import styles from './WidgetTooltipLayout.module.css';

interface WidgetTooltipLayoutProps {
  alignment: WidgetAlignPosition | typeof WidgetNeedCalcAlignment;
  children: ReactNode;
  showEmptyDiv: boolean; // TODO: https://storylane.atlassian.net/browse/STORY-2570
}

function WidgetTooltipLayout({
  children,
  alignment,
  showEmptyDiv,
}: WidgetTooltipLayoutProps): JSX.Element {
  return (
    <div
      className={cx(styles.root, {
        [styles.rootSmallScreen]: showEmptyDiv,
      })}
      data-alignment={alignment}
    >
      <div className={styles.body}>{children}</div>
    </div>
  );
}

export default WidgetTooltipLayout;
