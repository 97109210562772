import { VFC } from 'react';

import { DEFAULT_BACKDROP_COLOR } from '../../../features/widgets/widgets.constants';
import styles from './WidgetDialogBackdrop.module.css';

const WidgetDialogBackdrop: VFC = () => {
  return (
    <div
      className={styles.root}
      style={{
        backgroundColor: DEFAULT_BACKDROP_COLOR,
      }}
    />
  );
};

export default WidgetDialogBackdrop;
