import { useCallback, useContext } from 'react';
import { useSelector } from '@xstate/react';

import { demoPlayerModel } from '../machine/machine.model';
import { DemoPlayerContext } from '../contexts/DemoPlayerContext';

export function useRestartPlayerState() {
  const { demoPlayerService, replayButtonConfig } =
    useContext(DemoPlayerContext);
  const { send } = demoPlayerService;
  const isWaitingRestartState = useSelector(demoPlayerService, (state) =>
    state.matches('tour.walkthrough.waitRestart')
  );
  const restartPlayer = useCallback(() => {
    send(demoPlayerModel.events.restartPlayer());
  }, [send]);

  return {
    restartPlayer,
    isWaitingRestartState,
    replayButtonConfig,
  };
}
