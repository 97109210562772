import { useCallback, useContext, useMemo } from 'react';
import {
  Flowlist,
  FlowlistItem,
  FlowlistItemId,
} from 'shared-components/src/features/flowlist/flowlist.types';
import { useSelector } from '@xstate/react';

import { DemoPlayerProps } from '../components/DemoPlayer';
import { FlowProgressItem } from '../machine/machine';
import { DemoPlayerFlowsDict } from '../types';
import { DemoPlayerContext } from '../contexts/DemoPlayerContext';
import { demoPlayerModel } from '../machine/machine.model';

interface UseFlowlistPlayerStateReturn {
  checklist: Flowlist | null;
  checklistProgress: FlowlistItemId[];
  flowProgress: FlowProgressItem[];
  flowsDict: DemoPlayerFlowsDict;
  activeItem: ActiveFlowlistItem | null;
  checklistIsMinimized: boolean;
  maximizeChecklist: () => void;
  minimizeChecklist: () => void;
  startChecklistItem: (
    itemId: string,
    onOpenExtUrl?: DemoPlayerProps['onOpenExtUrl']
  ) => void;
  shouldAddWatermark: boolean;
  replayFlowFromCheckList: (flowId: string) => void;
}

export interface ActiveFlowlistItem extends FlowlistItem {
  position: number;
}

export function useFlowlistPlayerState(): UseFlowlistPlayerStateReturn {
  const { demoPlayerService, shouldAddWatermark } =
    useContext(DemoPlayerContext);
  const checklist = useSelector(
    demoPlayerService,
    (state) => state.context.checklist
  );
  const checklistProgress = useSelector(
    demoPlayerService,
    (state) => state.context.progress.checklist
  );
  const flowProgress = useSelector(
    demoPlayerService,
    (state) => state.context.progress.flows
  );
  const currentFlowId = useSelector(
    demoPlayerService,
    (state) => state.context.currentFlowId
  );
  const flowsDict = useSelector(
    demoPlayerService,
    (state) => state.context.flowsDict
  );
  const checklistIsMinimized = useSelector(demoPlayerService, (state) =>
    state.matches('checklist.minimized')
  );
  const { send } = demoPlayerService;

  const isPreview = useSelector(
    demoPlayerService,
    (state) => state.context.config.isPreview
  );

  const activeItem: ActiveFlowlistItem | null = useMemo(() => {
    if (checklist && currentFlowId) {
      const foundItemIndex = checklist.items.findIndex(
        (item) =>
          item.target_kind === 'flow' && item.target_value === currentFlowId
      );

      if (foundItemIndex > -1) {
        return {
          ...checklist.items[foundItemIndex],
          position: foundItemIndex + 1,
        };
      }

      return null;
    }
    return null;
  }, [currentFlowId, checklist]);

  const maximizeChecklist = useCallback(() => {
    send(demoPlayerModel.events.maximizeChecklist());
  }, [send]);

  const minimizeChecklist = useCallback(() => {
    send(demoPlayerModel.events.minimizeChecklist());
  }, [send]);

  const startChecklistItem = useCallback(
    (itemId: string, onOpenExtUrl?: DemoPlayerProps['onOpenExtUrl']) => {
      try {
        if (!checklist) throw new Error('Checklist not found');

        const item = checklist.items.find(
          (item) => item.id === itemId
        ) as FlowlistItem;

        if (!item) {
          console.error('Unable to find flow with id: ' + itemId);
          return;
        }

        if (!item.target_value && isPreview) {
          alert(`${item.name} item is not linked to any flow`);
        }

        if (item.target_kind === 'flow') {
          send(
            demoPlayerModel.events.startFlowFromChecklist(item.target_value)
          );
          send(demoPlayerModel.events.minimizeChecklist());
        } else if (item.target_kind === 'external_url') {
          onOpenExtUrl &&
            onOpenExtUrl({ url: item.target_value, target: '_blank' }, item.id);
          send(demoPlayerModel.events.trackChecklistItemVisit(item.id));
        }
      } catch (error) {
        console.error(error || 'Unable to start checklist item');
      }
    },
    [checklist, isPreview, send]
  );

  const replayFlowFromCheckList = useCallback(
    (flowId: string) => {
      send(demoPlayerModel.events.replayFlowFromChecklist(flowId));
      send(demoPlayerModel.events.minimizeChecklist());
    },
    [send]
  );

  return {
    checklist,
    checklistProgress,
    flowProgress,
    flowsDict,
    activeItem,
    checklistIsMinimized,
    maximizeChecklist,
    minimizeChecklist,
    startChecklistItem,
    shouldAddWatermark,
    replayFlowFromCheckList,
  };
}
