import { useMemo } from 'react';
import { TooltipWidget } from 'shared-components/src/features/widgets/widgets.types';
import WidgetBody from 'shared-components/src/components/widgets/WidgetBody/WidgetBody';
import {
  calculateWidgetTextColor,
  getWidgetArrowPlacement,
  retrieveWidthStyles,
} from 'shared-components/src/features/widgets/widgets.utils';
import WidgetAnimation from 'shared-components/src/components/widgets/WidgetAnimation';
import WidgetFooter from 'shared-components/src/components/widgets/WidgetFooter';
import AudioPlayer from 'shared-components/src/components/widgets/AudioPlayer';
import WidgetText from 'shared-components/src/components/widgets/WidgetText';
import WidgetTooltipLayout from 'shared-components/src/components/widgets/WidgetTooltipLayout';
import { isLightColor } from 'shared-components/src/utils/isLightColor';

import { WidgetComponentProps } from '../Widget';
import styles from './Tooltip.module.css';
import { DemoPlayerArrowWidgetId } from '../../../constants';
import { isWidgetPreviousButtonEnabled } from '../../../utils/isWidgetPreviousButtonEnabled';

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
function Tooltip({
  widget,
  onCta,
  isMounted,
  onExit,
  onReady,
  onPrev,
  onDismiss,
  onSecondary,
  animationDelayMs,
  durationIn,
  durationOut,
  isDemoPlayerSmallScreen,
  isAudioAutoplayEnabled,
  setIsAudioAutoplayEnabled,
  onAudioEnded,
}: WidgetComponentProps<TooltipWidget>) {
  const { options, audio_url } = widget;
  const autoAlignment = widget.autoAlignment;

  const widthStyles = useMemo(
    () => retrieveWidthStyles(widget, 'em'),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [widget.options.root.frameWidth, widget.options.root.maxFrameWidth]
  );
  const widgetBackgroundColor = widget.options.root.backgroundColor || '#fff';
  const isWidgetBgLight = isLightColor(widgetBackgroundColor);

  return (
    <WidgetAnimation
      in={isMounted}
      slideIn={getWidgetArrowPlacement(autoAlignment)}
      durationIn={durationIn}
      durationOut={durationOut}
      delayIn={animationDelayMs}
      onExitAnimationEnd={onExit}
      onEnterAnimationEnd={onReady}
    >
      <WidgetTooltipLayout
        showEmptyDiv={isDemoPlayerSmallScreen}
        alignment={autoAlignment}
      >
        {isDemoPlayerSmallScreen && (
          /**
           * We expect tooltip widget to be rendered to calculate scroll, invoke required callbacks amd playback hidden <audio />.
           * It'll be refactored in TODO: https://storylane.atlassian.net/browse/STORY-2570
           */
          <div className={styles.smallScreenBox}>
            {audio_url && (
              <audio autoPlay={true} src={audio_url} onEnded={onAudioEnded} />
            )}
          </div>
        )}
        {!isDemoPlayerSmallScreen && (
          <WidgetBody
            id={DemoPlayerArrowWidgetId}
            testId={`widget_${widget.kind}_${widget.id}`}
            onDismiss={onDismiss}
            widget={widget}
            autoAlignment={autoAlignment}
            {...widthStyles}
          >
            <WidgetText
              value={options.description.value}
              style={{
                color: calculateWidgetTextColor(
                  widgetBackgroundColor,
                  'primary'
                ),
              }}
            />
            {audio_url && (
              <div className={styles.audio}>
                <AudioPlayer
                  src={widget.audio_url}
                  autoplay={isAudioAutoplayEnabled}
                  onPlayPauseButtonClick={(isPlaying) =>
                    setIsAudioAutoplayEnabled(isPlaying)
                  }
                  onAudioEnded={onAudioEnded}
                  theme={isWidgetBgLight ? 'light' : 'dark'}
                />
              </div>
            )}
            <WidgetFooter
              totalStepsNumber={widget._totalStepsCount}
              currentStepNumber={widget._currentStepNumber}
              widget={widget}
              onSecondary={onSecondary}
              onPrev={onPrev}
              onCta={onCta}
              showPrevButton={isWidgetPreviousButtonEnabled(widget)}
              id={`widget_footer_${widget.id}`}
            />
          </WidgetBody>
        )}
      </WidgetTooltipLayout>
    </WidgetAnimation>
  );
}

export default Tooltip;
