import { DetailedHTMLProps, forwardRef, InputHTMLAttributes } from 'react';
import cx from 'classnames';
import Icon from 'shared-components/src/components/Icon';

import styles from './Input.module.css';

export interface FormInputProps
  extends DetailedHTMLProps<
    InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  > {
  beginIcon?: string;
  onBeginIconClick?: () => void;
  endIcon?: string;
  onEndIconClick?: () => void;
  label?: string;
  error?: string;
  className?: string;
  inputClassName?: string;
  errorClassName?: string;
}

const Input = forwardRef<HTMLInputElement, FormInputProps>((props, ref) => {
  const {
    error,
    className,
    beginIcon,
    onBeginIconClick,
    endIcon,
    onEndIconClick,
    id,
    label,
    inputClassName,
    errorClassName,
    ...restProps
  } = props;

  return (
    <div className={cx(styles.root, className)}>
      {label && (
        <label htmlFor={id} className={styles.label}>
          {label}
        </label>
      )}

      <span className={styles.inputWrap}>
        {beginIcon && (
          <Icon
            name={beginIcon}
            className={styles.icon}
            onClick={onBeginIconClick}
          />
        )}
        <input
          ref={ref}
          id={id}
          className={cx(styles.input, inputClassName)}
          {...restProps}
        />
        {endIcon && (
          <Icon
            name={endIcon}
            className={styles.trailingIcon}
            onClick={onEndIconClick}
          />
        )}
      </span>

      {error && (
        <span className={cx(styles.error, errorClassName)}>{error}</span>
      )}
    </div>
  );
});

Input.displayName = 'FormInput';

export default Input;
