/**
 * In Editor we do not validate URL string provided by the user.
 * Original thought: It's up to the user to provide a valid URL - no time for adding validation and input-mask.
 *
 * Process url to make a well-formatted absolute URL.
 * TODO: remove this when all URLs in database are fixed and URL is getting validated in Editor.
 */
export const stringToUrl = (str: string): string => {
  if (
    str.startsWith('http://') ||
    str.startsWith('https://') ||
    str.startsWith('mailto:')
  ) {
    return str;
  }

  return `https://${str}`;
};
