/**
 * Evaluate supplied JS if it exists
 */
export function evaluateJSSnippet(
  jsSnippet: string,
  rendererElement: HTMLIFrameElement | null
): void {
  if (jsSnippet && rendererElement?.contentWindow) {
    try {
      // @ts-expect-error TODO: tested  iframe.contentWindow.Function with Chrome, Firefox, Safari.
      new rendererElement.contentWindow.Function(
        '"use strict";\n' + jsSnippet
      )();
    } catch (m) {
      console.error('User js: Evaluate JavaScript action failed.', m);
    }
  }
}
