export enum ProjectPageKind {
  Html = 'html',
  Image = 'image',
  Video = 'video',
}

/**
 * To prevent layout shifting we have to set particular dimensions.
 * Currently, BE returns no image meta data(it has to be improved), so we add these values as "magic-constants".
 * TODO: remove once we know really meta data from response.
 */
export const thumbnailImageDimensions = {
  [ProjectPageKind.Image]: {
    width: 224,
    height: 179,
  },
  [ProjectPageKind.Html]: {
    width: 202,
    height: 173,
  },
};
