import { useContext, useEffect } from 'react';
import { scrollIntoView } from 'shared-components/src/utils/scroller';
import { getElementByUniqueSelector } from 'shared-components/src/utils/unique-selector';
import { useSelector } from '@xstate/react';

import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import { DemoPlayerWidgetContext } from '../../contexts/DemoPlayerWidgetContext';

interface UsePersistScrollArg {
  isRendererReady: boolean;
  isDisabled: boolean;
}
export function usePersistScroll(arg: UsePersistScrollArg) {
  const { isRendererReady, isDisabled } = arg;
  const { rendererElement, demoPlayerService } = useContext(DemoPlayerContext);

  const currentPageId = useSelector(
    demoPlayerService,
    (state) => state.context.currentPageId
  );

  const { prevWidgetPageScrollPosition, setPrevWidgetPageScrollPosition } =
    useContext(DemoPlayerWidgetContext);

  useEffect(() => {
    if (isDisabled) {
      return;
    }

    const rendererDocument = (rendererElement as HTMLIFrameElement)
      ?.contentDocument;
    if (
      prevWidgetPageScrollPosition &&
      rendererDocument &&
      prevWidgetPageScrollPosition.pageId !== currentPageId
    ) {
      const targetElement = getElementByUniqueSelector(
        prevWidgetPageScrollPosition.selector,
        rendererDocument
      );
      if (!targetElement) return;
      scrollIntoView({
        isAnimated: false,
        viewportPosition: prevWidgetPageScrollPosition.viewportOffset,
        targetElement,
        innerOffset: {
          x: 0,
          y: 0,
        },
        boundaryDocument: rendererDocument,
      });
      setPrevWidgetPageScrollPosition(null);
    }
  }, [
    prevWidgetPageScrollPosition,
    rendererElement,
    isRendererReady,
    isDisabled,
    setPrevWidgetPageScrollPosition,
    currentPageId,
  ]);

  return {};
}
