import { useCallback, useContext } from 'react';
import { useSelector } from '@xstate/react';
import { DisplayConfigRendererBorder } from 'shared-components/src/features/project/project.types';
import { WidgetId } from 'shared-components/src/features/widgets/widgets.types';

import { demoPlayerModel } from '../machine/machine.model';
import { DemoPlayerContext } from '../contexts/DemoPlayerContext';

export function useRendererPlayerState() {
  const {
    demoPlayerService,
    rendererElement,
    setRendererElement,
    projectKind,
    playerConfig,
    setWidgetSlotElement,
    pageToFileUrl,
    textSubstitutions,
    imageSubstitutions,
    rendererHtmlLoader,
    projectImageTokens,
    projectIframeScripts,
    rendererWrapper,
    isRendererHtmlScaleEnabled,
    rendererDimensions,
    isRecording,
    isAudioAutoplayEnabled,
    isHtmlPreRenderingEnabled,
    isShadowDomEnabled,
  } = useContext(DemoPlayerContext);
  const { send } = demoPlayerService;

  const currentPageId = useSelector(
    demoPlayerService,
    (state) => state.context.currentPageId
  );
  const currentFlowId = useSelector(
    demoPlayerService,
    (state) => state.context.currentFlowId
  );
  const currentWidgetId = useSelector(
    demoPlayerService,
    (state) => state.context.currentWidgetId
  );
  const startPageId = useSelector(
    demoPlayerService,
    (state) => state.context.config.startPageId
  );
  const checklist = useSelector(
    demoPlayerService,
    (state) => state.context.checklist
  );
  const predictedPagesFlow = useSelector(
    demoPlayerService,
    (state) => state.context.pageIdSequence
  );
  const isPreview = useSelector(
    demoPlayerService,
    (state) => state.context.config.isPreview
  );
  const widgetsLinkedMap = useSelector(
    demoPlayerService,
    (state) => state.context.widgetsLinkedMap
  );
  const widgetsDict = useSelector(
    demoPlayerService,
    (state) => state.context.widgetsDict
  );
  const flowsDict = useSelector(
    demoPlayerService,
    (state) => state.context.flowsDict
  );
  const flowsLinkedMap = useSelector(
    demoPlayerService,
    (state) => state.context.flowsLinkedMap
  );
  const isInteractiveState = useSelector(demoPlayerService, (state) =>
    state.matches('tour.interactive')
  );
  const goNextWidget = useCallback(() => {
    send(demoPlayerModel.events.goNextWidget());
  }, [send]);
  const screenSizeWarningWidth = playerConfig.min_width;
  const rendererBorderColor = isRecording
    ? DisplayConfigRendererBorder.None
    : playerConfig.renderer_border_color;
  const navigationColor = playerConfig.nav_bar_color;

  const progress = useSelector(
    demoPlayerService,
    (state) => state.context.progress
  );

  const goToWidget = useCallback(
    (widgetId: WidgetId) => {
      send(demoPlayerModel.events.goToWidget(widgetId));
    },
    [send]
  );

  const isLeadCaptured = useSelector(
    demoPlayerService,
    (state) => state.context.isLeadCaptured
  );

  return {
    checklist,
    startPageId,
    currentPageId,
    currentFlowId,
    currentWidgetId,
    widgetsLinkedMap,
    widgetsDict,
    flowsLinkedMap,
    flowsDict,
    textSubstitutions,
    imageSubstitutions,
    projectImageTokens,
    predictedPagesFlow,
    pageToFileUrl,
    rendererElement,
    setRendererElement,
    setWidgetSlotElement,
    projectKind,
    screenSizeWarningWidth,
    rendererBorderColor,
    rendererHtmlLoader,
    isPreview,
    projectIframeScripts,
    goNextWidget,
    progress,
    goToWidget,
    isLeadCaptured,
    navigationColor,
    isInteractiveState,
    rendererWrapper,
    isRendererHtmlScaleEnabled,
    rendererDimensions,
    isAudioAutoplayEnabled,
    isHtmlPreRenderingEnabled,
    isShadowDomEnabled,
  };
}
