import { useCallback, useEffect, useRef, useState } from 'react';

export const useFullScreen = (targetDomId?: string) => {
  const screenfull = useRef<{
    isEnabled: boolean;
    isFullscreen: boolean;
    request: (el: HTMLElement) => Promise<void>;
    on: (name: 'change' | 'error', handler: (event: Event) => void) => void;
    off: (name: 'change' | 'error', handler: (event: Event) => void) => void;
  } | null>(null);

  const [isFullscreen, setIsFullscreen] = useState(false);
  const [isFullscreenAllowedInBrowser, setIsFullscreenAllowedInBrowser] =
    useState(false);
  const [fullScreenElement, setFullScreenElement] = useState<Element | null>(
    null
  );

  useEffect(() => {
    import('screenfull')
      .then((dep) => (screenfull.current = dep.default))
      .then((screenfull) => {
        setIsFullscreenAllowedInBrowser(screenfull.isEnabled);
        setIsFullscreen(screenfull.isFullscreen);
        setFullScreenElement(screenfull.element);
      });
  }, []);

  const requestFullScreenView = useCallback(async () => {
    try {
      if (screenfull.current?.isEnabled) {
        let targetEl = null;
        if (targetDomId) {
          targetEl = document.getElementById(targetDomId);
        }
        if (!targetEl) targetEl = window.document.documentElement;

        await screenfull.current.request(targetEl);
      }
    } catch (e) {
      console.error('Failed to enable fullscreen', e);
    }
  }, [targetDomId]);

  useEffect(() => {
    if (isFullscreenAllowedInBrowser) {
      const errorHandler = (event: Event) => {
        alert(`Failed to enable fullscreen, reason: ${event}`);
      };

      const changeHandler = () => {
        setIsFullscreen(screenfull.current?.isFullscreen ?? false);
      };

      screenfull.current?.on('error', errorHandler);
      screenfull.current?.on('change', changeHandler);

      return () => {
        screenfull.current?.off('error', errorHandler);
        screenfull.current?.off('change', changeHandler);
      };
    }
  }, [isFullscreenAllowedInBrowser]);

  return {
    showFullScreenButton: isFullscreenAllowedInBrowser,
    requestFullScreenView,
    /**
     * Simple getter which returns a reference: get: () => document[nativeAPI.fullscreenElement] ?? undefined
     */
    fullScreenElement,
    isFullscreen,
  };
};
