import { iterateShadowDomDeep } from './iterateShadowDomDeep';

/**
 * Go through all(root and all "nested" iframes) documents and
 * invoke a callback with each.
 * TODO: return unsubscribe function
 */
export function iterateDocumentDeep(props: {
  doc: Document;
  cb: (d: Document | ShadowRoot) => void;
  options: {
    iterateShadowDom: boolean;
  };
}) {
  const { doc, cb, options } = props;
  const docIframes = doc.querySelectorAll('iframe');

  cb(doc); // invoke callback with a current document;

  if (options.iterateShadowDom) {
    iterateShadowDomDeep(cb)(doc);
  }

  for (const iframe of docIframes) {
    // check if iframe is ready
    if (
      iframe.contentDocument &&
      iframe.contentDocument.readyState === 'complete'
    ) {
      iterateDocumentDeep({
        doc: iframe.contentDocument,
        cb,
        options,
      });
    }
  }
}
