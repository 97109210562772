import { isDocument, isDocumentFragment } from '../../utils/dom';
import {
  htmlElementBlurredClassName,
  htmlImagePageBodyClassName,
  rendererStylesId,
  rendererSVGSubstitutionsContainerId,
} from './renderer.constants';

export function injectRendererStyles(d: Document | ShadowRoot): void {
  // update storylane existing styles
  const foundStyles = d.querySelectorAll(`[id="${rendererStylesId}"]`);
  if (foundStyles.length) {
    foundStyles.forEach((el) => el.remove());
  }

  let stylesEl = createStylesElement(isDocument(d) ? d : d.ownerDocument);
  if (isDocument(d)) {
    // append latest version of styles into document.body
    const docHead = d.head as HTMLHeadElement | null; // it'll be `null` if `body` element is not presented
    if (docHead) {
      docHead.appendChild(stylesEl.cloneNode(true));
    }
  } else if (isDocumentFragment(d)) {
    d.appendChild(stylesEl.cloneNode(true));
  }
  stylesEl = null as any;
}

function createStylesElement(doc: Document) {
  const stylesEl = doc.createElement('style');
  stylesEl.id = rendererStylesId;
  // No `?raw` import reason: https://storylane.atlassian.net/browse/STORY-2440
  stylesEl.innerHTML = `
  *[data-storylane-hidden-el='true'] {
    pointer-events: none !important;
    visibility: hidden !important;
  }
  
  *[data-storylane-inline-text-edit='true'] {
    cursor: text !important;
    outline: 0px solid transparent !important;
  }

  .${htmlElementBlurredClassName} {
    user-select: none;
  }
  
  .${htmlImagePageBodyClassName} {
    position: relative;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
  }
  .${htmlImagePageBodyClassName} > img {
    display: block;
  }
  `;
  return stylesEl;
}

/**
 * Inject container into body where image-substitutions svg will be placed.
 * @see TODO: write docs
 * @param doc
 * @returns
 */
export function injectSVGSubstitutionsContainer(doc: Document): Document {
  // remove storylane existing svg container
  const foundDiv = doc.getElementById(rendererSVGSubstitutionsContainerId);
  if (foundDiv) {
    foundDiv.remove();
  }

  // append div into document.body
  const docBody = doc.body as HTMLElement | null; // it'll be `null` if `body` element is not presented
  if (docBody) {
    const divEl = doc.createElement('div');
    divEl.id = rendererSVGSubstitutionsContainerId;
    divEl.style.display = 'none';
    docBody.appendChild(divEl);
  }

  return doc;
}

export function getSVGSubstitutionDomId(id: string): string {
  return `sl-svg-substitution-${id}`;
}
