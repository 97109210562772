import { captureMessage } from '@sentry/react';
import { useEffect, useMemo, useRef, useState } from 'react';
import { HtmlString } from 'shared-components/src/features/renderer/renderer.types';
import {
  fillCustomSubstitutions,
  retrieveSubstitutionKeyFromBreadcrumbHtmlComment,
} from 'shared-components/src/features/substitution/substitution.utils';
import { SentrySlowOperationMs } from 'shared-components/src/features/sentry/sentry.constants';

import { DemoPlayerTextSubstitutionsDict } from '../../types';
import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';
import { DemoPlayerPageHtmlManager } from './DemoPlayerRendererHtml.utils';

export function usePageHtmls(isRendererReady: boolean): {
  pageHtml: HtmlString | null;
} {
  const {
    currentPageId,
    predictedPagesFlow,
    textSubstitutions,
    pageToFileUrl,
    isPreview,
    rendererElement,
  } = useRendererPlayerState();
  const [renderKey, setRenderKey] = useState(0);

  const manager = useRef<DemoPlayerPageHtmlManager>();

  useEffect(() => {
    if (!manager.current) {
      manager.current = new DemoPlayerPageHtmlManager({
        queue: predictedPagesFlow,
        pages: pageToFileUrl,
        onChange: () => setRenderKey((prevKey) => prevKey + 1),
        textSubstitutions,
        isPreview,
      });
    }
  }, [isPreview, pageToFileUrl, predictedPagesFlow, textSubstitutions]);

  useEffect(() => {
    manager.current?.updateTextSubstitutions(textSubstitutions);
  }, [textSubstitutions]);

  const pageData = useMemo(() => {
    return manager.current?.requestPageData(currentPageId);
    // `renderKey` helps to retrieve async page data
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentPageId, renderKey]);

  const rendererIframeElement = rendererElement as HTMLIFrameElement | null; // Only available for HTML-based projects, hence it is expected to be HTMLIFrameElement.
  useEffect(() => {
    if (
      isRendererReady &&
      rendererIframeElement?.contentDocument &&
      pageData?.substitutions
    ) {
      const parseIsRequired = Object.entries(pageData.substitutions).some(
        ([substitutionKey, substitutionValue]) =>
          textSubstitutions[substitutionKey] &&
          textSubstitutions[substitutionKey] !== substitutionValue
      );
      if (parseIsRequired) {
        const startTime = performance.now();
        replaceSubstitutionTextNodes(
          rendererIframeElement.contentDocument,
          textSubstitutions
        );
        const finishTime = performance.now();
        const executionTime = finishTime - startTime;
        if (executionTime > SentrySlowOperationMs) {
          captureMessage(`usePageHtmls parsed html in ${executionTime} ms`);
        }
      }
    }
  }, [
    isRendererReady,
    textSubstitutions,
    rendererIframeElement,
    pageData?.substitutions,
  ]);

  const pageHtml = pageData?.html || null;
  return {
    pageHtml,
  };
}

function replaceSubstitutionTextNodes(
  doc: Document,
  textSubstitutions: DemoPlayerTextSubstitutionsDict
) {
  const iframes = doc.querySelectorAll('iframe');
  iframes.forEach((iframe) => {
    if (
      iframe.contentDocument &&
      iframe.contentDocument.readyState === 'complete' &&
      iframe.srcdoc
    ) {
      replaceSubstitutionTextNodes(iframe.contentDocument, textSubstitutions);
    }
  });
  const nodeIterator = document.createNodeIterator(
    doc,
    NodeFilter.SHOW_COMMENT
  );
  let commentNode;
  const collectedNodesForReplacement = new Map<Node, string>([]);
  while ((commentNode = nodeIterator.nextNode())) {
    const commentNodeTextContent = commentNode.textContent;

    // we iterate through unknown customer's document => skip EMPTY html comment nodes
    if (!commentNodeTextContent) {
      continue;
    }

    let textContentToFillWithSubstitution = commentNodeTextContent;

    const textContentWithSubstitutionBreadcrumb =
      retrieveSubstitutionKeyFromBreadcrumbHtmlComment(
        textContentToFillWithSubstitution
      );
    if (textContentWithSubstitutionBreadcrumb) {
      /**
       * We expect next sibling to be the text Node with a substitution value,
       * because we retrieved substitution key from a "breadcrumb comment" we left on previous substitutions replacement iteration.
       * TODO: add doc reference if presented or create a new one
       */
      const prevReplacedTextNode = commentNode.nextSibling;
      if (prevReplacedTextNode) {
        prevReplacedTextNode.remove(); // remove it to replace with a relevant substitution value
      }
      textContentToFillWithSubstitution = textContentWithSubstitutionBreadcrumb;
    }

    const nextText = fillCustomSubstitutions(
      textContentToFillWithSubstitution,
      textSubstitutions,
      true,
      true
    ).result;

    if (nextText) {
      collectedNodesForReplacement.set(commentNode, nextText);
    }
  }

  for (const [
    nodeToReplace,
    substitutionValue,
  ] of collectedNodesForReplacement.entries()) {
    /**
     * nextText is expected to contain html comment + substitution value(e.g. "<!--sl-substitution-key-breadcrumb-{{first_name}}-->John" ),
     * hence we need to parse it as HTML-string to get 2 nodes: COMMENT_NODE and TEXT_NODE.
     */
    const tempDiv = nodeToReplace.ownerDocument?.createElement('div');
    if (!tempDiv) continue; // for some reason ownerDocument is not available and HTMLDivElement is not created.
    tempDiv.innerHTML = substitutionValue;

    const [nextBreadcrumbCommentNode, nextSubstitutionValueTextNode] =
      tempDiv.childNodes;

    // Get the parent node of the existing comment node
    const parentNode = nodeToReplace.parentNode;
    if (!parentNode) continue;

    // Replace the existing comment node with the new comment node and text node
    parentNode.insertBefore(
      nextSubstitutionValueTextNode,
      nodeToReplace.nextSibling
    );
    parentNode.replaceChild(nextBreadcrumbCommentNode, nodeToReplace);
  }
}
