import { CSSProperties, forwardRef } from 'react';
import cx from 'classnames';

import { DEFAULT_BACKDROP_COLOR } from '../../../features/widgets/widgets.constants';
import styles from './WidgetBackdropWithSpot.module.css';

interface BackdropSpotProps {
  className?: string;
  style?: CSSProperties;
  onClick?: () => void;
}

interface BackdropStyles extends CSSProperties {
  '--backdrop-color'?: string;
}

const WidgetBackdropWithSpot = forwardRef<HTMLDivElement, BackdropSpotProps>(
  ({ className, onClick, style = {} }, ref) => {
    return (
      <div
        ref={ref}
        style={
          {
            ...style,
            '--backdrop-color': DEFAULT_BACKDROP_COLOR,
          } as BackdropStyles
        }
        className={cx(styles.root, className)}
        onClick={onClick}
      />
    );
  }
);

WidgetBackdropWithSpot.displayName = 'WidgetBackdropWithSpot';

export default WidgetBackdropWithSpot;
