import { ReactNode } from 'react';
import cx from 'classnames';

import {
  WidgetAlignPosition,
  WidgetNeedCalcAlignment,
} from '../../../features/widgets/widgets.constants';
import styles from './WidgetHotspotLayout.module.css';
import Beacon from './Beacon';
import Body from './Body';

interface WidgetHotspotLayoutProps {
  alignment: WidgetAlignPosition | typeof WidgetNeedCalcAlignment;
  onlyBeacon: boolean;
  children: ReactNode;
  id?: string;
}

function WidgetHotspotLayout({
  children,
  alignment,
  onlyBeacon,
  id,
}: WidgetHotspotLayoutProps): JSX.Element {
  return (
    <div
      aria-label="Widget Hotspot Layout"
      id={id}
      className={cx(styles.root, {
        [styles.rootOnlyBeacon]: onlyBeacon,
      })}
      data-alignment={alignment}
    >
      {children}
    </div>
  );
}

WidgetHotspotLayout.Beacon = Beacon;
WidgetHotspotLayout.Body = Body;

export default WidgetHotspotLayout;
