/**
 * Expose `unique`
 */

import { getID } from './getID';
import getAHref from './getAHref';
import { getClassSelectors } from './getClasses';
import { getCombinations } from './getCombinations';
import { getAttributes } from './getAttributes';
import { getNthChild } from './getNthChild';
import { getTag } from './getTag';
import { isUnique } from './isUnique';
import { getParents } from './getParents';

export enum Selector {
  Tag = 'Tag',
  NthChild = 'NthChild',
  Id = 'ID',
  Class = 'Class',
  Attributes = 'Attributes',
  AHref = 'AHref',
}

interface getAllSelectorsReturn {
  [Selector.Tag]?: string;
  [Selector.NthChild]?: string | null;
  [Selector.Attributes]?: string[];
  [Selector.Class]?: string[];
  [Selector.Id]?: string | null;
  [Selector.AHref]?: string | null;
}

/**
 * Returns all the selectors of the elemenet
 * @param el
 * @param selectors
 * @param attributesToIgnore
 */
function getAllSelectors(
  el: Element,
  selectors: Selector[],
  attributesToIgnore: IgnorableAttribute[]
): getAllSelectorsReturn {
  const funcs = {
    [Selector.Tag]: getTag,
    [Selector.NthChild]: getNthChild,
    [Selector.Attributes]: (elem: Element) =>
      getAttributes(elem, attributesToIgnore),
    [Selector.Class]: getClassSelectors,
    [Selector.Id]: getID,
    [Selector.AHref]: getAHref,
  } as const;

  return selectors.reduce((res, next) => {
    // @ts-expect-error TODO: improve typings
    res[next] = funcs[next](el);
    return res;
  }, {});
}

/**
 * Tests uniqueNess of the element inside its parent
 * @param element
 * @param selector
 */
function testUniqueness(element: Element, selector: string) {
  try {
    const { parentNode } = element;
    // @ts-expect-error TODO: cover vendor lib with TS
    const elements = parentNode.querySelectorAll(selector);
    return elements.length === 1 && elements[0] === element;
  } catch (error) {
    return false;
  }
}

/**
 * Tests all selectors for uniqueness and returns the first unique selector.
 * @param  { Object } element
 * @param  { Array } selectors
 * @return { String }
 */
function getFirstUnique(element: Element, selectors: string[]) {
  return selectors.find(testUniqueness.bind(null, element));
}

/**
 * Checks all the possible selectors of an element to find one unique and return it
 * @param  { Object } element
 * @param  { Array } items
 * @param  { String } tag
 * @return { String }
 */
// @ts-expect-error TODO: cover vendor lib with TS
function getUniqueCombination(element: Element, items, tag) {
  let combinations = getCombinations(items, 3),
    firstUnique = getFirstUnique(element, combinations);

  if (firstUnique) {
    return firstUnique;
  }

  if (tag) {
    combinations = combinations.map((combination) => tag + combination);
    firstUnique = getFirstUnique(element, combinations);

    if (firstUnique) {
      return firstUnique;
    }
  }

  return null;
}

/**
 * Returns a uniqueSelector based on the passed options
 * @param element
 * @param selectorTypes
 * @param attributesToIgnore
 * @param excludeRegex
 */
function getUniqueSelector(
  element: Element,
  selectorTypes: Selector[],
  attributesToIgnore: IgnorableAttribute[],
  excludeRegex: RegExp | null
): string {
  let foundSelector;

  const elementSelectors = getAllSelectors(
    element,
    selectorTypes,
    attributesToIgnore
  );

  if (excludeRegex && excludeRegex) {
    // @ts-expect-error TODO: cover vendor lib with TS
    elementSelectors.ID = excludeRegex.test(elementSelectors.ID)
      ? null
      : elementSelectors.ID;
    // @ts-expect-error TODO: cover vendor lib with TS
    elementSelectors.Class = elementSelectors.Class.filter(
      (className) => !excludeRegex.test(className)
    );
  }

  for (const selectorType of selectorTypes) {
    const {
      ID,
      Tag,
      Class: Classes,
      Attributes,
      NthChild,
      AHref,
    } = elementSelectors;
    switch (selectorType) {
      case Selector.Id:
        if (ID && testUniqueness(element, ID)) {
          return ID;
        }
        break;

      case Selector.Tag:
        if (Tag && testUniqueness(element, Tag)) {
          return Tag;
        }
        break;

      case Selector.AHref:
        if (AHref && testUniqueness(element, AHref)) {
          return AHref;
        }
        break;

      case Selector.Class:
        if (Array.isArray(Classes) && Classes.length) {
          foundSelector = getUniqueCombination(element, Classes, Tag);
          if (foundSelector) {
            return foundSelector;
          }
        }
        break;

      case Selector.Attributes:
        if (Array.isArray(Attributes) && Attributes.length) {
          foundSelector = getUniqueCombination(element, Attributes, Tag);
          if (foundSelector) {
            return foundSelector;
          }
        }
        break;

      case Selector.NthChild:
        if (NthChild) {
          return NthChild;
        }
    }
  }
  return '*';
}

// type SelectorType = 'ID' | 'Class' | 'Tag' | 'NthChild';
type IgnorableAttribute = 'id' | 'class' | 'length';
interface UniqueOptions {
  selectorTypes?: Selector[];
  attributesToIgnore?: IgnorableAttribute[];
  excludeRegex?: RegExp | null;
  strict?: boolean;
}

/**
 * Generate unique CSS selector for given DOM element
 * https://gitlab.com/storylane-devs/product/-/wikis/Unique-Selectors
 * @param el
 * @param options
 * @api
 */
function __unique(el: Element, options: UniqueOptions) {
  const {
    // selectorTypes order is important. Check getUniqueSelector method
    selectorTypes = [
      Selector.Id,
      Selector.AHref,
      Selector.Class,
      Selector.Tag,
      Selector.NthChild,
    ],
    attributesToIgnore = ['id', 'class', 'length'],
    excludeRegex = null,
    strict = false,
  } = options;
  const allSelectors: string[] = [];
  const parents = getParents(el);

  for (const elem of parents) {
    const selector = getUniqueSelector(
      elem,
      selectorTypes,
      attributesToIgnore,
      excludeRegex
    );
    if (selector) {
      allSelectors.push(selector);
    }
  }

  const selectors = [];
  if (!strict) {
    for (const it of allSelectors) {
      selectors.unshift(it);
      const selector = selectors.join(' > ');
      if (isUnique(el, selector)) {
        return selector;
      }
    }
  }

  const selector = allSelectors.reverse().join(' > ');
  if (isUnique(el, selector)) {
    return selector;
  }

  return null;
}

/**
 * Get <a> selector to the closest DOM element
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/622643/Page+Link#Selector Docs}
 */
export function getHrefSelector(
  el: Element,
  ignoreSearchParams: boolean
): string | null {
  const aHrefElement = el.closest('a[href]');
  if (!aHrefElement) return null;

  let href = aHrefElement.getAttribute('href');
  if (
    !href ||
    href.startsWith('#') ||
    href.startsWith('?') ||
    href.startsWith('mailto') ||
    href.startsWith('tel') ||
    href.startsWith('javascript:void(0)')
  )
    return null;

  const removeSearchParams = (value: string) => value.replace(/\?.*$/, '');
  if (ignoreSearchParams && href.includes('?')) {
    href = removeSearchParams(href);
    return `a[href^="${href}?"],a[href="${href}"]`;
  }
  return `a[href="${href}"]`;
}

export function getRegularSelector(
  el: Element,
  options: UniqueOptions = {}
): string {
  return __unique(el, options) ?? '';
}

function isDocument(node: Node): node is Document {
  return node.nodeType === Node.DOCUMENT_NODE;
}

function isDocumentFragment(node: Node): node is DocumentFragment {
  return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
}

function isShadowHost(node: Node): node is Element {
  return (
    node.nodeType === Node.ELEMENT_NODE &&
    'shadowRoot' in node &&
    (node as Element).shadowRoot !== null
  );
}

export const uniqSelectorIframeDivider = '$IFRAME$';
export const uniqSelectorShadowRootDivider = '$SHADOW$';

export default function uniqueSelector(
  el: Element,
  options: UniqueOptions = {},
  boundaryDocument: Document
): string {
  let result = '';
  let currentDocument = null;
  let currentElement: Element | null = el;

  let continueIframeWhile = true;
  while (continueIframeWhile) {
    let continueShadowDomWhile = true;
    while (continueShadowDomWhile) {
      const selector = __unique(currentElement, options);

      if (selector === null) {
        result = '';
        break;
      }

      result = selector + result;

      const rootNode = currentElement.getRootNode();
      const isShadowDom =
        rootNode.nodeType === 11 && (rootNode as ShadowRoot).host;

      if (isShadowDom) {
        result = `${uniqSelectorShadowRootDivider}${result}`;
        currentElement = (rootNode as ShadowRoot).host;
      } else {
        continueShadowDomWhile = false;
      }
    }

    currentDocument = currentElement.ownerDocument;
    // check if element doesn't belong to boundary document element
    if (currentDocument !== boundaryDocument) {
      // is nested in iframe
      const iframeEl: Element | undefined | null =
        currentDocument?.defaultView?.frameElement;
      if (iframeEl) {
        result = `${uniqSelectorIframeDivider}${result}`;
        currentElement = iframeEl;
      } else {
        result = '';
        break;
      }
    } else {
      continueIframeWhile = false;
    }
  }

  return result;
}

function isIframeElement(el: Element): el is HTMLIFrameElement {
  return el.nodeName === 'IFRAME';
}

/**
 * Get element by unique-selector which might be placed in a nested iframe.
 *
 * @param selector element selector
 * @param boundaryDocument top-most(root) document. Element search starts from it.
 * @returns element or `null`
 */
export function getElementByUniqueSelector(
  selector: string,
  boundaryDocument: Document
): Element | null {
  /**
   * "html > body #iframe$IFRAME$body div$SHADOW$p > span"
   *  is converted into
   * ["html > body #iframe", "body div", "p > span"]
   */
  const composedSelector = selector.split(/\$IFRAME\$|\$SHADOW\$/gm);

  if (!composedSelector.length) return null;

  let elementToQueryFrom: Document | ShadowRoot | Element | HTMLIFrameElement =
    boundaryDocument;
  let foundElement: Element | null = null;
  for (const subSelector of composedSelector) {
    if (
      isDocument(elementToQueryFrom) ||
      isDocumentFragment(elementToQueryFrom)
    ) {
      foundElement = elementToQueryFrom.querySelector(subSelector);
    } else if (
      isIframeElement(elementToQueryFrom) &&
      elementToQueryFrom.contentDocument
    ) {
      foundElement =
        elementToQueryFrom.contentDocument.querySelector(subSelector);
    } else if (isShadowHost(elementToQueryFrom)) {
      foundElement = (
        elementToQueryFrom.shadowRoot as ShadowRoot
      ).querySelector(subSelector);
    }

    // selectors chain is broken. null should be returned.
    if (!foundElement) {
      return null;
    }

    elementToQueryFrom = foundElement;
  }

  return foundElement;
}

/**
 * Returns "final selector" of the unique-selector.
 *
 * For example:
 * "body > class > #id$IFRAME$body > class2" unique-selector points to an element within a nested iframe.
 * "body > class2" will be a "final-selector" for it.
 *
 *
 * @param selector
 * @returns
 */
export function getFinalFrameSelectorFromUniqueSelector(
  selector: string
): string {
  const parsedSelector = selector.split(/\$IFRAME\$/gm);
  return parsedSelector[parsedSelector.length - 1];
}
