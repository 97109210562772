import {
  useCallback,
  useEffect,
  useRef,
  useState,
  VFC,
  PointerEvent,
  CSSProperties,
} from 'react';
import { DemoPlayerSmScreemBottomSpace } from 'shared-components/src/features/ui/ui.constants';
import noop from 'lodash-es/noop';
import { calculateWidgetTextColor } from 'shared-components/src/features/widgets/widgets.utils';

import styles from './WidgetBottomTray.module.css';
import WidgetBottomTrayFlowlistLaunch from './WidgetBottomTrayFlowlistLaunch';
import WidgetBottomTrayBody from './WidgetBottomTrayBody';
import { DemoPlayerFlowlist, DemoPlayerWidget } from '../../types';

interface WidgetBottomTrayProps {
  onFlowlistLaunch: () => void;
  rounded: boolean;
  showFlowlistLaunch: boolean;
  flowlist: DemoPlayerFlowlist | null;
  widget?: DemoPlayerWidget;
  onCta?: () => void;
  onPrev?: () => void;
  onSecondary?: () => void;
}

const WidgetBottomTray: VFC<WidgetBottomTrayProps> = (props) => {
  const {
    widget,
    onCta,
    onPrev,
    onSecondary,
    onFlowlistLaunch,
    rounded,
    showFlowlistLaunch,
    flowlist,
  } = props;
  const [isExpanded, setIsExpanded] = useState(false);
  const [contentHeight, setContentHeight] = useState(0);

  const contentRef = useRef<HTMLDivElement | null>(null);
  const secondaryRef = useRef<HTMLButtonElement | null>(null);
  const ctaRef = useRef<HTMLButtonElement | null>(null);
  const prevRef = useRef<HTMLButtonElement | null>(null);
  const launchRef = useRef<HTMLButtonElement | null>(null);

  useEffect(() => {
    setIsExpanded(false);
    setContentHeight(contentRef.current?.clientHeight || 0);
  }, [widget?.id]);

  const handleClick = useCallback((e: PointerEvent<HTMLDivElement>) => {
    const target = e.target as HTMLElement;
    if (
      ctaRef.current?.contains(target) ||
      secondaryRef.current?.contains(target) ||
      prevRef.current?.contains(target) ||
      launchRef.current?.contains(target)
    ) {
      return;
    }
    setIsExpanded((prevIsExpanded) => !prevIsExpanded);
  }, []);
  const handleFlowlistLaunchClick = useCallback(() => {
    onFlowlistLaunch();
    setIsExpanded(false);
  }, [onFlowlistLaunch]);
  const isExpandable = contentHeight > DemoPlayerSmScreemBottomSpace;
  const primaryColor =
    widget?.primaryColor ?? flowlist?.options.launch.backgroundColor ?? '';
  const secondaryColor = calculateWidgetTextColor(primaryColor, 'primary');

  return (
    <div
      className={styles.root}
      style={
        {
          '--widget-bottom-tray-color': primaryColor,
          '--widget-bottom-tray-secondary-color': secondaryColor,
        } as CSSProperties
      }
      data-rounded={rounded}
    >
      <div
        className={styles.body}
        data-flowlist={showFlowlistLaunch}
        data-expanded={isExpanded}
        style={{
          height: isExpanded ? contentHeight : DemoPlayerSmScreemBottomSpace,
        }}
        onClick={isExpandable && widget ? handleClick : undefined}
      >
        {showFlowlistLaunch && (
          <WidgetBottomTrayFlowlistLaunch
            ref={launchRef}
            onClick={handleFlowlistLaunchClick}
          />
        )}
        {widget && (
          <WidgetBottomTrayBody
            showFlowlistLaunch={showFlowlistLaunch}
            widget={widget}
            hideShadow={isExpanded}
            onCta={onCta ?? noop}
            onSecondary={onSecondary ?? noop}
            onPrev={onPrev ?? noop}
            contentRef={contentRef}
            ctaRef={ctaRef}
            prevRef={prevRef}
            secondaryRef={secondaryRef}
          />
        )}
      </div>
    </div>
  );
};

export default WidgetBottomTray;
