import { CSSProperties } from 'react';
import cx from 'classnames';
import { CSSTransition } from 'react-transition-group';
import {
  FlowlistItemTargetKind,
  FlowlistLaunchButtonPosition,
} from 'shared-components/src/features/flowlist/flowlist.constants';
import Icon from 'shared-components/src/components/Icon';
import WatermarkInline from 'shared-components/src/components/ui/WatermarkInline';

import Item from './Item';
import styles from './Flowlist.module.css';
import {
  DemoPlayerFlowlist,
  DemoPlayerFlowlistFlowProgress,
  DemoPlayerFlowlistItem,
} from '../../../types';
import { ActiveFlowlistItem } from '../../../hooks/useFlowlistPlayerState';

interface FlowlistProps {
  flowlist: DemoPlayerFlowlist;
  progress: DemoPlayerFlowlistItem['id'][];
  flowlistFlowProgress: DemoPlayerFlowlistFlowProgress;
  activeItem: ActiveFlowlistItem | null;
  onItemClick: (item: DemoPlayerFlowlistItem) => void;
  onClose: () => void;
  isMinimized: boolean;
  shouldAddWatermark: boolean;
  onReplayClickHandler: (item: DemoPlayerFlowlistItem) => void;
}

function Flowlist({
  flowlist,
  onClose,
  progress,
  flowlistFlowProgress,
  onItemClick,
  isMinimized,
  activeItem,
  shouldAddWatermark,
  onReplayClickHandler,
}: FlowlistProps): JSX.Element {
  const data = flowlist.options.launch;
  const completeOnClick = flowlist.options.root.completeOnClick;
  const showStepCount = flowlist.options.root.showStepCount;
  const handleItemClick = (item: DemoPlayerFlowlistItem) => () => {
    onItemClick(item);
  };

  const handleReplayItemClick = (item: DemoPlayerFlowlistItem) => () => {
    onReplayClickHandler(item);
  };

  return (
    <CSSTransition
      classNames={{
        appear: styles.rootMaximizing,
        appearActive: styles.rootMaximizingActive,
        appearDone: styles.rootMaximizingDone,
        enter: styles.rootMaximizing,
        enterActive: styles.rootMaximizingActive,
        enterDone: styles.rootMaximizingDone,
        exit: styles.rootMinimizing,
        exitActive: styles.rootMinimizingActive,
        exitDone: styles.rootMinimizingDone,
      }}
      mountOnEnter
      in={!isMinimized}
      appear
      unmountOnExit
      timeout={{
        appear: 500,
        exit: 500,
      }}
    >
      <div className={styles.root} data-testid="flowlist">
        <div
          className={cx(styles.body, {
            [styles.bodyCenterPosition]:
              flowlist.options.root.position === 'center',
            [styles.bodyCornerPosition]:
              flowlist.options.root.position === 'corner',
            [styles.bodyStickedToCenter]:
              flowlist.options.launch.position ===
              FlowlistLaunchButtonPosition.Center,
            [styles.bodyStickedToLeft]:
              flowlist.options.launch.position ===
              FlowlistLaunchButtonPosition.Left,
            [styles.bodyStickedToRight]:
              flowlist.options.launch.position ===
              FlowlistLaunchButtonPosition.Right,
          })}
          style={
            {
              '--offsetX': `${data.offsetX || 0}%`,
              '--offsetY': `${data.offsetY || 0}%`,
            } as CSSProperties
          }
        >
          <Icon
            className={styles.iconCollapse}
            name="collapse"
            onClick={onClose}
          />
          <div className={styles.headerWrap}>
            <div className={styles.header}>{flowlist.options.title.value}</div>
            {flowlist.options.description.value && (
              <div className={styles.subtitle}>
                {flowlist.options.description.value}
              </div>
            )}
          </div>
          <div className={styles.content}>
            {flowlist.items
              .filter((item) => !item._destroy)
              .map((item, index) => {
                const itemProgress =
                  item.target_kind === FlowlistItemTargetKind.Flow
                    ? flowlistFlowProgress[item.target_value]
                    : undefined;
                const completedStepsCount =
                  showStepCount && itemProgress ? itemProgress.current : NaN;
                const totalStepsCount =
                  showStepCount && itemProgress ? itemProgress.total : NaN;

                return (
                  <Item
                    key={item.id}
                    item={item}
                    position={index + 1}
                    isActive={activeItem?.id === item.id}
                    onClick={handleItemClick(item)}
                    completed={completeOnClick && progress.includes(item.id)}
                    completedStepsCount={completedStepsCount}
                    totalStepsCount={totalStepsCount}
                    style={
                      {
                        '--launchButtonColor': flowlist.options.launch.color,
                        '--launchButtonBackgroundColor':
                          flowlist.options.launch.backgroundColor,
                      } as CSSProperties
                    }
                    onReplayClick={handleReplayItemClick(item)}
                  />
                );
              })}

            {shouldAddWatermark && (
              <WatermarkInline className={styles.watermark} />
            )}
          </div>
        </div>
      </div>
    </CSSTransition>
  );
}

export default Flowlist;
