export const rendererStylesId = 'storylane-styles';
export const rendererHiddenElementDataAttribute = 'data-storylane-hidden-el';

/**
 * "widget" in a "shadow-dom" within html was deprecated and reworked to be regulat react-component.
 * "storylane-widget-container" was an id of an element where "shadow-dom".
 * Some old html projects might still have it.
 */
export const widgetShadowContainerId = 'storylane-widget-container';

export const rendererSVGSubstitutionsContainerId =
  'storylane-svg-substitutions';

export const rendererDefaultDimensions = {
  width: 1280,
  height: 720,
};

export const rendererIframeName = 'sl-renderer' as const;

export const htmlImagePageBodyClassName = 'storylane-image-page-body';
export const htmlElementBlurredClassName = 'storylane-blur-element';
