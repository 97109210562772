import { forwardRef, PropsWithChildren } from 'react';
import cx from 'classnames';

import {
  WidgetNeedCalcAlignment,
  WidgetAlignPosition,
} from '../../../features/widgets/widgets.constants';
import styles from './WidgetHotspotAlignment.module.css';

interface WidgetHotspotAlignmentProps {
  alignment: WidgetAlignPosition | typeof WidgetNeedCalcAlignment;
  onlyBeacon: boolean;
  isHidden?: boolean;
}

const WidgetHotspotAlignment = forwardRef<
  HTMLDivElement,
  PropsWithChildren<WidgetHotspotAlignmentProps>
>(({ isHidden, onlyBeacon, alignment, children }, ref) => {
  return (
    <div
      ref={ref}
      className={cx(styles.root, {
        [styles.rootPending]: isHidden,
        [styles.rootHideContent]: onlyBeacon,
      })}
      data-alignment={alignment}
    >
      {children}
    </div>
  );
});

WidgetHotspotAlignment.displayName = 'WidgetHotspotAlignment';

export default WidgetHotspotAlignment;
