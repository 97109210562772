import {
  useState,
  CSSProperties,
  useCallback,
  useEffect,
  useRef,
  VFC,
} from 'react';

import {
  HotspotWidget,
  TooltipWidget,
} from '../../../features/widgets/widgets.types';
import { getElementByUniqueSelector } from '../../../utils/unique-selector';
import { getNestedBoundingClientRect } from '../../../utils/dom';
import WidgetBackdropWithSpot from '../WidgetBackdropWithSpot';
import useRendererHtmlObservers from '../../renderer/RendererHtml/useRendererHtmlObservers';
import { getWidgetBackdropSelector } from '../../../features/widgets/widgets.utils';
import WidgetSpotlight from '../WidgetSpotlight';
import { WidgetBackdrop as WidgetBackdropType } from '../../../features/widgets/widgets.constants';

interface WidgetTooltipBackdropHtmlProps {
  widget: HotspotWidget | TooltipWidget;
  rendererElement: HTMLIFrameElement;
  isScaleEnabled: boolean;
}

const WidgetTooltipBackdropHtml: VFC<WidgetTooltipBackdropHtmlProps> = (
  props
) => {
  const { widget, rendererElement, isScaleEnabled } = props;
  const showBackdrop = widget.options.root.spot === WidgetBackdropType.Enabled;
  const showSpotlight = widget.options.root.hasSpotlight;
  const spotlightColor = widget.options.root.spotlightColor;

  /**
   * `backdropStyle` is used to style "backdrop with a spot-area" element.
   * "spot-area" should have same dimensions as a visible anchor-element.
   * "spot-area" stays transparent while remaining area is overlapped with a backdrop-color.
   */
  const [backdropStyle, setBackropStyle] = useState<CSSProperties>(() => ({
    top: 0,
    left: 0,
    width: 0,
    height: 0,
  }));

  const rendererDocument = (rendererElement as HTMLIFrameElement)
    .contentDocument;

  const backdropElementSelector = getWidgetBackdropSelector(widget);
  const backdropElement = useRef<Element | null>(null);

  const handleCalculations = useCallback(() => {
    if (!rendererDocument || !backdropElementSelector) {
      /**
       * Unset spot-area dimensions when acnhor-element is beyond visible part of the screen.
       */
      setBackropStyle({
        width: 0,
        height: 0,
        top: 0,
        left: 0,
      });
      return;
    }

    if (backdropElement.current && rendererDocument?.defaultView) {
      const dims = getNestedBoundingClientRect(
        backdropElement.current,
        rendererDocument?.defaultView
      );
      const { visualViewport, innerHeight, innerWidth } =
        rendererDocument.defaultView;
      const viewportWidth = visualViewport ? visualViewport.width : innerWidth;
      const viewportHeight = visualViewport
        ? visualViewport.height
        : innerHeight;
      if (
        dims.bottom < 0 ||
        dims.right < 0 ||
        dims.top > viewportHeight ||
        dims.left > viewportWidth
      ) {
        /**
         * Unset spot-area dimensions when acnhor-element is beyond visible part of the screen.
         */
        setBackropStyle({
          width: 0,
          height: 0,
          top: 0,
          left: 0,
        });
      }

      if (isScaleEnabled) {
        setBackropStyle({
          width: `calc(${dims?.width}px * var(--renderer-scale-factor))`,
          height: `calc(${dims?.height}px * var(--renderer-scale-factor))`,
          top: `calc(${dims?.top}px * var(--renderer-scale-factor))`,
          left: `calc(${dims?.left}px * var(--renderer-scale-factor))`,
        });
      } else {
        setBackropStyle({
          width: dims?.width,
          height: dims?.height,
          top: dims?.top,
          left: dims?.left,
        });
      }
    } else {
      /**
       * Completely hide backdrop-element
       */
      setBackropStyle({
        display: 'none',
      });
    }
  }, [rendererDocument, backdropElementSelector, isScaleEnabled]);

  useEffect(() => {
    backdropElement.current = rendererDocument
      ? getElementByUniqueSelector(backdropElementSelector, rendererDocument)
      : null;
    handleCalculations();
  }, [handleCalculations, backdropElementSelector, rendererDocument]);

  useRendererHtmlObservers({
    rendererElement,
    onFire: handleCalculations,
  });

  return (
    <>
      {showBackdrop && <WidgetBackdropWithSpot style={backdropStyle} />}
      {showSpotlight && (
        <WidgetSpotlight style={backdropStyle} color={spotlightColor} />
      )}
    </>
  );
};

export default WidgetTooltipBackdropHtml;
