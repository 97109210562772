import { isDocument, isElementNode, isSVGUseElement } from './dom';

/**
 * Create a node iterator that iterates over all elements with shadow-dom.
 * It skips SVGElement because it also has shadowRoot property.
 */
export function createShadowHostIterator(
  root: Document | ShadowRoot
): NodeIterator {
  const doc = isDocument(root) ? root : root.ownerDocument;
  if (!doc) throw new Error('Document is not found');
  return doc.createNodeIterator(root, NodeFilter.SHOW_ELEMENT, {
    acceptNode: (node) => {
      if (
        isElementNode(node) &&
        /**
         * Skip SVGElement because it also has shadowRoot property.
         *
         * Note:
         * Do not use `instanceof` because of a mess with HTMLElement constructor
         *
         * node.ownerDocument?.defaultView?.HTMLElement &&
         * node instanceof node.ownerDocument.defaultView.HTMLElement
         */
        !isSVGUseElement(node) &&
        node.shadowRoot
      ) {
        return NodeFilter.FILTER_ACCEPT;
      }
      return NodeFilter.FILTER_REJECT;
    },
  });
}
