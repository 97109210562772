import { typewriterSpeedAttribute } from './typewriter.constants';

export function noParentElementTypewriter(e: HTMLElement) {
  // do not allow nested typewriters
  let parentElement = e.parentElement;
  let isParentElementTypewriter = false;
  while (parentElement) {
    const activeElementTypeSpeed = parentElement?.getAttribute(
      typewriterSpeedAttribute
    );
    if (activeElementTypeSpeed) {
      e.removeAttribute(typewriterSpeedAttribute);
      isParentElementTypewriter = true;
      break;
    }
    parentElement = parentElement.parentElement;
  }
  return isParentElementTypewriter === false;
}
