import { DraftEditFlowlist } from './flowlist.types';

export enum FlowlistItemTargetKind {
  Flow = 'flow',
  ExternalUrl = 'external_url',
}

export enum FlowlistPosition {
  Center = 'center',
  Corner = 'corner',
}

export enum FlowlistLaunchButtonPosition {
  Left = 'left',
  Right = 'right',
  Center = 'center',
}

export const flowlistTemplate: DraftEditFlowlist = {
  name: 'Product Tour',
  options: {
    root: {
      position: FlowlistPosition.Corner,
      autoStartEnabled: false,
      /**
       * Strikethrough name and description of flow list item on click.
       * Applies for current session only.
       * @see {@link https://trello.com/c/V5W0gsDx/1576-checklist-add-option-to-strike Requirements}
       */
      completeOnClick: false,
      showStepCount: true,
    },
    title: {
      value: 'Experience Product',
    },
    description: {
      value: 'Pick what you are interested about below',
    },
    launch: {
      value: 'PRODUCT TOUR',
      position: FlowlistLaunchButtonPosition.Left,
      offsetX: '0',
      offsetY: '0',
      color: '#ffffff',
      backgroundColor: '#9939eb',
    },
  },
  items: [],
};
