import { EventEmitter } from 'events';

type CreateEventFnResult<P> = () => {
  name: string;
  match: (inputName: string) => boolean;
  dispatch: (arg: P) => P;
};
export type PayloadFromEventFn<T> = T extends CreateEventFnResult<infer P>
  ? P
  : never;
export const createEventFn = <P extends unknown>(
  name: string
): CreateEventFnResult<P> => {
  const match = (inputName: string) => inputName === name;
  const dispatch = (payload: P) => payload;
  return () => ({
    name,
    match,
    dispatch,
  });
};

class DemoPlayerEventEmitter {
  private emitter: EventEmitter;
  constructor() {
    this.emitter = new EventEmitter();
  }

  on<PayloadI>(
    cef: CreateEventFnResult<PayloadI>,
    cb: (arg: PayloadI) => void
  ) {
    const fn = cef();
    this.emitter.on(fn.name, cb);
    return () => this.off(cef, cb);
  }

  off<PayloadI>(
    cef: CreateEventFnResult<PayloadI>,
    cb: (arg: PayloadI) => void
  ) {
    const fn = cef();
    this.emitter.off(fn.name, cb);
  }

  emit<P>(eventCreatorFn: CreateEventFnResult<P>, arg: P) {
    const fn = eventCreatorFn();
    this.emitter.emit(fn.name, fn.dispatch(arg));
  }
}

export default DemoPlayerEventEmitter;
export { DemoPlayerEventEmitter };
