import { parseHtmlString } from './parseHtmlString';

/**
 * Get the window object for the document that a node belongs to,
 * or return null if it cannot be found (node not attached to DOM, etc).
 */
export function getOwnerWindow(node: Node): Window | null {
  return node.ownerDocument?.defaultView ?? null;
}

export function isDocument(node: Node): node is Document {
  return node.nodeType === Node.DOCUMENT_NODE;
}

export function isDocumentFragment(node: Node): node is DocumentFragment {
  return node.nodeType === Node.DOCUMENT_FRAGMENT_NODE;
}

export function isIframeElement(
  element: Element
): element is HTMLIFrameElement {
  return element.tagName === 'IFRAME';
}

export function isFormElement(element: Element): element is HTMLFormElement {
  return element.tagName === 'FORM';
}

export function isAnchorElement(
  element: Element
): element is HTMLAnchorElement {
  return element.tagName === 'A';
}

/**
 * Get the iframe containing a node, or return null if it cannot be found (node not within iframe, etc).
 */
export function getOwnerIframe(node: Node): HTMLIFrameElement | null {
  const frame = getOwnerWindow(node)?.frameElement;
  if (frame && isIframeElement(frame)) {
    return frame;
  }

  return null;
}

export const isElementNode = (node: Node): node is Element => {
  return node?.nodeType === Node.ELEMENT_NODE;
};

export const isTextNode = (node: Node): node is Text => {
  return node?.nodeType === Node.TEXT_NODE;
};

/**
 * Returns `true` if element has TEXT_NODE nodes as first-level children
 * @param element
 * @returns
 */
export const hasTopLevelText = (element: Element | SVGElement): boolean => {
  for (const node of element.childNodes) {
    if (node.nodeType === 3) return true;
  }
  return false;
};

/**
 * is Image element
 */
export const isImageElement = (
  element: Element
): element is HTMLImageElement => {
  return element.tagName === 'IMG';
};

/**
 * is <svg /> element
 */
export const isSVGElement = (element: Element): element is SVGElement => {
  return element.tagName === 'svg';
};

/**
 * is SVG element has `<use>`
 */
export const isSVGHasUse = (element: SVGElement): boolean => {
  return Boolean(element.querySelector('use'));
};

/**
 * Any of the SVG namespace element
 */
export const isSVGChildElement = (element: Element): element is SVGElement => {
  return Boolean(
    'ownerSVGElement' in element && (element as SVGSVGElement).ownerSVGElement
  );
};

/**
 * is SVG `<use>` element
 */
export const isSVGUseElement = (element: Element): element is SVGUseElement => {
  return isSVGChildElement(element) && element.tagName === 'use';
};

/**
 * Calculate a boundingClientRect for a node relative to boundaryWindow,
 * taking into account any offsets caused by intermediate iframes.
 */
export function getNestedBoundingClientRect(
  node: Element,
  boundaryWindow: Window
): DOMRect {
  const ownerIframe = getOwnerIframe(node);

  if (ownerIframe && ownerIframe.contentWindow !== boundaryWindow) {
    const rects: DOMRect[] = [node.getBoundingClientRect()];

    let currentIframe: HTMLIFrameElement | null = ownerIframe;
    do {
      const rect = getBoundingClientRectWithBorderOffset(currentIframe);
      rects.push(rect);

      currentIframe = getOwnerIframe(currentIframe);
    } while (currentIframe && currentIframe.contentWindow !== boundaryWindow);

    return mergeRectOffsets(rects);
  } else {
    return node.getBoundingClientRect();
  }
}

/**
 * Add together the top, left, bottom, and right properties of each ClientRect,
 * but keep the width and height of the first one.
 */
export function mergeRectOffsets(rects: DOMRect[]): DOMRect {
  return rects.reduce<DOMRect>(
    (previousRect: DOMRect, rect: DOMRect, index) => {
      return {
        top: previousRect.top + rect.top,
        left: previousRect.left + rect.left,
        width: index === 0 ? rect.width : previousRect.width, // keep width from first rect
        height: index === 0 ? rect.height : previousRect.height, // keep height from first rect
        bottom: previousRect.bottom + rect.bottom,
        right: previousRect.right + rect.right,
      } as DOMRect;
    },
    {
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
      width: 0,
      height: 0,
    } as DOMRect
  );
}

// Get a bounding client rect for a node, with an
// offset added to compensate for its border.
export function getBoundingClientRectWithBorderOffset(
  element: Element
): DOMRect {
  const dimensions = getElementDimensions(element);
  return mergeRectOffsets([
    element.getBoundingClientRect(),
    {
      top: dimensions.borderTop,
      left: dimensions.borderLeft,
      bottom: dimensions.borderBottom,
      right: dimensions.borderRight,
      // This width and height won't get used by mergeRectOffsets (since this
      // is not the first rect in the array), but we set them so that this
      // object typechecks as a ClientRect.
      width: 0,
      height: 0,
    } as DOMRect,
  ]);
}

// eslint-disable-next-line @typescript-eslint/explicit-module-boundary-types
export function getElementDimensions(domElement: Element) {
  const domElementWindow = getOwnerWindow(domElement);

  const calculatedStyle =
    domElementWindow?.getComputedStyle(domElement) ||
    ({} as CSSStyleDeclaration);

  return {
    borderLeft: parseInt(calculatedStyle.borderLeftWidth, 10),
    borderRight: parseInt(calculatedStyle.borderRightWidth, 10),
    borderTop: parseInt(calculatedStyle.borderTopWidth, 10),
    borderBottom: parseInt(calculatedStyle.borderBottomWidth, 10),
    marginLeft: parseInt(calculatedStyle.marginLeft, 10),
    marginRight: parseInt(calculatedStyle.marginRight, 10),
    marginTop: parseInt(calculatedStyle.marginTop, 10),
    marginBottom: parseInt(calculatedStyle.marginBottom, 10),
    paddingLeft: parseInt(calculatedStyle.paddingLeft, 10),
    paddingRight: parseInt(calculatedStyle.paddingRight, 10),
    paddingTop: parseInt(calculatedStyle.paddingTop, 10),
    paddingBottom: parseInt(calculatedStyle.paddingBottom, 10),
  };
}

export const hasDims = (element: Element): boolean => {
  // TODO: improve it. Handle position absolute top -9999 left -9999 cases
  const dims = element.getBoundingClientRect();

  return !(
    dims?.x === 0 &&
    dims?.y === 0 &&
    dims?.width === 0 &&
    dims?.height === 0
  );
};

/**
 * Check most common computed styles which make element invisible
 * @param element
 * @returns
 */
export const isElementVisible = (element: Element): boolean => {
  const { visibility, opacity } = getComputedStyle(element);
  return (
    visibility !== 'hidden' && visibility !== 'collapse' && Number(opacity) > 0
  );
};

export const isSVGTextElement = (
  element: Element
): element is SVGTSpanElement | SVGTextElement | SVGTextPathElement => {
  return (
    element.tagName === 'text' ||
    element.tagName === 'tspan' ||
    element.tagName === 'textPath'
  );
};

export const isBodyElement = (element: Element): element is HTMLBodyElement => {
  return element.tagName === 'BODY';
};

export const isHtmlRootElement = (
  element: Element
): element is HTMLBodyElement => {
  return element.tagName === 'HTML';
};

export const hasBackgroundImage = (element: HTMLElement): boolean => {
  return (
    Boolean(getComputedStyle(element)['backgroundImage']) &&
    getComputedStyle(element)['backgroundImage'] !== 'none'
  );
};

export const parseHtmlStringAsync = async (
  ...args: Parameters<typeof parseHtmlString>
): Promise<ReturnType<typeof parseHtmlString>> => parseHtmlString(...args);

/**
 * Check if an iframe has required "sandbox" attributes to get access to.
 *
 * @param iframe any `HTMLIframeElement`
 * @returns `true` if iframe has "sandbox" attribute with required values.
 */
export const isIframeSandbox = (iframe: HTMLIFrameElement): boolean => {
  const sandboxItems = iframe.getAttribute('sandbox')?.split(' ') || [];

  return (
    sandboxItems.indexOf('allow-scripts') > -1 &&
    sandboxItems.indexOf('allow-same-origin') > -1
  );
};

export const injectHtmlIntoIframe = (props: {
  html: string;
  iframeElement: HTMLIFrameElement;
}): void => {
  const documentDOM = props.iframeElement?.contentWindow?.document;

  if (documentDOM) {
    documentDOM.open();
    documentDOM.write(props.html);
    documentDOM.close();
  }
};

export const serializeHTMLDocument = (originalDocument: Document): string => {
  const doctype = originalDocument.doctype;
  const doctypeStr = doctype
    ? `<!DOCTYPE ${doctype.name}${
        doctype.publicId ? ` PUBLIC "${doctype.publicId}"` : ''
      }${!doctype.publicId && doctype.systemId ? ' SYSTEM' : ''}${
        doctype.systemId ? ` "${doctype.systemId}"` : ''
      }>`
    : '';

  return `${doctypeStr}${
    (originalDocument.body.parentElement as HTMLElement).outerHTML
  }`;
};

export const removeNoscriptElements = (d: Document | ShadowRoot): void => {
  const noScriptEls = d.querySelectorAll('noscript');
  if (noScriptEls.length) {
    for (const el of noScriptEls) {
      el.remove();
    }
  }
};

export const disableInputAutocomplete = (d: Document | ShadowRoot): void => {
  const inputs = d.querySelectorAll('input');
  if (inputs.length) {
    for (const el of inputs) {
      // el.setAttribute('readonly', true);
      el.setAttribute('autocomplete', 'off');
    }
  }
};

export const SVGToDataURL = (svg: SVGElement): string => {
  const serializedSVG = new XMLSerializer().serializeToString(svg);
  const base64 = window.btoa(serializedSVG); // base64-encoded string from "binary string"
  return `data:image/svg+xml;base64,${base64}`;
};

type Dimensions = { width: number; height: number };

export function getVideoDimensions(videoUrl: string): Promise<Dimensions> {
  const videoElement = document.createElement('video');
  videoElement.src = videoUrl;
  return new Promise<Dimensions>((resolve) => {
    videoElement.addEventListener('loadedmetadata', function () {
      resolve({
        width: this.videoWidth,
        height: this.videoHeight,
      });
    });
  });
}

export function getImageDimensions(imgSrc: string): Promise<Dimensions> {
  const img = new Image();
  img.src = imgSrc;

  return new Promise<Dimensions>((resolve) => {
    img.onload = async () => {
      resolve({
        width: img.width,
        height: img.height,
      });
    };
  });
}
