import { sessionStorageWithFallback } from '../../utils/storageWithFallback';
import { AudioAutoplaySessionKey } from './audioSession.constants';

export function setSessionAudioAutoplay(shouldAutoplay: boolean): void {
  sessionStorageWithFallback.setItem(
    AudioAutoplaySessionKey,
    shouldAutoplay ? 'yes' : 'no'
  );
}

export function getSessionAudioAutoplay(): boolean {
  const autoplay = sessionStorageWithFallback.getItem(AudioAutoplaySessionKey);

  return autoplay !== 'no';
}
