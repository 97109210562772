import { WidgetKind } from 'shared-components/src/features/widgets/widgets.constants';
import { AnyWidget } from 'shared-components/src/features/widgets/widgets.types';

import Popup from './Popup';
import Media from './Media';
import Hotspot from './Hotspot';
import Tooltip from './Tooltip';
import LeadCapture from './LeadCapture';
import VendorForm from './VendorForm';
import { DemoPlayerWidget } from '../../types';

const components = {
  [WidgetKind.Popup]: Popup,
  [WidgetKind.Tooltip]: Tooltip,
  [WidgetKind.Hotspot]: Hotspot,
  [WidgetKind.Media]: Media,
  [WidgetKind.Modal]: null,
  [WidgetKind.Video]: null,
  [WidgetKind.Overlay]: null,
  [WidgetKind.RecordVideo]: null,
  [WidgetKind.LeadCapture]: LeadCapture,
  [WidgetKind.VendorForm]: VendorForm,
  [WidgetKind.VideoClip]: null,
} as const;

interface WidgetProps {
  isMounted: boolean;
  isDemoPlayerSmallScreen: boolean;
  widget: DemoPlayerWidget;
  onPrev: () => void;
  onCta: () => void;
  onSecondary: () => void;
  onClose: () => void;
  onExit: () => void;
  onReady: () => void;
  animationDelayMs: number;
  durationIn: number;
  durationOut: number;
  isAudioAutoplayEnabled: boolean;
  setIsAudioAutoplayEnabled: (isEnabled: boolean) => void;
  onAudioEnded: VoidFunction;
}
export interface WidgetComponentProps<WidgetType extends AnyWidget>
  extends Pick<
    WidgetProps,
    | 'isMounted'
    | 'onCta'
    | 'onPrev'
    | 'onSecondary'
    | 'onExit'
    | 'onReady'
    | 'animationDelayMs'
    | 'durationIn'
    | 'durationOut'
    | 'isDemoPlayerSmallScreen'
    | 'isAudioAutoplayEnabled'
    | 'setIsAudioAutoplayEnabled'
    | 'onAudioEnded'
  > {
  widget: DemoPlayerWidget<WidgetType>;
  onDismiss: WidgetProps['onClose'];
}

function Widget({
  widget,
  onClose,
  ...otherProps
}: WidgetProps): JSX.Element | null {
  const Component = components[widget.kind];

  if (!Component) {
    throw new Error(`Unknown widget kind: ${widget.kind}`);
  }

  return (
    <Component widget={widget as never} onDismiss={onClose} {...otherProps} />
  );
}

export default Widget;
