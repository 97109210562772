import { FC, useCallback } from 'react';

import { useDemoIsSmallScreen } from '../../hooks/useDemoIsSmallScreen';
import { useFlowlistPlayerState } from '../../hooks/useFlowlistPlayerState';
import LaunchButton from './LaunchButton';

const FlowlistLaunchManager: FC<{
  isResponsive: boolean;
}> = (props) => {
  const { isResponsive } = props;
  const { checklist, activeItem, maximizeChecklist } = useFlowlistPlayerState();
  const isSmallScreen = useDemoIsSmallScreen();

  const handleLaunchClick = useCallback(() => {
    maximizeChecklist();
  }, [maximizeChecklist]);

  if (!checklist) return null;

  return (
    <LaunchButton
      isResponsive={isResponsive}
      data={checklist.options.launch}
      activeItem={activeItem}
      onClick={handleLaunchClick}
      tooltipDisabled={isSmallScreen}
    />
  );
};

export default FlowlistLaunchManager;
