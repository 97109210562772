import { useMemo, useState, useRef, FC, useCallback } from 'react';
import { useInterpret } from '@xstate/react';
import { ProjectKind } from 'shared-components/src/features/project/project.constants';
import { WhitelabelLoader } from 'shared-components/src/features/whitelabel/whitelabel.constants';
import { localStorageWithFallback } from 'shared-components/src/utils/storageWithFallback';
import { processDisplayConfig } from 'shared-components/src/features/project/project.util';
import { setSessionAudioAutoplay } from 'shared-components/src/features/audioSession/audioSession.utils';
import { getFinalRendererDimensions } from 'shared-components/src/utils/getFinalRendererDimensions';

import {
  WidgetAnimationDelayMs,
  WidgetAnimationDurationInMs,
  WidgetAnimationDurationOutMs,
  WidgetAnimationTranslationDelayMs,
} from '../../constants';
import {
  DemoPlayerConfig,
  DemoPlayerFlowlist,
  DemoPlayerFlowsDict,
  DemoPlayerIframeScript,
  DemoPlayerImageSubstitutionsDict,
  DemoPlayerProject,
  DemoPlayerProjectPage,
  DemoPlayerTextSubstitutionsDict,
  DemoPlayerWidgetsDict,
  HostInfoRef,
} from '../../types';
import { getPageToFileUrlMap } from '../../utils/getPageToFileUrlMap';
import demoPlayerMachine, { getInitialContext } from '../../machine/machine';
import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import DemoPlayerEventEmitter from '../../events/emitter';
import { demoplayerEventCreators } from '../../events/eventCreators';

const defaultSubstitutions = {};

interface DemoPlayerStateProviderProps {
  project: DemoPlayerProject;
  projectPages: DemoPlayerProjectPage[];
  projectWidgets: DemoPlayerWidgetsDict;
  projectFlows: DemoPlayerFlowsDict;
  projectFlowlist: DemoPlayerFlowlist | null;
  config: DemoPlayerConfig;
  hostInfo?: HostInfoRef;
  textSubstitutions?: DemoPlayerTextSubstitutionsDict;
  imageSubstitutions?: DemoPlayerImageSubstitutionsDict;
  emitter: DemoPlayerEventEmitter;
}

const DemoPlayerStateProvider: FC<DemoPlayerStateProviderProps> = ({
  project,
  textSubstitutions = defaultSubstitutions,
  imageSubstitutions = defaultSubstitutions,
  projectPages,
  projectFlows,
  projectWidgets,
  projectFlowlist,
  config,
  hostInfo,
  emitter,
  children,
}) => {
  const [rendererElement, setRendererElement] = useState<HTMLElement | null>(
    null
  );

  const [widgetSlotElement, setWidgetSlotElement] =
    useState<HTMLDivElement | null>(null);

  const demoPlayerService = useInterpret(
    () =>
      demoPlayerMachine.withContext(
        getInitialContext({
          projectFlowIdSequence: project.flows,
          projectWidgets,
          projectPages,
          projectFlows,
          flowlist: projectFlowlist ?? undefined,
          projectPageLinks: project.page_links,
          config,
        })
      ),
    {
      actions: {
        onFinishWalkthrough: () => {
          emitter.emit(
            demoplayerEventCreators.walkthroughFinishedEvent,
            undefined
          );
        },
        captureLeadExternalEffect: (context, event) => {
          if (event.type === 'captureLead') {
            emitter.emit(demoplayerEventCreators.leadCaptureEvent, {
              inputLead: event.lead,
            });
          }
        },
      },
      devTools: localStorageWithFallback.getItem('xstateDebug') === 'on',
    }
  );
  const playerConfig = useMemo(() => {
    return processDisplayConfig(project.display_config, {
      auto_play: config.autoPlay,
    });
  }, [project.display_config, config.autoPlay]);
  const pageToFileUrl = useMemo(
    () => getPageToFileUrlMap(projectPages),
    [projectPages]
  );
  const startPageDimensions = projectPages[0].dimensions;
  const projectIframeScripts = useMemo(() => {
    return project.kind === ProjectKind.Html
      ? project.scripts.filter(
          (script): script is DemoPlayerIframeScript =>
            script.position === 'iframe_body' ||
            script.position === 'iframe_head'
        )
      : [];
  }, [project.scripts, project.kind]);
  const rendererHtmlLoader =
    project.whitelabel?.loader || WhitelabelLoader.Default;
  const isRendererHtmlScaleEnabled = useRef(
    config.isRendererHtmlScaleEnabled || false
  );
  const isKeepScrollBetweenWidgets = useRef(
    config.isKeepScrollBetweenWidgets || false
  );
  const isHtmlPreRenderingEnabled = useRef(
    config.isHtmlPreRenderingEnabled || false
  );
  const isVendorLeadFormScaleEnabled = useRef(
    config.isVendorLeadFormScaleEnabled || false
  );
  const isShadowDomEnabled = useRef(config.isShadowDomEnabled || false);
  const [audioAutoplayState, setAudioAutoplayState] = useState(
    config.isAudioAutoplayEnabled || false
  );
  const setIsAudioAutoplayEnabled = useCallback((isEnabled) => {
    setSessionAudioAutoplay(isEnabled);
    setAudioAutoplayState(isEnabled);
  }, []);
  const rendererWrapperRef = useRef<HTMLDivElement | null>(null);
  /**
   * Renderer dimensions are used for scale feature.
   *
   * @see https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/49840129/Html-Renderer+Scale#Logic
   */
  const rendererDimensions = getFinalRendererDimensions(
    playerConfig,
    startPageDimensions
  );

  const isWidgetTranslationAnimationEnabled =
    !config.isWidgetAnimationDisabled &&
    Boolean(config.isWidgetTranslationAnimationEnabled);

  const widgetAnimationDelayMs = config.isWidgetAnimationDisabled
    ? 0.001
    : config.isWidgetTranslationAnimationEnabled
    ? WidgetAnimationTranslationDelayMs
    : WidgetAnimationDelayMs;

  const widgetAnimationDurationInMs = config.isWidgetAnimationDisabled
    ? 0.001
    : WidgetAnimationDurationInMs;

  const widgetAnimationDurationOutMs = config.isWidgetAnimationDisabled
    ? 0.001
    : WidgetAnimationDurationOutMs;

  return (
    <DemoPlayerContext.Provider
      value={{
        projectId: project.id,
        projectKind: project.kind,
        projectImageTokens: project.image_tokens,
        projectIframeScripts,
        textSubstitutions,
        imageSubstitutions,
        demoPlayerService,
        rendererElement,
        setRendererElement,
        widgetSlotElement,
        setWidgetSlotElement,
        playerConfig,
        pageToFileUrl,
        rendererHtmlLoader,
        replayButtonConfig: config.replayButton ?? null,
        resumeButtonConfig: config.resumeButton ?? null,
        isWidgetTranslationAnimationEnabled,
        widgetAnimationDelayMs,
        widgetAnimationDurationInMs,
        widgetAnimationDurationOutMs,
        shouldAddWatermark: config.shouldAddWatermark,
        watermarkTextHref: config.watermarkTextHref,
        watermarkLogoHref: config.watermarkLogoHref,
        shouldDisplayBrandWatermark: config.shouldDisplayBrandWatermark,
        rendererWrapper: rendererWrapperRef,
        isRendererHtmlScaleEnabled: isRendererHtmlScaleEnabled.current,
        isKeepScrollBetweenWidgets: isKeepScrollBetweenWidgets.current,
        isHtmlPreRenderingEnabled: isHtmlPreRenderingEnabled.current,
        isVendorLeadFormScaleEnabled: isVendorLeadFormScaleEnabled.current,
        rendererDimensions,
        isReplayEnabled: config.isReplayEnabled,
        isShadowDomEnabled: isShadowDomEnabled.current,
        isResponsiveEnabled: Boolean(config.isResponsiveEnabled),
        isRecording: Boolean(config.isRecording),
        projectPages,
        hostInfo: hostInfo ?? null,
        isAudioAutoplayEnabled: audioAutoplayState,
        setIsAudioAutoplayEnabled,
        emitter,
      }}
    >
      {children}
    </DemoPlayerContext.Provider>
  );
};

export default DemoPlayerStateProvider;
