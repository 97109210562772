import { useCallback, useContext } from 'react';
import { useSelector } from '@xstate/react';

import { DemoPlayerContext } from '../contexts/DemoPlayerContext';
import { demoPlayerModel } from '../machine/machine.model';

export function usePlayerState() {
  const {
    demoPlayerService,
    projectKind,
    playerConfig,
    shouldAddWatermark,
    projectId,
    isRecording,
  } = useContext(DemoPlayerContext);
  const { send } = demoPlayerService;

  const currentPageId = useSelector(
    demoPlayerService,
    (state) => state.context.currentPageId
  );
  const currentFlowId = useSelector(
    demoPlayerService,
    (state) => state.context.currentFlowId
  );
  const currentWidgetId = useSelector(
    demoPlayerService,
    (state) => state.context.currentWidgetId
  );
  const isLastWidgetInFlow = useSelector(demoPlayerService, (state) =>
    state.context.currentWidgetId
      ? !state.context.widgetsLinkedMap[state.context.currentWidgetId]?.next
      : false
  );
  const startPageId = useSelector(
    demoPlayerService,
    (state) => state.context.config.startPageId
  );
  const isInteractiveState = useSelector(demoPlayerService, (state) =>
    state.matches('tour.interactive')
  );
  const isFlowPlaybackState = useSelector(demoPlayerService, (state) =>
    state.matches('tour.walkthrough.playFlow')
  );
  const isPlayerReadyState = useSelector(demoPlayerService, (state) =>
    state.matches('tour.ready')
  );
  const isChecklistEnabled = useSelector(
    demoPlayerService,
    (state) =>
      state.matches('checklist.maximized') ||
      state.matches('checklist.minimized')
  );

  const startPlayer = useCallback(() => {
    send(demoPlayerModel.events.startPlayer());
  }, [send]);

  const isMobileViewportEnabled = playerConfig.mobile_viewport;
  const autoplayTimeoutSeconds = playerConfig.auto_play;
  const isNavigationEnabled = playerConfig.nav_bar;

  return {
    projectKind,
    currentPageId,
    currentFlowId,
    currentWidgetId,
    isLastWidgetInFlow,
    startPageId,
    isInteractiveState,
    isPlayerReadyState,
    isChecklistEnabled,
    startPlayer,
    isRecording,
    isFlowPlaybackState,
    isMobileViewportEnabled,
    autoplayTimeoutSeconds,
    shouldAddWatermark,
    isNavigationEnabled,
    projectId,
    playerConfig,
  };
}
