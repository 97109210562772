import { CSSProperties, forwardRef } from 'react';
import cx from 'classnames';

import styles from './WidgetSpotlight.module.css';
import { DEFAULT_SPOTLIGHT_COLOR } from '../../../features/widgets/widgets.constants';

interface BackdropSpotProps {
  style?: CSSProperties;
  color?: string;
  onClick?: () => void;
}
const WidgetSpotlight = forwardRef<HTMLDivElement, BackdropSpotProps>(
  ({ style = {}, onClick, color }, ref) => {
    return (
      <div
        ref={ref}
        style={
          {
            ...style,
            '--spotlight-color': color || DEFAULT_SPOTLIGHT_COLOR,
          } as CSSProperties
        }
        className={cx(styles.root)}
        onClick={onClick}
      />
    );
  }
);

WidgetSpotlight.displayName = 'WidgetBackdropWithSpot';

export default WidgetSpotlight;
