import { createContext, Dispatch, SetStateAction } from 'react';
import { ProjectPageId } from 'shared-components/src/features/projectPages/projectPages.types';
import {
  WidgetId,
  WidgetPlacement,
} from 'shared-components/src/features/widgets/widgets.types';

import { Dictionary } from '../global';

export type WidgetPageScrollPositionState = {
  widgetId: WidgetId;
  pageId: ProjectPageId;
  selector: string;
  viewportOffset: {
    x: number;
    y: number;
  };
} | null;

export const DemoPlayerWidgetContext = createContext<{
  displayWidget: boolean;
  setDisplayWidget: Dispatch<SetStateAction<boolean>>;
  arrowWidgetAutoPlacement: Dictionary<WidgetPlacement>;
  setArrowWidgetAutoPlacement: Dispatch<
    SetStateAction<Dictionary<WidgetPlacement>>
  >;
  prevWidgetPageScrollPosition: WidgetPageScrollPositionState;
  setPrevWidgetPageScrollPosition: Dispatch<
    SetStateAction<WidgetPageScrollPositionState>
  >;
  isWidgetScrollAnimationDisabled: boolean;
}>({
  displayWidget: false,
  setDisplayWidget: () => undefined,
  arrowWidgetAutoPlacement: {},
  setArrowWidgetAutoPlacement: () => undefined,
  prevWidgetPageScrollPosition: null,
  setPrevWidgetPageScrollPosition: () => undefined,
  isWidgetScrollAnimationDisabled: false,
});
