import animateScrollTo, { IOptions } from 'animated-scroll-to';
import { useEffect } from 'react';

import { getScrollableAncestors } from '../utils/scroller';

const scrollVerticalOffset = -50;
const scrollSpeed = 50;

interface UseAnimateScrollIntoViewProps {
  element: HTMLElement | null;
  speed?: IOptions['speed'];
  delay?: number;
}

export default function useAnimateScrollIntoView({
  element,
  speed = scrollSpeed,
  delay = 0,
}: UseAnimateScrollIntoViewProps): void {
  useEffect(() => {
    /**
     * Scroll element into view relative to the closest scrollable element
     */
    if (element) {
      const timer = setTimeout(() => {
        const firstScrollableAncestor = getScrollableAncestors(
          element,
          window.document.body
        )[0];
        if (firstScrollableAncestor) {
          const scrollableAncestorRect =
            firstScrollableAncestor.getBoundingClientRect();
          const posElRect = element.getBoundingClientRect();
          if (
            // We check only vertical offset as we do not support horizontal overflow in image editor
            posElRect.top < scrollableAncestorRect.top ||
            posElRect.bottom > scrollableAncestorRect.bottom
          ) {
            const scrollY =
              posElRect.top +
              posElRect.height / 2 -
              (scrollableAncestorRect.top + scrollableAncestorRect.height / 2);
            animateScrollTo(scrollY, {
              elementToScroll: firstScrollableAncestor,
              speed,
              verticalOffset: scrollVerticalOffset,
            });
          }
        }
      }, delay);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [element, delay, speed]);
}
