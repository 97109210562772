import { useCallback, useContext, useMemo, VFC, Suspense, lazy } from 'react';
import { isLeadCaptureLikeWidget } from 'shared-components/src/features/widgets/widgets.utils';

import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';
import { useWidgetPlayerState } from '../../hooks/useWidgetPlayerState';
import { DemoPlayerWidget } from '../../types';

const NavigationBarClient = lazy(
  /* webpackChunkName: "NavigationBar" */ () => import('../NavigationBar')
);

const NavigationBarManager: VFC = () => {
  const {
    currentFlowId,
    flowsDict,
    goToWidget,
    isLeadCaptured,
    isInteractiveState,
    navigationColor,
    isPreview,
  } = useRendererPlayerState();
  const { widget } = useWidgetPlayerState();
  const { shouldDisplayBrandWatermark } = useContext(DemoPlayerContext);

  const handlePrevClick = useCallback(() => {
    if (!widget?._prevId) return;
    goToWidget(widget._prevId);
  }, [widget, goToWidget]);

  const handleNextClick = useCallback(() => {
    if (!widget?._nextId) return;
    goToWidget(widget._nextId);
  }, [widget, goToWidget]);

  const handleControlClick = useCallback(
    (widgetId: DemoPlayerWidget['id']) => {
      goToWidget(widgetId);
    },
    [goToWidget]
  );

  const isPrevDisabled = !widget?._prevId;
  const isNextDisabled =
    !widget?._nextId || (isLeadCaptureLikeWidget(widget) && !isLeadCaptured);

  const paginationNumberLabel = `${widget?._currentStepNumber}/${widget?._totalStepsCount}`;

  /**
   * IF user enters "interactive" mode,
   * THEN make navigation disabled and start exit animation
   */
  const currentFlow = currentFlowId ? flowsDict[currentFlowId] : null;
  const controlItems = useMemo(() => {
    return (
      currentFlow?.widgets.map((widgetId, index) => ({
        id: widgetId,
        highlight: Number(widget?._currentStepNumber) > index,
        current: widgetId === widget?.id,
      })) ?? []
    );
  }, [currentFlow, widget]);
  // some customers build empty flows and publish such projects => show nothing :)
  if (!currentFlow?.widgets.length || isInteractiveState) {
    return null;
  }

  return (
    <Suspense fallback={null}>
      <NavigationBarClient
        color={navigationColor}
        controlItems={controlItems}
        paginationNumberLabel={paginationNumberLabel}
        isWatermarkOffset={shouldDisplayBrandWatermark && !isPreview}
        mountInAnimationKey={currentFlow.id}
        onPaginationNextClick={handleNextClick}
        onPaginationPrevClick={handlePrevClick}
        onControlClick={handleControlClick}
        isPaginationNextDisabled={isNextDisabled}
        isPaginationPrevDisabled={isPrevDisabled}
      />
    </Suspense>
  );
};

export default NavigationBarManager;
