import { FC } from 'react';

import styles from './Body.module.css';

const Body: FC = ({ children }) => {
  return (
    <div
      id="beacon-body"
      aria-label="Widget Hotspot Layout Body"
      className={styles.root}
    >
      {children}
    </div>
  );
};

export default Body;
