import { useCallback, useEffect, useRef, useState } from 'react';
import { WidgetKind } from 'shared-components/src/features/widgets/widgets.constants';
import {
  isMedia,
  isVideoClip,
} from 'shared-components/src/features/widgets/widgets.utils';

import getVideoDuration from '../utils/getVideoDuration';
import { useRendererPlayerState } from './useRendererPlayerState';
import { useWidgetPlayerState } from './useWidgetPlayerState';

export function useDemoWidgetAutoplay(arg: {
  onTimerFired: () => void;
  configInterval: number;
  disabled?: boolean;
}) {
  const { onTimerFired, configInterval, disabled = false } = arg;
  const intervalIdRef = useRef<number>();
  const [tabHasFocus, setTabHasFocus] = useState(true);
  const [isTimerRunning, setIsTimerRunning] = useState(false);
  const { widget, currentPageId, isRecording } = useWidgetPlayerState();
  const { pageToFileUrl } = useRendererPlayerState();
  const [interval, setInterval] = useState<number>();

  const startTimer = useCallback(() => {
    if (!interval) return;

    if (intervalIdRef.current) {
      console.error('Previous timer should be stopped before starting new one');
      return;
    }

    intervalIdRef.current = window.setInterval(() => {
      onTimerFired();
    }, interval);
    setIsTimerRunning(true);
  }, [onTimerFired, interval]);

  const stopTimer = useCallback(() => {
    if (!intervalIdRef.current) {
      console.error('No timer to clear');
      return;
    }

    window.clearInterval(intervalIdRef.current);
    intervalIdRef.current = undefined;
    setIsTimerRunning(false);
  }, []);

  useEffect(() => {
    let cancel = false;

    if (configInterval <= 0 || !widget) return;

    const setIntervalDuration = async (durationPromise: Promise<number>) => {
      try {
        const duration = await durationPromise;
        if (!cancel && !intervalIdRef.current) {
          setInterval(Math.max(duration * 1000, configInterval));
        }
      } catch (error) {
        setInterval(configInterval);
      }
    };

    if (isVideoClip(widget) && pageToFileUrl[currentPageId].url) {
      const url = pageToFileUrl[currentPageId].url;
      import('../utils/getHlsVideoDuration').then((module) =>
        setIntervalDuration(module.default(url))
      );
    } else if (isMedia(widget) && widget.video_url) {
      setIntervalDuration(getVideoDuration(widget.video_url));
    } else {
      if (!widget.audio_url) {
        setInterval(configInterval);
      }
    }

    return () => {
      cancel = true;
      setInterval(undefined);
      if (intervalIdRef.current) {
        stopTimer();
      }
    };
  }, [configInterval, stopTimer, widget, currentPageId, pageToFileUrl]);

  const rerunTimer = useCallback(() => {
    stopTimer();
    startTimer();
  }, [stopTimer, startTimer]);

  // Stop timer if tab loses focus and rerun if focus is back
  useEffect(() => {
    const visibilitychangeListener = () => {
      setTabHasFocus(document.visibilityState === 'visible');
    };
    document.addEventListener('visibilitychange', visibilitychangeListener);
    return () => {
      document.removeEventListener(
        'visibilitychange',
        visibilitychangeListener
      );
    };
  }, [setTabHasFocus]);

  useEffect(() => {
    const shouldStopTimerOnCurrentStep =
      (!isRecording && widget?.options?.cta?.extUrl) ||
      widget?.kind === WidgetKind.LeadCapture ||
      widget?.kind === WidgetKind.VendorForm;

    if (!disabled) {
      if (
        !isTimerRunning &&
        widget &&
        tabHasFocus &&
        !shouldStopTimerOnCurrentStep
      ) {
        startTimer();
      } else if (
        isTimerRunning &&
        (!widget || !tabHasFocus || shouldStopTimerOnCurrentStep)
      ) {
        stopTimer();
      }
    }
  }, [
    widget?.id,
    startTimer,
    stopTimer,
    disabled,
    isTimerRunning,
    widget,
    tabHasFocus,
    isRecording,
  ]);

  return {
    rerunTimer,
    isTimerRunning,
  };
}
