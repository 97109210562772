import { useContext, useEffect, VFC } from 'react';
import VideoContext from 'shared-components/src/components/Video/video.context';
import VideoWithControls from 'shared-components/src/components/Video/VideoWithControls';

import styles from './DemoVideo.module.css';

type DemoVideoForPlayerProps = {
  src: string;
  startTime?: number;
  endTime?: number;
  onEnded?: () => void;
  isPreloadingHidden: boolean;
  thumbnailUrl?: string;
};

const DemoVideoForPlayer: VFC<DemoVideoForPlayerProps> = ({
  src,
  startTime = 0,
  endTime,
  onEnded,
  isPreloadingHidden,
  thumbnailUrl,
}) => {
  const { setIsPlaying, setCurrentTime } = useContext(VideoContext);

  // Each time we hide the video we need to reset current time. This way there will be no flashing images
  useEffect(() => {
    if (isPreloadingHidden) {
      setIsPlaying(false);

      // We need to add a little timeout before rewinding video to startTime because user can see it for split second
      // This also avoids conflict with setCurrentTime on video pause in Video.tsx
      setTimeout(() => {
        setCurrentTime(startTime);
      }, 100);
    }
  }, [isPreloadingHidden, setIsPlaying, setCurrentTime, startTime]);

  // Handle autoplay
  useEffect(() => {
    setIsPlaying(!isPreloadingHidden);
  }, [isPreloadingHidden, setIsPlaying]);

  return (
    <VideoWithControls
      className={styles.video}
      src={src}
      startTime={startTime}
      endTime={endTime}
      onEnded={onEnded}
      showMute={true}
      thumbnailUrl={thumbnailUrl}
    />
  );
};

export default DemoVideoForPlayer;
