import {
  forwardRef,
  PropsWithChildren,
  useEffect,
  useImperativeHandle,
  useRef,
  useState,
} from 'react';

import { useMeasure } from '../../../hooks/react-use/useMeasure';
import { useScroll } from '../../../hooks/react-use/useScroll';
import styles from './WidgetScrollableContainer.module.css';

const WidgetScrollableContainer = forwardRef<
  HTMLDivElement,
  PropsWithChildren<unknown>
>((props, ref) => {
  const { children } = props;
  const scrollableDiv = useRef<HTMLDivElement | null>(null);
  const scrollPosition = useScroll(scrollableDiv);

  const [displayShadow, setDisplayShadow] = useState({
    top: false,
    bottom: false,
  });

  useImperativeHandle(ref, () => scrollableDiv.current as HTMLDivElement);

  /**
   * `useMeasure` is using Resize Observer API https://github.com/streamich/react-use/blob/master/docs/useMeasure.md
   * We need it to detect Layout Shift of an image.
   * TODO: remove it once https://storylane.atlassian.net/browse/STORY-766 is implemented.
   */
  const [measureRef, { height: measureHeight }] = useMeasure<HTMLDivElement>();
  useEffect(() => {
    const isShowTopShadow = scrollPosition.y > 0;
    const isShowBottomShadow = scrollableDiv.current
      ? scrollPosition.y + scrollableDiv.current.clientHeight <
        scrollableDiv.current.scrollHeight
      : false;
    setDisplayShadow({
      top: isShowTopShadow,
      bottom: isShowBottomShadow,
    });
  }, [scrollPosition.y, measureHeight]);

  return (
    <div className={styles.scrollWrapper}>
      <div
        className={styles.scrollShadowTop}
        data-hidden={!displayShadow.top}
      />
      <div
        className={styles.scrollShadowBottom}
        data-hidden={!displayShadow.bottom}
      />
      <div
        className={styles.gridScrollableArea}
        ref={(node) => {
          scrollableDiv.current = node;
          measureRef(node as HTMLDivElement);
        }}
      >
        {children}
      </div>
    </div>
  );
});

WidgetScrollableContainer.displayName = 'WidgetScrollableContainer';

export default WidgetScrollableContainer;
