export const parseHtmlString = (html: string): Document => {
  /**
   * DOMParser
   *
   * Note: Be aware that block-level elements like <p> will be automatically closed
   * if another block-level element is nested inside and therefore parsed before the closing </p> tag.
   * See: https://developer.mozilla.org/en-US/docs/Web/API/DOMParser
   */
  const parser = new DOMParser();
  return parser.parseFromString(html, 'text/html');
};

export default parseHtmlString;
