import { SubstitutionKind } from './substitution.constants';

export type SubstitutionId = string;

export interface Substitution {
  id: SubstitutionId;
  default_key: boolean;
  key: string;
  value: string;
  kind: SubstitutionKind;
  sequence: number; // starts from "1"
  options: Record<string, unknown>;
}

export interface SubstitutionImage extends Substitution {
  kind: SubstitutionKind.Image;
  options: {
    fileType:
      | 'image/jpeg'
      | 'image/jpg'
      | 'image/png'
      | 'image/webp'
      | 'image/svg+xml';
  };
}

export interface SubstitutionText extends Substitution {
  kind: SubstitutionKind.Text;
  options: Record<string, never>;
}

export type DraftNewSubstitution = Pick<
  SubstitutionImage | SubstitutionText,
  'key' | 'value' | 'kind' | 'options'
>;

export type DraftEditSubstitution = Pick<Substitution, 'key' | 'value'>;

export interface SubstitutionValue {
  id: SubstitutionId;
  key: string;
  value: string | null;
  kind: SubstitutionKind;
  options: Record<string, unknown>;
}

export type SubstitutionsTextDict = Record<
  Substitution['key'],
  Substitution['value']
>;

export interface PublishedSubstitutionImage {
  id: SubstitutionId;
  key: string;
  value: string;
  kind: SubstitutionKind.Image;
  options: SubstitutionImage['options'];
}

export const enum SubstitutionDateOperationUnit {
  Hour = 'h',
  Minute = 'm',
  Second = 's',
  Day = 'd',
  Year = 'y',
  Month = 'M',
}

export const enum SubstitutionDateOperationType {
  Add = '+',
  Sub = '-',
}
