import {
  MutableRefObject,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
  VFC,
} from 'react';
import Icon from 'shared-components/src/components/Icon';
import { PageLinkWithId } from 'shared-components/src/features/pageLink/pageLink.types';
import { useRendererHtmlListener } from 'shared-components/src/features/renderer/renderer.hooks';
import { IframeEventsManagerListeners } from 'shared-components/src/utils/IframeEventsManager';

import { DemoPlayerContext } from '../../contexts/DemoPlayerContext';
import styles from './InteractiveToast.module.css';

interface InteractiveToastProps {
  clickLinkedElementsRef: MutableRefObject<Map<Element, PageLinkWithId>>;
  isRendererReady: boolean;
}

const InteractiveToast: VFC<InteractiveToastProps> = (props) => {
  const { clickLinkedElementsRef, isRendererReady } = props;
  const { playerConfig, rendererElement } = useContext(DemoPlayerContext);
  const enabledMissclickHints = playerConfig.page_link_hints;
  const textValue = playerConfig.page_link_toast_text;
  const [isVisible, setIsVisible] = useState(false);

  const pageLinkToastTimeoutRef = useRef<number>();
  useEffect(() => {
    return () => {
      clearTimeout(pageLinkToastTimeoutRef.current);
    };
  }, []);

  const showPageLinkToast = useCallback(
    (nextIsVisible: boolean) => {
      if (!enabledMissclickHints || !textValue) {
        return;
      }

      clearTimeout(pageLinkToastTimeoutRef.current);
      setIsVisible(nextIsVisible);
      if (nextIsVisible) {
        pageLinkToastTimeoutRef.current = window.setTimeout(() => {
          setIsVisible(false);
          clearTimeout(pageLinkToastTimeoutRef.current);
        }, 3000);
      }
    },
    [textValue, enabledMissclickHints]
  );

  const listeners = useMemo<IframeEventsManagerListeners>(() => {
    if (!textValue) return [];

    return [
      [
        'click',
        (event: MouseEvent) => {
          try {
            const clickedTarget = event.target as unknown as Element;

            /**
             * Find suitable page link
             */
            for (const [lEl] of clickLinkedElementsRef.current.entries()) {
              if (lEl === clickedTarget || lEl.contains(clickedTarget)) {
                showPageLinkToast(false);
                return; // exit when we click "page-link element"
              }
            }

            showPageLinkToast(true);
          } catch (error) {
            console.error(error);
          }
        },
        {
          attachToShadowDom: false,
        },
      ],
    ];
  }, [showPageLinkToast, clickLinkedElementsRef, textValue]);

  useRendererHtmlListener({
    rendererElement: isRendererReady
      ? (rendererElement as HTMLIFrameElement)
      : null,
    listeners,
    isEnabled: isRendererReady || !textValue,
  });

  // TODO: introduce animation if requested
  if (!isVisible) {
    return null;
  }

  return (
    <div className={styles.root}>
      <div className={styles.toast}>
        <span className={styles.icon}>
          <Icon name="lightbulb-soft" />
        </span>
        <span
          dangerouslySetInnerHTML={{
            __html: playerConfig.page_link_toast_text,
          }}
        />
      </div>
    </div>
  );
};

export default InteractiveToast;
