import { FC } from 'react';
import cx from 'classnames';

import styles from './WidgetDialogPositionManager.module.css';

const WidgetDialogPositionManager: FC = ({ children }) => {
  return (
    <div className={cx(styles.root, styles.rootModal)}>
      <div>{children}</div>
    </div>
  );
};

export default WidgetDialogPositionManager;
