import { WhiteLabelSettings } from './whitelabel.types';

export enum WhitelabelLoader {
  Default = 'default',
  Spinner = 'spinner',
  None = 'none',
}

export const WhitelabelSettingsDefault: WhiteLabelSettings = {
  loader: WhitelabelLoader.Default,
  logo: null,
  logo_href: null,
  site_name: 'Loading...',
  favicon: 'icons/favicon.ico',
  product_logo_enabled: false,
  replay_demo_bg_color: '',
  replay_demo_text: '',
  replay_demo_color: '',
  resume_demo_bg_color: '',
  resume_demo_text: '',
  resume_demo_color: '',
  brand_name_enabled: false,
  header_link_text: '',
  header_link_url: '',
};
