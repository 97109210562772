import { useCallback, useContext } from 'react';
import { FlowId } from 'shared-components/src/features/flow/flow.types';
import { ProjectKind } from 'shared-components/src/features/project/project.constants';
import { PageId } from 'shared-components/src/features/projectPages/projectPages.types';
import { useSelector } from '@xstate/react';

import { DemoPlayerButtonConfig, DemoPlayerPageLinksDict } from '../types';
import { demoPlayerModel } from '../machine/machine.model';
import { DemoPlayerContext } from '../contexts/DemoPlayerContext';

interface UseInteractivePlayerStateReturn {
  projectKind: ProjectKind;
  currentPageId: string;
  currentFlowId: FlowId | null;
  goToPage: (pageId: PageId) => void;
  onReplayClick: () => void;
  isReplayEnabled: boolean;
  onResumeClick: () => void;
  isResumeEnabled: boolean;
  progress: number;
  rendererElement: HTMLElement | null;
  enabledMissclickHints: boolean;
  enabledHoverHints: boolean;
  pageLinksDict: DemoPlayerPageLinksDict;
  resumeButtonConfig: DemoPlayerButtonConfig | null;
  widgetSlotElement: HTMLDivElement | null;
  replayButtonConfig: DemoPlayerButtonConfig | null;
}

export function useInteractivePlayerState(): UseInteractivePlayerStateReturn {
  const {
    demoPlayerService,
    rendererElement,
    playerConfig,
    projectKind,
    resumeButtonConfig,
    widgetSlotElement,
    replayButtonConfig,
    isReplayEnabled: configIsReplayEnabled,
  } = useContext(DemoPlayerContext);
  const { send } = demoPlayerService;

  const currentPageId = useSelector(
    demoPlayerService,
    (state) => state.context.currentPageId
  );
  const currentFlowId = useSelector(
    demoPlayerService,
    (state) => state.context.currentFlowId
  );
  /**
   * "Resume" button is to continue walkthrough.
   * exit "interactive" tour => enter "walkthrough" tour to continue from last visited widget.
   * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Resume-Button Docs}
   */
  const isResumeEnabled = useSelector(
    demoPlayerService,
    (state) =>
      state.matches('tour.interactive.walkthroughDismissed') &&
      state.matches('checklist.hidden')
  );
  /**
   * "Replay" button is to restart walkthrough.
   * exit "interactive" tour => restart "walkthrough" tour from the begining.
   * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Replay-Button Docs}
   */
  const isReplayEnabled = useSelector(
    demoPlayerService,
    (state) =>
      state.matches('tour.interactive.walkthroughFinished') &&
      configIsReplayEnabled
  );
  const flowProgress = useSelector(demoPlayerService, (state) => {
    const { currentFlowId, currentWidgetId, flowsDict } = state.context;
    if (currentFlowId && currentWidgetId) {
      const flow = flowsDict[currentFlowId];
      if (flow) {
        const widgetPos = flow.widgets.indexOf(currentWidgetId) + 1;
        return (widgetPos * 100) / flow.widgets.length;
      }
    }

    return 0;
  });

  const goToPage = useCallback(
    (pageId: PageId) => {
      send(demoPlayerModel.events.goToPage(pageId));
    },
    [send]
  );
  const onResumeClick = useCallback(() => {
    send(demoPlayerModel.events.continueWalkthrough());
  }, [send]);
  const onReplayClick = useCallback(() => {
    send(demoPlayerModel.events.restartPlayer());
  }, [send]);
  const pageLinksDict = useSelector(
    demoPlayerService,
    (state) => state.context.pageLinks
  );

  return {
    projectKind,
    currentPageId,
    currentFlowId,
    goToPage,
    onReplayClick,
    isReplayEnabled,
    onResumeClick,
    isResumeEnabled,
    progress: flowProgress,
    rendererElement,
    pageLinksDict,
    enabledMissclickHints: playerConfig.page_link_hints,
    enabledHoverHints: playerConfig.page_link_hover_hints,
    resumeButtonConfig,
    widgetSlotElement,
    replayButtonConfig,
  };
}
