import { ChangeEvent, useCallback, useRef, useState, VFC } from 'react';
import cx from 'classnames';

import Icon from '../../Icon';
import styles from './AudioPlayer.module.css';
import { calculateTime } from './AudioPlayer.utils';

type AudioPlayerProps = {
  src: string;
  autoplay?: boolean;
  theme?: 'light' | 'dark';
  id?: string;
  onPlayPauseButtonClick?: (isPlaying: boolean) => void;
  onAudioEnded?: VoidFunction;
};

const AudioPlayer: VFC<AudioPlayerProps> = ({
  src,
  autoplay = false,
  theme = 'light',
  id,
  onPlayPauseButtonClick,
  onAudioEnded,
}) => {
  const [timelineValue, setTimelineValue] = useState('0');
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState('0:00');
  const [audioCurrentTime, setAudioCurrentTime] = useState('0:00');
  const audioRef = useRef<HTMLAudioElement>(null);

  const [isRangeDragging, setIsRangeDragging] = useState(false);

  const handleRangeMouseUp = useCallback(() => {
    setIsRangeDragging(false);
    if (audioRef.current) {
      audioRef.current.currentTime =
        (parseInt(timelineValue) * audioRef.current.duration) / 100;
    }
  }, [timelineValue]);

  const handleRangeChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      setTimelineValue(e.target.value);
      if (!isRangeDragging) {
        setIsRangeDragging(true);
      }
    },
    [isRangeDragging]
  );

  const handleAudioTimeUpdate = useCallback(() => {
    if (audioRef.current?.duration && !isRangeDragging) {
      const percentagePosition =
        (100 * audioRef.current.currentTime) / audioRef.current.duration;
      setTimelineValue(String(percentagePosition));
      setAudioCurrentTime(calculateTime(audioRef.current.currentTime));
    }
  }, [isRangeDragging]);

  const handlePlayButtonCLick = useCallback(() => {
    if (audioRef.current) {
      if (audioRef.current.paused) {
        audioRef.current.play();
        onPlayPauseButtonClick && onPlayPauseButtonClick(true);
      } else {
        audioRef.current.pause();
        onPlayPauseButtonClick && onPlayPauseButtonClick(false);
      }
    }
  }, [onPlayPauseButtonClick]);

  const handleMetadataLoad = useCallback((e) => {
    if (Number.isFinite(e.target.duration)) {
      setAudioDuration(calculateTime(e.target.duration));
    }
  }, []);

  return (
    <div
      className={cx(styles.root, {
        [styles.rootLight]: theme === 'light',
        [styles.rootDark]: theme === 'dark',
      })}
      id={id}
      aria-label={`Audio Player Widget with interactive controls having src ${src}`}
    >
      <audio
        ref={audioRef}
        src={src}
        autoPlay={autoplay}
        onTimeUpdate={handleAudioTimeUpdate}
        onPlay={() => setIsPlaying(true)}
        onPause={() => setIsPlaying(false)}
        onLoadedMetadata={handleMetadataLoad}
        onEnded={onAudioEnded}
      />
      <div className={styles.controls}>
        <button className={styles.playerButton} onClick={handlePlayButtonCLick}>
          <Icon
            name={isPlaying ? 'pause' : 'play'}
            width="0.5rem"
            height="0.625rem"
          />
        </button>
        <div className={styles.time}>{audioCurrentTime}</div>
        <input
          type="range"
          className={styles.timeline}
          max="100"
          value={timelineValue}
          onChange={handleRangeChange}
          onMouseUp={handleRangeMouseUp}
          style={{
            backgroundSize: `${timelineValue}% 100%`,
          }}
        />
        <div className={styles.time}>{audioDuration}</div>
      </div>
    </div>
  );
};

export default AudioPlayer;
