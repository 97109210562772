import { useCallback } from 'react';
import {
  DefaultTypeSpeed,
  FallbackSpeedFactor,
  typewriterSpeedAttribute,
} from 'shared-components/src/features/typewriter/typewriter.constants';
import observeIntersection from 'shared-components/src/utils/IntersectionObserver';

import type Typed from 'typed.js';
import { noParentElementTypewriter } from './typewriter.utils';

type TState = {
  fullHtml: string;
  typedInstance: Typed;
  onDestroy: () => unknown;
};

type ElementWithData = {
  e: HTMLElement;
  speedFactor: number;
};

export function useTypewriter() {
  const startTypewriter = useCallback((rendererDocument?: Document | null) => {
    if (!rendererDocument) {
      return;
    }

    const observeElements: Array<ElementWithData> = [
      ...rendererDocument.querySelectorAll<HTMLElement>(
        `[${typewriterSpeedAttribute}]`
      ),
    ]
      .filter(noParentElementTypewriter)
      .map((e) => {
        const parsedSpeedFactor = Number.parseFloat(
          e.getAttribute(typewriterSpeedAttribute) ?? '1'
        );
        const speedFactor = Number.isFinite(parsedSpeedFactor)
          ? parsedSpeedFactor
          : FallbackSpeedFactor;
        return {
          e,
          speedFactor,
        };
      });
    const elementsMap = new Map();
    import('typed.js').then((module) => {
      const Typed = module.default;

      for (const { e, speedFactor } of observeElements) {
        const fullHtml = e.dataset.fullHtml ?? '';
        const typedInstance = new Typed(e, {
          strings: [fullHtml],
          typeSpeed: DefaultTypeSpeed / speedFactor, // slower is faster
          showCursor: false,
          loop: false,
        });
        typedInstance.stop();

        const unsubscribe = observeIntersection({
          element: e,
          threshold: [0.5],
          cb(data) {
            const { intersectionRatio } = data[0];
            const state = elementsMap.get(e);
            if (state) {
              if (intersectionRatio > 0.5) {
                state.typedInstance.start();
              } else {
                state.typedInstance.stop();
              }
            } else {
              unsubscribe();
            }
          },
        });

        const state: TState = {
          fullHtml,
          typedInstance,
          onDestroy: () => {
            unsubscribe();
            typedInstance.destroy();
          },
        };

        elementsMap.set(e, state);
      }
    });
    const destroyTypewriter = () => {
      for (const { onDestroy } of elementsMap.values()) {
        onDestroy();
      }
    };
    return destroyTypewriter;
  }, []);

  return { startTypewriter };
}
