import { ProjectPageId } from '../projectPages/projectPages.types';
import {
  ProjectImageToken,
  StrictPageProjectImageToken,
  UniversalProjectImageToken,
} from './projectImageToken.types';

export const isImageTokenAvailableInPage = (
  imageToken: ProjectImageToken,
  pageId: ProjectPageId
): boolean => {
  return (
    !isStrictPageImageToken(imageToken) || imageToken.options.pageId === pageId
  );
};

export const isStrictPageImageToken = (
  imageToken: ProjectImageToken
): imageToken is StrictPageProjectImageToken => {
  return typeof imageToken.options?.pageId === 'string';
};

export const isUniversalImageToken = (
  imageToken: ProjectImageToken
): imageToken is UniversalProjectImageToken => {
  return (
    !('pageId' in imageToken.options) ||
    typeof imageToken.options.pageId !== 'string'
  );
};

export const getPrioritizedImageToken = (
  imageToken1: ProjectImageToken,
  imageToken2: ProjectImageToken
): ProjectImageToken => {
  if (
    isStrictPageImageToken(imageToken2) &&
    !isStrictPageImageToken(imageToken1)
  ) {
    return imageToken2;
  }

  return imageToken1;
};
