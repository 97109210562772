import { FC, useMemo, MouseEvent } from 'react';

import { MediaWidget } from '../../../features/widgets/widgets.types';
import Image from '../../ui/Image';
import styles from './MediaWidgetImage.module.css';

interface MediaWidgetImage {
  widget: MediaWidget;
  onClick?: (event: MouseEvent<HTMLDivElement>) => void;
  onLoad?: (image: HTMLImageElement) => void;
}

const MediaWidgetImage: FC<MediaWidgetImage> = ({
  widget,
  onClick,
  onLoad,
}) => {
  const options = useMemo(() => widget.options.image, [widget.options?.image]);

  const width = useMemo(() => {
    return options.actualSize || !options.size ? 'auto' : `${options.size}%`;
  }, [options.actualSize, options.size]);

  const aspectRatio = useMemo(() => options.aspectRatio, [options.aspectRatio]);

  if (!widget.image_url) {
    return null;
  }

  return (
    <div className={styles.root} style={{ width, aspectRatio }}>
      <Image
        className={styles.image}
        src={widget.image_url}
        alt="modal image"
        onClick={onClick}
        onLoad={onLoad}
      />
    </div>
  );
};

export default MediaWidgetImage;
