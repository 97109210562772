import { ComponentProps } from 'react';
import {
  BaseWidget,
  HotspotWidget,
  TooltipWidget,
} from 'shared-components/src/features/widgets/widgets.types';
import {
  isAnchorBasedWidget,
  isAnchorBasedWidgetBackdropEnabled,
  isModalLikeWidget,
} from 'shared-components/src/features/widgets/widgets.utils';
import WidgetTooltipBackdropHtml from 'shared-components/src/components/widgets/WidgetTooltipBackdropHtml';
import WidgetDialogBackdrop from 'shared-components/src/components/widgets/WidgetDialogBackdrop';
import WidgetBackdropAnimation from 'shared-components/src/components/widgets/WidgetBackdropAnimation';

import { DemoPlayerWidget } from '../../types';

interface WidgetBackdropManagerHtmlProps
  extends Pick<
    ComponentProps<typeof WidgetBackdropAnimation>,
    'durationIn' | 'durationOut'
  > {
  rendererElement: HTMLElement;
  widget: DemoPlayerWidget<BaseWidget>;
  isMounted: boolean;
  animationDelayMs: number;
}

function WidgetBackdropManagerHtml({
  rendererElement,
  widget,
  isMounted,
  animationDelayMs,
  durationIn,
  durationOut,
}: WidgetBackdropManagerHtmlProps): JSX.Element {
  return (
    <WidgetBackdropAnimation
      delayIn={animationDelayMs}
      durationIn={durationIn}
      durationOut={durationOut}
      in={isMounted}
    >
      {isModalLikeWidget(widget) && <WidgetDialogBackdrop />}
      {isAnchorBasedWidget(widget) &&
        isAnchorBasedWidgetBackdropEnabled(widget) && (
          <WidgetTooltipBackdropHtml
            widget={widget as HotspotWidget | TooltipWidget}
            rendererElement={rendererElement as HTMLIFrameElement}
            isScaleEnabled
          />
        )}
    </WidgetBackdropAnimation>
  );
}

export default WidgetBackdropManagerHtml;
