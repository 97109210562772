import { useMemo, memo, CSSProperties } from 'react';

import WidgetBackdropWithSpot from '../WidgetBackdropWithSpot';
import styles from './WidgetTooltipBackdropImage.module.css';
import { WidgetBackdrop as WidgetBackdropType } from '../../../features/widgets/widgets.constants';
import WidgetSpotlight from '../WidgetSpotlight';
import {
  HotspotWidget,
  TooltipWidget,
  WidgetBackdropRect,
} from '../../../features/widgets/widgets.types';

interface TooltipBackdropImageProps {
  widget: (TooltipWidget | HotspotWidget) & {
    anchorBackdropRect: WidgetBackdropRect;
  };
  onClick: () => void;
}

function TooltipBackdropImage({
  widget,
  onClick,
}: TooltipBackdropImageProps): JSX.Element | null {
  const showBackdrop = widget.options.root.spot === WidgetBackdropType.Enabled;
  const showSpotlight = widget.options.root.hasSpotlight;
  const spotlightColor = widget.options.root.spotlightColor;

  const style = useMemo(() => {
    const result: CSSProperties = {};

    if (widget.anchorBackdropRect) {
      result.top = `${widget.anchorBackdropRect.y}%`;
      result.left = `${widget.anchorBackdropRect.x}%`;
      result.width = `${widget.anchorBackdropRect.width}%`;
      result.height = `${widget.anchorBackdropRect.height}%`;
    }

    // We need to capture clicks inside highlighted area for image demos
    result.pointerEvents = 'initial';

    return result;
  }, [widget.anchorBackdropRect]);

  return (
    <>
      {showBackdrop && (
        <WidgetBackdropWithSpot
          className={styles.root}
          style={style}
          onClick={onClick}
        />
      )}
      {showSpotlight && (
        <WidgetSpotlight
          style={style}
          color={spotlightColor}
          onClick={onClick}
        />
      )}
    </>
  );
}

export default memo(TooltipBackdropImage);
