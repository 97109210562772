import {
  AnyWidget,
  BaseWidget,
  WidgetId,
} from 'shared-components/src/features/widgets/widgets.types';
import { FlowlistItemTargetKind } from 'shared-components/src/features/flowlist/flowlist.constants';
import { WidgetKind } from 'shared-components/src/features/widgets/widgets.constants';
import { ProjectPageId } from 'shared-components/src/features/projectPages/projectPages.types';
import { Flow, FlowId } from 'shared-components/src/features/flow/flow.types';
import { isLeadCaptureLikeWidget } from 'shared-components/src/features/widgets/widgets.utils';

import { DemoPlayerFlow, DemoPlayerFlowsDict } from '../types';
import { DemoPlayerContextType } from './machine.types';
import { demoPlayerModel } from './machine.model';

export const assignNextWidgetEffect = demoPlayerModel.assign((context) => {
  const nextWidgetId = context.widgetsLinkedMap[
    context.currentWidgetId as WidgetId
  ]?.next as WidgetId;
  const nextWidget = context.widgetsDict[nextWidgetId] as AnyWidget;

  return {
    currentWidgetId: nextWidgetId,
    currentPageId: nextWidget.page_id, // we are fine if "current page" is same as a "next widget page"
  };
}, 'goNextWidget');
export const assignPrevWidgetEffect = demoPlayerModel.assign((context) => {
  const prevWidgetId = context.widgetsLinkedMap[
    context.currentWidgetId as WidgetId
  ]?.prev as WidgetId;
  const prevWidget = context.widgetsDict[prevWidgetId] as AnyWidget;

  return {
    currentWidgetId: prevWidgetId,
    currentPageId: prevWidget.page_id,
  };
}, 'goPrevWidget');
export const startNextFlowEffect = demoPlayerModel.assign((context) => {
  // get next flow id(expected to be there as we use "guard")
  const nextFlowId = context.flowsLinkedMap[
    context.currentFlowId as FlowId
  ] as FlowId;

  let nextPageId = null;
  let nextWidgetId = null;

  const nextFlow = context.flowsDict[nextFlowId] as DemoPlayerFlow;
  const nextFlowStartWidget = context.widgetsDict[nextFlow.widgets[0]];
  if (nextFlowStartWidget) {
    nextWidgetId = nextFlowStartWidget.id;
    nextPageId = nextFlowStartWidget.page_id;
  }

  return {
    currentFlowId: nextFlowId,
    currentWidgetId: nextWidgetId,
    currentPageId: nextPageId || context.currentPageId,
  };
});
export const resetFlowEffect = demoPlayerModel.assign((context) => {
  // If last widget is video then we need to keep it on screen
  if (
    context.currentWidgetId &&
    context.widgetsDict[context.currentWidgetId].kind === WidgetKind.VideoClip
  ) {
    return {};
  } else {
    return {
      currentFlowId: null,
      currentWidgetId: null,
    };
  }
});
export const autoStartChecklistEffect = demoPlayerModel.assign((context) => {
  // if checklist autoplay is enabled then:

  // 1. get first item checklist.items[0]
  const firstItem = context.checklist?.items[0];

  // incorrect checklist with an empty target item. Typically, it is a "onboarding 3 empty items" scenario
  if (!firstItem?.target_value) {
    return {};
  }

  if (firstItem?.target_kind === FlowlistItemTargetKind.Flow) {
    // 2. preset context with currentFlowId, currentWidgetId - those are required for "playback"
    const flowId = firstItem.target_value;
    const flow = context.flowsDict[flowId];

    if (!flow) {
      // do nothing if user created incorrect demo and there is no flow with current id
      return {};
    }

    const firstWidget = flow.widgets[0];
    const pageId = context.widgetsDict[firstWidget]?.page_id as ProjectPageId;

    return {
      currentFlowId: flowId,
      currentPageId: pageId,
      currentWidgetId: firstWidget,
    };
  } else {
    // do nothing if auto-start enabled and first item is not a "flowId"
  }
  return {};
});
export const requestedFlowStartEffect = demoPlayerModel.assign((context) => {
  const flow = context.flowsDict[
    context.config.requestedStartFlowId as FlowId
  ] as DemoPlayerFlow;
  const widget = context.widgetsDict[flow.widgets[0]];
  if (widget) {
    return {
      currentFlowId: flow.id,
      currentWidgetId: widget.id,
      currentPageId: widget.page_id,
    };
  }

  return {};
});
export const requestedWidgetStartEffect = demoPlayerModel.assign((context) => {
  const widget =
    context.widgetsDict[context.config.requestedStartWidgetId as WidgetId];
  if (widget) {
    return {
      currentFlowId: widget.flowId,
      currentWidgetId: widget.id,
      currentPageId: widget.page_id,
    };
  }

  return {};
});
export const autoStartFlowPlaybackEffect = demoPlayerModel.assign((context) => {
  const flow = context.flowsDict[context.flowIdSequence[0]];
  if (flow) {
    const widget = context.widgetsDict[flow.widgets[0]];
    if (widget) {
      const pageId = widget.page_id;

      return {
        currentFlowId: flow.id,
        currentWidgetId: widget.id,
        currentPageId: pageId,
      };
    }
  }

  return {};
});
export const startFlowFromChecklistEffect = demoPlayerModel.assign(
  (context, event, { state }) => {
    // incorrect checklist with an empty target item. Typically, it is a "onboarding 3 empty items" scenario
    if (!event.flowId) {
      return {};
    }

    // same flow is already. Just do nothing
    if (
      state?.matches('#demoplayer.tour.walkthrough') &&
      context.currentFlowId === event.flowId
    ) {
      return {};
    }

    const flow: DemoPlayerFlow | undefined = context.flowsDict[event.flowId];
    if (!flow) {
      // do nothing if user created incorrect demo and there is no flow with current id
      return {};
    }

    /**
     * Continue from previously dropped widget OR start from the begining if it was fully finished before
     */
    const flowProgress: DemoPlayerContextType['progress']['flows'][0] | void =
      context.progress.flows.find(
        (flowProgress) => flowProgress.id === flow.id
      );
    if (
      flowProgress?.widgets.length &&
      flow.widgets.length !== flowProgress.widgets.length
    ) {
      // `flow` is NOT finished, THEN continue from dropped step
      const continueWidget =
        context.widgetsDict[flowProgress.widgets.at(-1) as WidgetId];

      if (!continueWidget) {
        return {};
      }

      return {
        currentFlowId: continueWidget.flowId,
        currentPageId: continueWidget.page_id,
        currentWidgetId: continueWidget.id,
      };
    }

    const firstWidget = context.widgetsDict[flow.widgets[0]];
    if (!firstWidget) {
      // do nothing if user created incorrect demo and flow has no widgets(empty flow)
      return {};
    }

    // If user have visited all the widgets in the flow then we should reset his progress
    if (flow.widgets.length === flowProgress?.widgets.length) {
      return {
        currentFlowId: firstWidget.flowId,
        currentPageId: firstWidget.page_id,
        currentWidgetId: firstWidget.id,
        progress: {
          checklist: context.progress.checklist,
          flows: context.progress.flows.map((fp) => {
            if (fp.id === flowProgress.id) {
              fp.widgets = [];
            }

            return fp;
          }),
        },
      };
    }

    return {
      currentFlowId: firstWidget.flowId,
      currentPageId: firstWidget.page_id,
      currentWidgetId: firstWidget.id,
    };
  },
  'startFlowFromChecklist'
);

export const replayFlowFromChecklistEffect = demoPlayerModel.assign(
  (context, event) => {
    const flow: DemoPlayerFlow | undefined = context.flowsDict[event.flowId];

    if (!flow) {
      // do nothing if user created incorrect demo and there is no flow with current id
      return {};
    }

    const firstWidget = context.widgetsDict[flow.widgets[0]];

    if (!firstWidget) {
      // do nothing if user created incorrect demo and flow has no widgets(empty flow)
      return {};
    }

    return {
      currentFlowId: firstWidget.flowId,
      currentPageId: firstWidget.page_id,
      currentWidgetId: firstWidget.id,
      progress: {
        ...context.progress,
        flows: context.progress.flows.map((fp) => {
          if (fp.id === event.flowId) {
            fp.widgets = [];
          }
          return fp;
        }),
      },
    };
  },
  'replayFlowFromChecklist'
);

export const startFlowFromButton = demoPlayerModel.assign((context, event) => {
  // incorrect checklist with an empty target item. Typically, it is a "onboarding 3 empty items" scenario
  if (!event.flowId) {
    return {};
  }

  const flow = context.flowsDict[event.flowId];
  if (!flow) {
    // do nothing if user created incorrect demo and there is no flow with current id
    return {};
  }

  const firstWidget = flow.widgets[0] as WidgetId | undefined;
  const currentWidget = event.widgetId ? event.widgetId : firstWidget;

  if (!currentWidget) {
    // do nothing if user created incorrect demo and flow has no widgets(empty flow)
    return {};
  }

  const pageId = context.widgetsDict[currentWidget]?.page_id as ProjectPageId;

  return {
    currentFlowId: event.flowId,
    currentPageId: pageId,
    currentWidgetId: currentWidget,
  };
}, 'startFlowFromButton');

/**
 * When machine is initialized - we need to filter our "lead_capture" + "vendor_form" widgets,
 * if lead is already captured or the demo is being recorded.
 */
export const initialEffect = demoPlayerModel.assign((context) => {
  if (context.isLeadCaptured || context.config.isRecording) {
    return filterOutLeadCaptureLikeWidgets(context);
  }

  return {};
});

export const restartEffect = demoPlayerModel.assign((context) => {
  /**
   * Restart happens only IF 1 flow exists
   */
  const flowId = Object.keys(context.flowsDict)[0]; // there is only 1 flow to "restart" happen
  const flow = context.flowsDict[flowId as FlowId] as DemoPlayerFlow;

  const widgetId = flow.widgets[0];
  const pageId = context.widgetsDict[widgetId]?.page_id as ProjectPageId;

  // if flow has only lead capture widget which has been passed => empty flow
  if (!pageId || !widgetId) {
    return {
      currentFlowId: flowId,
      currentWidgetId: null,
    };
  }

  return {
    currentFlowId: flowId,
    currentPageId: pageId,
    currentWidgetId: widgetId,
  };
}, 'restartPlayer');
export const persistChecklistProgressEffect = demoPlayerModel.assign(
  (context, event) => {
    if (!context.progress.checklist.includes(event.itemId)) {
      return {
        progress: {
          ...context.progress,
          checklist: [...context.progress.checklist, event.itemId],
        },
      };
    }
    return {};
  },
  'trackChecklistItemVisit'
);
export const playFlowEffect = demoPlayerModel.assign((context) => {
  // track progress
  const checklistItem = context?.checklist?.items.find(
    (item) =>
      item.target_kind === FlowlistItemTargetKind.Flow &&
      item.target_value === context.currentFlowId
  );
  const nextProgressChecklist = [...context.progress.checklist];
  if (checklistItem && !nextProgressChecklist.includes(checklistItem.id)) {
    nextProgressChecklist.push(checklistItem.id);
  }

  const nextProgressFlows = [...context.progress.flows];
  if (context.currentFlowId && context.currentWidgetId) {
    let flowProgress = nextProgressFlows.find(
      (flowProgress) => flowProgress.id === context.currentFlowId
    );
    if (!flowProgress) {
      flowProgress = {
        id: context.currentFlowId,
        widgets: [],
      };
      nextProgressFlows.push(flowProgress);
    }
    if (!flowProgress.widgets.includes(context.currentWidgetId)) {
      flowProgress.widgets.push(context.currentWidgetId);
    }
  }

  return {
    progress: {
      checklist: nextProgressChecklist,
      flows: nextProgressFlows,
    },
  };
});
export const continueWalkthroughEffect = demoPlayerModel.assign((context) => {
  const { currentPageId, currentWidgetId, widgetsDict } = context;
  const widget = widgetsDict[currentWidgetId as WidgetId];

  if (widget.page_id !== currentPageId) {
    return {
      currentPageId: widget.page_id,
    };
  }

  return {};
}, 'continueWalkthrough');
export const captureLeadEffect = demoPlayerModel.assign((context) => {
  const {
    progress,
    widgetsLinkedMap: nextWidgetsLinkedMap,
    flowsDict: nextFlowsDict,
  } = filterOutLeadCaptureLikeWidgets(context);

  return {
    isLeadCaptured: true,
    flowsDict: nextFlowsDict,
    widgetsLinkedMap: {
      ...context.widgetsLinkedMap, // "lead_capture"|"vendor_form" are still presented, but not connected with other widgets
      ...nextWidgetsLinkedMap, // all widgets are connected in a way to skip "lead_capture"|"vendor_form"
    },
    progress,
  };
}, 'captureLead');

export const assignGoToWidgetEffect = demoPlayerModel.assign(
  (context, event) => {
    let targetWidget = context.widgetsDict[event.widgetId] as BaseWidget;
    const flowWidgets = context.flowsDict[targetWidget.flowId];

    if (!context.isLeadCaptured && flowWidgets && !context.config.isPreview) {
      // find first target or lead capture widget id
      const guardedTargetWidgetId = flowWidgets.widgets.find(
        (widgetId) =>
          widgetId === event.widgetId ||
          (context.widgetsDict[widgetId] &&
            isLeadCaptureLikeWidget(context.widgetsDict[widgetId]))
      );

      targetWidget =
        guardedTargetWidgetId && context.widgetsDict[guardedTargetWidgetId]
          ? context.widgetsDict[guardedTargetWidgetId]
          : targetWidget;
    }

    return {
      currentWidgetId: targetWidget.id,
      currentPageId: targetWidget.page_id,
    };
  },
  'goToWidget'
);

// effects -- end

/**
 * Removes lead capture like widgets from the context.
 * This is needed to prevent them from being shown in the walkthrough tour.
 *
 * @param context
 * @returns
 */
function filterOutLeadCaptureLikeWidgets(context: DemoPlayerContextType) {
  const nextFlowsDict: DemoPlayerFlowsDict = {};
  const nextWidgetsLinkedMap: DemoPlayerContextType['widgetsLinkedMap'] = {};

  context.flowIdSequence.forEach((flowId) => {
    const flow = context.flowsDict[flowId] as Flow;
    const flowWidgets = flow.widgets.filter(
      (widgetId) => !isLeadCaptureLikeWidget(context.widgetsDict[widgetId])
    );
    nextFlowsDict[flow.id] = {
      id: flow.id,
      widgets: flowWidgets,
    };

    flowWidgets.forEach((widgetId, index, arr) => {
      const next = arr[index + 1] || null;
      const prev = arr[index - 1] || null;
      nextWidgetsLinkedMap[widgetId] = {
        next,
        prev,
      };
    });
  });

  const nextProgressFlows = context.progress.flows.map((progressFlow) => {
    const nextProgressFlowWidgets = progressFlow.widgets.filter(
      (widgetId) => !isLeadCaptureLikeWidget(context.widgetsDict[widgetId])
    );
    const totalWidgetsCount = context.currentFlowId
      ? context.flowsDict[context.currentFlowId]?.widgets.length || 0
      : 0;
    return {
      id: progressFlow.id,
      widgets: nextProgressFlowWidgets,
      totalWidgetsCount,
    };
  });

  return {
    flowsDict: nextFlowsDict,
    widgetsLinkedMap: nextWidgetsLinkedMap,
    progress: {
      ...context.progress,
      flows: nextProgressFlows,
    },
  };
}
