import { useEffect, useRef } from 'react';
import debounce from 'lodash-es/debounce';

import {
  StorylaneLeadSubmitMessage,
  StorylaneLeadFormSubmitEvent,
  StorylaneDivId,
  StorylaneDivWidth,
} from './VendorLeadForm.constants';

export interface UseVendorFormSubmitProps {
  onSubmit: (data: {
    lead: {
      email?: string;
      externalId?: string;
    } & Partial<Record<string, string>>;
    proceed: boolean;
  }) => void;
}

/**
 * Listen for cross-origin lead submit event
 * @see {@link https://storylane.atlassian.net/l/cp/qpRm4kTn Docs}
 */
const useVendorFormSubmit = ({ onSubmit }: UseVendorFormSubmitProps): void => {
  useEffect(() => {
    /**
     * Deprecated event.
     * Listen to `CustomEvent` which should be dispatched from iframe
     */
    const handleEvent = (event: any) =>
      onSubmit({ lead: event.detail, proceed: true });
    window.document.addEventListener(
      StorylaneLeadFormSubmitEvent,
      handleEvent,
      false
    );
    return () => {
      window.document.removeEventListener(
        StorylaneLeadFormSubmitEvent,
        handleEvent,
        false
      );
    };
  }, [onSubmit]);
  useEffect(() => {
    /**
     * @see {@link https://storylane.atlassian.net/wiki/x/CgAI Docs}
     */
    const handleEvent = (messageEvent: MessageEvent) => {
      try {
        if (
          messageEvent.data?.message === StorylaneLeadSubmitMessage &&
          Boolean(messageEvent.data?.payload.lead)
        ) {
          onSubmit({
            lead: messageEvent.data.payload.lead,
            proceed: messageEvent.data.payload.proceed_step ?? true,
          });
        }
      } catch (error) {
        console.error(error);
      }
    };
    window.addEventListener('message', handleEvent);
    return () => {
      window.removeEventListener('message', handleEvent);
    };
  }, [onSubmit]);
};

interface useVendorFormScaleArg {
  disabled: boolean;
}
const useVendorFormScale = (arg: useVendorFormScaleArg) => {
  const { disabled } = arg;
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  useEffect(() => {
    if (disabled || !iframeRef.current) return;

    const iframeTopMostDocument = iframeRef.current.contentDocument;
    if (iframeTopMostDocument === null) {
      console.error(`vendor-form iframe top-most document is not available`);
      return;
    }

    const resizeListener = () => {
      if (iframeRef.current) {
        const formContainerDiv =
          iframeRef.current?.contentDocument?.getElementById(StorylaneDivId);
        if (formContainerDiv) {
          // TODO: https://storylane.atlassian.net/browse/STORY-2790 it's an experiment controlled by feature flag. Improve, optimize and refactor if it's approved in production by customers.
          formContainerDiv.style.width = `${StorylaneDivWidth}px`;
          formContainerDiv.style.transform = `scale(${
            iframeRef.current?.clientWidth / StorylaneDivWidth
          })`;
          formContainerDiv.style.transformOrigin = 'top left';
          formContainerDiv.style.position = 'absolute';
          formContainerDiv.style.top = '0';
          formContainerDiv.style.left = '0';
        }
      }
    };
    const targetWindow = iframeTopMostDocument.defaultView;
    if (!targetWindow) {
      console.warn(
        `Unable to listen for lead form iframe resize. Reason: ${targetWindow} is not available`
      );
      return;
    }
    resizeListener();
    iframeRef.current.addEventListener('load', () => {
      resizeListener();
    });
    const debouncedResizeListener = debounce(resizeListener, 200);
    targetWindow.addEventListener('resize', debouncedResizeListener);

    return () => {
      targetWindow?.removeEventListener('resize', debouncedResizeListener);
      debouncedResizeListener.cancel();
    };
  }, [disabled]);

  return {
    ref: iframeRef,
  };
};

export { useVendorFormSubmit, useVendorFormScale };
