import cx from 'classnames';
import { FC } from 'react';

import styles from './Skeleton.module.css';

interface SkeletonProps {
  /**
   * @deprecated DO NOT USE. Component should be isolated and have no "dangerous" in-place styles
   */
  className?: string;
  variant?: 'circular' | 'rectangular' | 'rounded';
  width?: number | string;
  height?: number | string;
  fill?: boolean;
  isAnimationDisabled?: boolean;
}
const Skeleton: FC<SkeletonProps> = (props) => {
  const {
    variant = 'rounded',
    className,
    width,
    height,
    fill = false,
    children,
    isAnimationDisabled = false,
  } = props;

  return (
    <div
      className={cx(
        styles.skeleton,
        {
          [styles.skeletonFill]: fill,
          [styles.skeletonRounded]: variant === 'rounded',
          [styles.skeletonRectangular]: variant === 'rectangular',
          [styles.skeletonCircular]: variant === 'circular',
          [styles.animated]: !isAnimationDisabled,
        },
        className
      )}
      style={{
        width,
        height,
      }}
    >
      {children}
    </div>
  );
};

export default Skeleton;
