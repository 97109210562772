import {
  useCallback,
  useContext,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
  VFC,
} from 'react';

import VideoContext from './video.context';

export interface VideoProps {
  className?: string;
  src: string;
  startTime?: number;
  endTime?: number;
  onEnded?: () => void;
  thumbnailUrl?: string;
  onVideoAutoplayFailed?: () => void;
}

const Video: VFC<VideoProps> = ({
  className,
  src,
  startTime,
  endTime,
  onEnded,
  thumbnailUrl,
  onVideoAutoplayFailed,
}) => {
  const videoRef = useRef<HTMLVideoElement>(null);
  const [isHlsInitialized, setIsHlsInitialized] = useState(false);

  const isVideoStoppedRef = useRef(false);

  const {
    isPlaying,
    isMuted,
    setIsMuted,
    currentTime,
    currentTimeRef,
    setCurrentTime,
    setIsPlaying,
    playbackRate,
    isHlsVideo,
    duration,
    setDuration,
    setHasAudio,
  } = useContext(VideoContext);

  // HLS library is not supported on iOS, but it natively supports .m3u8 format
  const isIos =
    typeof navigator !== 'undefined' &&
    /iPad|iPhone|iPod/.test(navigator.userAgent);

  const shouldUseHls = isHlsVideo && !isIos;

  useEffect(() => {
    if (startTime) {
      setCurrentTime(startTime);
    }
  }, [setCurrentTime, startTime]);

  // Sending onVideoReady event for no-hls videos
  useEffect(() => {
    if (!shouldUseHls) {
      const videoElement = videoRef.current;
      if (videoElement) {
        const handleLoadedMetadata = () => {
          const videoDuration = videoElement.duration;
          setDuration(videoDuration);
          // We can't tell if audio is available for no-hls videos so no audio set is done here
        };
        videoElement.addEventListener('loadedmetadata', handleLoadedMetadata);

        return () =>
          videoElement.removeEventListener(
            'loadedmetadata',
            handleLoadedMetadata
          );
      }
    }
  }, [shouldUseHls, setDuration]);

  useEffect(() => {
    if (shouldUseHls) {
      import('./enableVideoHls').then(({ default: enableVideoHls }) => {
        if (videoRef.current) {
          enableVideoHls({
            videoElement: videoRef.current,
            src,
            onBufferCreated: (hlsInstance) => {
              const videoDuration = videoRef.current?.duration || 0;
              const videoHasAudio = Boolean(hlsInstance.levels[0].audioCodec);

              setIsHlsInitialized(true);

              setDuration(videoDuration);
              setHasAudio(videoHasAudio);
            },
          });
        }
      });
    }
  }, [shouldUseHls, setDuration, setHasAudio, src]);

  // Perform autoplay if needed
  useEffect(() => {
    if (videoRef.current) {
      if (isPlaying) {
        if (videoRef.current.paused) {
          videoRef.current.play().catch(() => {
            // If video failed to play, then we try to play it again but muted
            if (videoRef.current) {
              setIsMuted(true);
              videoRef.current.muted = true;
              videoRef.current.play().catch((reason) => {
                // If video failed to play even when muted then we mark it as stopped and fire onVideoAutoplayFailed callback
                setIsPlaying(false);
                onVideoAutoplayFailed && onVideoAutoplayFailed();
                console.error('Can not play the video, reason: ' + reason);
              });
            }
          });
        }
      } else {
        if (!videoRef.current.paused) {
          videoRef.current.pause();
        }
      }
    }
  }, [isPlaying, onVideoAutoplayFailed, setIsMuted, setIsPlaying]);

  useLayoutEffect(() => {
    const videoElement = videoRef.current;
    // Video duration is zero until HLS is initialized, so we should not run interval in that case
    if (duration === 0) return;

    if (videoElement && isPlaying) {
      // cancelAnimationFrame(reqId) haven't worked for me here, so using a flag
      let cancelAnimationFrameRequest = false;
      const handler = function () {
        if (videoElement) {
          const curTime = videoElement.currentTime;
          // Update currentTimeRef while video is playing
          currentTimeRef.current = curTime;

          // Stop video when endTime is reached
          const videoEndTime = endTime
            ? Math.min(endTime, duration) // fallback in case end time exceeds video duration
            : duration;
          // subtract a little amount of time from video duration to make sure that we always end the video with this condition
          if (curTime >= videoEndTime - 0.016) {
            if (!isVideoStoppedRef.current) {
              isVideoStoppedRef.current = true;

              setIsPlaying(false);
              onEnded && onEnded();
            }
          }
        }

        if (!cancelAnimationFrameRequest) {
          requestAnimationFrame(handler);
        }
      };
      requestAnimationFrame(handler);

      return () => {
        cancelAnimationFrameRequest = true;
      };
    }
  }, [currentTimeRef, duration, endTime, isPlaying, onEnded, setIsPlaying]);

  useEffect(() => {
    const videoElement = videoRef.current;
    if (videoElement) {
      const endedHandler = () => {
        if (!isVideoStoppedRef.current) {
          isVideoStoppedRef.current = true;

          setIsPlaying(false);
          onEnded && onEnded();
        }
      };

      videoElement.addEventListener('ended', endedHandler);

      return () => videoElement.removeEventListener('ended', endedHandler);
    }
  }, [onEnded, setIsPlaying]);

  useEffect(() => {
    if (videoRef.current) {
      // We need to use this hack for iOS.
      // https://stackoverflow.com/questions/18266437/html5-video-currenttime-not-setting-properly-on-iphone
      if (isIos) {
        videoRef.current.load();
        videoRef.current.pause();
        videoRef.current.currentTime = currentTime;
        videoRef.current.play();
      } else {
        videoRef.current.currentTime = currentTime;
      }
    }
  }, [currentTime, isIos]);

  useEffect(() => {
    if (isPlaying) {
      isVideoStoppedRef.current = false;
    }
  }, [isPlaying]);

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.playbackRate = playbackRate;
    }
  }, [playbackRate, isHlsInitialized]); // effect should be called when `isHlsInitialized` changes

  const handleVideoPause = useCallback(() => {
    if (videoRef.current) {
      setCurrentTime(videoRef.current.currentTime);
    }
  }, [setCurrentTime]);

  return (
    <video
      ref={videoRef}
      className={className}
      src={shouldUseHls ? undefined : src} // if HLS is used, then src is provided by the library and will be overridden
      controls={false}
      playsInline={true}
      preload="auto"
      muted={isMuted}
      onPause={handleVideoPause}
      poster={thumbnailUrl}
    />
  );
};
Video.displayName = 'Video';

export default Video;
