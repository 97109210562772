import {
  HotspotWidget,
  LeadCaptureWidget,
  MediaWidget,
  PopupWidget,
  TooltipWidget,
} from 'shared-components/src/features/widgets/widgets.types';

import { DemoPlayerWidget } from '../types';

export const isWidgetPreviousButtonEnabled = <
  T extends
    | HotspotWidget
    | TooltipWidget
    | MediaWidget
    | LeadCaptureWidget
    | PopupWidget
>(
  widget: DemoPlayerWidget<T>
) => Boolean(widget._prevId) && widget.options.root.prev_button;
