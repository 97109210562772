import { createShadowHostIterator } from './createShadowHostIterator';

/**
 * To find and iterate through all shadow-dom DocumentFragment until bottom is reached
 */
export function iterateShadowDomDeep(cb: (doc: Document | ShadowRoot) => void) {
  return function iterateShadowDomInDocument<D extends Document | ShadowRoot>(
    root: D
  ): D {
    const shadowHostIterator = createShadowHostIterator(root);
    let nodeWithShadow: HTMLElement | null = null;
    while (
      (nodeWithShadow = shadowHostIterator.nextNode() as HTMLElement | null)
    ) {
      const shadowRoot = nodeWithShadow.shadowRoot as ShadowRoot;
      cb(shadowRoot);
      iterateShadowDomInDocument<ShadowRoot>(shadowRoot);
    }

    return root;
  };
}
