import { FC, ReactNode } from 'react';
import {
  HotspotWidget,
  TooltipWidget,
} from 'shared-components/src/features/widgets/widgets.types';
import { isModalLikeWidget } from 'shared-components/src/features/widgets/widgets.utils';

import TooltipPositionManagerHtml from './TooltipPositionManagerHtml';
import WidgetDialogPositionManager from '../WidgetDialogPositionManager';
import { DemoPlayerWidget } from '../../types';

interface WidgetPositionManagerHtmlProps {
  widget: DemoPlayerWidget;
  children: ReactNode;
  rendererElement: HTMLIFrameElement;
  isDemoPlayerSmallScreen: boolean;
}

const WidgetPositionManagerHtml: FC<WidgetPositionManagerHtmlProps> = ({
  children,
  widget,
  rendererElement,
  isDemoPlayerSmallScreen,
}) => {
  if (isModalLikeWidget(widget)) {
    return (
      <WidgetDialogPositionManager>{children}</WidgetDialogPositionManager>
    );
  }

  return (
    <TooltipPositionManagerHtml
      widget={widget as DemoPlayerWidget<TooltipWidget | HotspotWidget>}
      rendererElement={rendererElement}
      isDemoPlayerSmallScreen={isDemoPlayerSmallScreen}
    >
      {children}
    </TooltipPositionManagerHtml>
  );
};

export default WidgetPositionManagerHtml;
