import { FC, useEffect, useRef } from 'react';
import cx from 'classnames';

import styles from './WidgetAnimation.module.css';

interface WidgetAnimationProps {
  in: boolean;
  durationIn: number;
  durationOut: number;
  delayIn: number;
  delayOut?: number;
  slideIn?: 'top' | 'left' | 'right' | 'bottom';
  onExitAnimationEnd?: () => void;
  onEnterAnimationEnd?: () => void;
}

/**
 *
 * @returns
 * @see {@link https://storylane.atlassian.net/l/cp/K6ajNZNA Docs}
 */
const WidgetAnimation: FC<WidgetAnimationProps> = ({
  children,
  onExitAnimationEnd,
  onEnterAnimationEnd,
  delayIn,
  delayOut = 0,
  durationIn,
  durationOut,
  slideIn,
  in: inFlag,
}) => {
  const animationRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (inFlag) {
      const handleAnimationEnd = () => {
        onEnterAnimationEnd && onEnterAnimationEnd();
      };
      const animationElement = animationRef.current;
      animationElement?.addEventListener('animationend', handleAnimationEnd);
      return () => {
        animationElement?.removeEventListener(
          'animationend',
          handleAnimationEnd
        );
      };
    }
  }, [inFlag, onEnterAnimationEnd]);

  useEffect(() => {
    if (!inFlag) {
      const handleAnimationEnd = () => {
        onExitAnimationEnd && onExitAnimationEnd();
      };
      const animationElement = animationRef.current;
      animationElement?.addEventListener('animationend', handleAnimationEnd);
      return () => {
        animationElement?.removeEventListener(
          'animationend',
          handleAnimationEnd
        );
      };
    }
  }, [inFlag, onExitAnimationEnd]);

  return (
    <div
      ref={animationRef}
      className={cx(styles.widgetAnimation, {
        [styles.widgetAnimationSlide]: slideIn,
        [styles.widgetAnimationMounted]: inFlag,
      })}
      aria-label="Widget Animation"
      data-placement={slideIn}
      style={{
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        '--widget-animation-delay-in': `${delayIn}ms`,
        '--widget-animation-delay-out': `${delayOut}ms`,
        '--widget-animation-duration-in': `${durationIn}ms`,
        '--widget-animation-duration-out': `${durationOut}ms`,
      }}
    >
      {children}
    </div>
  );
};

export default WidgetAnimation;
