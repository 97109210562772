/**
 * This id should be assigned to "alignment" container.
 * It is used to scroll arrow-widget into view correctly.
 * Search for `DemoPlayerArrowWidgetId` to learn more.
 */
export const DemoPlayerArrowWidgetId = 'sl-demoplayer-arrow-widget';
export const EditorArrowWidgetId = 'sl-editor-arrow-widget';
export const DemoPlayerHotspotClickableAreaId =
  'sl-demoplayer-hotspot-click-area';

export const WidgetAnimationDurationInMs = 500; // 500ms
export const WidgetAnimationDurationOutMs = 300; // 300ms
export const WidgetAnimationDelayMs = 200; // 200ms
export const WidgetAnimationTranslationDelayMs = 700; // 700ms
export const NavigationBarDelayMs = 3000;

export const DefaultNavigationBarColor = '#9939EB';

export const DemoPlayerDefaultHtmlDimensions = {
  width: 1472,
  height: 828,
};
