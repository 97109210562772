import { VFC } from 'react';

import styles from './BrandedLoader.module.css';

const BrandedLoader: VFC = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      viewBox="0 0 34 34"
      className={styles.root}
    >
      <rect id="Rectangle_591" width="34" height="34" fill="none" />
      <g id="Logo_Symbol" transform="translate(10.022 5)">
        <path
          id="Path_1"
          d="M40.222,8.8l8.929-6.8A1.117,1.117,0,0,0,47.8.225L40.427,5.851a1.956,1.956,0,0,0-.383,2.74l.16.211a.013.013,0,0,0,.019,0"
          transform="translate(-39.649 0)"
          fill="#e52a8f"
          className={styles.path1}
        />
        <path
          id="Path_2"
          d="M50.04,12.221l5.711-4.312a1.127,1.127,0,1,0-1.358-1.8l-5.712,4.312Z"
          transform="translate(-43.87 -2.749)"
          fill="#e52a8f"
          className={styles.path2}
        />
        <rect
          id="Rectangle_2"
          width="2.233"
          height="5.79"
          transform="translate(1.436 10.148) rotate(-37.049)"
          fill="#e52a8f"
          className={styles.path3}
        />
        <rect
          id="Rectangle_1"
          width="2.232"
          height="5.748"
          transform="translate(7.185 10.735) rotate(-37.056)"
          fill="#e52a8f"
          className={styles.path4}
        />
        <path
          id="Path_4"
          d="M44.605,33.141l5.941-4.473L49.2,26.882l-5.941,4.473a1.119,1.119,0,0,0,1.348,1.786"
          transform="translate(-41.127 -12.562)"
          fill="#e52a8f"
          className={styles.path5}
        />
        <path
          id="Path_3"
          d="M49.068,37.124,56.4,31.591a1.957,1.957,0,0,0,.383-2.741l-.159-.21h0l-8.889,6.71a1.112,1.112,0,0,0,1.34,1.775"
          transform="translate(-43.217 -13.383)"
          fill="#e52a8f"
          className={styles.path6}
        />
      </g>
    </svg>
  );
};

export default BrandedLoader;
