import { memo, CSSProperties, VFC } from 'react';
import cx from 'classnames';

import styles from './WidgetText.module.css';

export interface WidgetTextProps extends React.HTMLAttributes<HTMLDivElement> {
  value: string;
  className?: string;
  style?: CSSProperties;
  id?: string;
}

const WidgetText: VFC<WidgetTextProps> = (props) => {
  const { value, className, ...restProps } = props;

  return value.length !== 0 ? (
    <div
      className={cx(styles.text, className)}
      dangerouslySetInnerHTML={{ __html: value }}
      {...restProps}
    />
  ) : null;
};

export default memo(WidgetText);
