import { FlowId } from 'shared-components/src/features/flow/flow.types';
import { FlowlistItemTargetKind } from 'shared-components/src/features/flowlist/flowlist.constants';

import { DemoPlayerFlowlist } from '../types';

export const generateFlowIdSequence = (arg: {
  flowlist: DemoPlayerFlowlist | null;
  flowIdSequence: FlowId[];
}): FlowId[] => {
  const { flowIdSequence, flowlist } = arg;
  if (!flowlist) {
    return flowIdSequence;
  }

  return flowlist.items
    .filter(
      (flItem) =>
        flItem.target_kind === FlowlistItemTargetKind.Flow &&
        Boolean(flItem.target_value)
    )
    .map((flItem) => flItem.target_value);
};
