import { useState, FC } from 'react';
import { WidgetPlacement } from 'shared-components/src/features/widgets/widgets.types';

import {
  DemoPlayerWidgetContext,
  WidgetPageScrollPositionState,
} from '../../contexts/DemoPlayerWidgetContext';
import { Dictionary } from '../../global';
import { DemoPlayerConfig } from '../../types';

interface DemoPlayerWidgetStateProviderProps {
  config: DemoPlayerConfig;
}

const DemoPlayerWidgetStateProvider: FC<DemoPlayerWidgetStateProviderProps> = ({
  config,
  children,
}) => {
  const [displayWidget, setDisplayWidget] = useState(true);

  // @see {@link https://storylane.atlassian.net/l/cp/yq69oN1c Widget Placement Docs}
  const [arrowWidgetAutoPlacement, setArrowWidgetAutoPlacement] = useState<
    Dictionary<WidgetPlacement>
  >({});

  const [prevWidgetPageScrollPosition, setPrevWidgetPageScrollPosition] =
    useState<WidgetPageScrollPositionState>(null);

  return (
    <DemoPlayerWidgetContext.Provider
      value={{
        displayWidget,
        setDisplayWidget,
        arrowWidgetAutoPlacement,
        setArrowWidgetAutoPlacement,
        prevWidgetPageScrollPosition,
        setPrevWidgetPageScrollPosition,
        isWidgetScrollAnimationDisabled: Boolean(
          config.isWidgetScrollAnimationDisabled
        ),
      }}
    >
      {children}
    </DemoPlayerWidgetContext.Provider>
  );
};

export default DemoPlayerWidgetStateProvider;
