import cx from 'classnames';
import { DisplayConfigType } from 'shared-components/src/features/project/project.types';
import { FC } from 'react';
import Button from 'shared-components/src/components/Button';
import { Emoji } from 'emoji-picker-react';

import styles from './CustomCtaLaunchButton.module.css';

interface CustomCtaLaunchButtonProps {
  playerConfig: DisplayConfigType;
  onCustomCtaExtUrl: () => void;
  isResponsiveEnabled: boolean;
}

const CustomCtaLaunchButton: FC<CustomCtaLaunchButtonProps> = ({
  playerConfig,
  onCustomCtaExtUrl,
  isResponsiveEnabled,
}) => {
  const { cta_align, cta_text, cta_background_color, cta_color, cta_emoji } =
    playerConfig;

  return (
    <>
      <Button
        data-position={cta_align}
        size="lg"
        data-testid="custom-cta-launch-button"
        onClick={onCustomCtaExtUrl}
        className={cx(styles.root, styles.previewCustomCta, {
          [styles.rootResponsive]: isResponsiveEnabled,
        })}
        style={{
          background: cta_background_color,
          color: cta_color,
        }}
      >
        <span>
          <div className={styles.emojiWrapper}>
            <Emoji
              lazyLoad
              unified={cta_emoji}
              size={20} // This size can be adjusted as needed
            />
          </div>
          <span data-testid="custom-cta-launch-text">{cta_text}</span>
        </span>
        <div
          className={`${styles.previewCustomCtaRipple}`}
          style={{
            position: 'absolute',
            border: cta_background_color
              ? `1px solid ${cta_background_color}`
              : undefined,
            inset: 0,
            borderRadius: '6px',
            pointerEvents: 'none',
          }}
        >
          <div
            className={`${styles.previewCustomCtaRippleInner}`}
            style={{
              boxShadow: cta_background_color
                ? `${cta_background_color} 0px 0px 4px 4px`
                : undefined,
              opacity: 0,
              borderRadius: 'inherit',
              position: 'absolute',
              inset: '0',
            }}
          />
        </div>
      </Button>
    </>
  );
};

export default CustomCtaLaunchButton;
