import { useCallback, useEffect, useState } from 'react';
import uniqWith from 'lodash-es/uniqWith';
import { FlowId } from 'shared-components/src/features/flow/flow.types';
import { ProjectPageKind } from 'shared-components/src/features/projectPages/projectPages.constants';
import { WidgetKind } from 'shared-components/src/features/widgets/widgets.constants';
import {
  VideoClipWidget,
  BaseWidget,
  WidgetId,
  WithCta,
  WithSecondary,
} from 'shared-components/src/features/widgets/widgets.types';
import { RendererImagePageToFileMapItem } from 'shared-components/src/features/renderer/renderer.types';
import {
  isVendorForm,
  isVideoClip,
} from 'shared-components/src/features/widgets/widgets.utils';

import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';

const MAX_FETCH_COUNT = 3;

/**
 * Return page's image source url
 */
function usePageImages(): {
  image: RendererImagePageToFileMapItem | undefined;
  allImages: RendererImagePageToFileMapItem[];
} {
  const {
    currentPageId,
    currentWidgetId,
    widgetsLinkedMap,
    flowsLinkedMap,
    widgetsDict,
    flowsDict,
    pageToFileUrl,
  } = useRendererPlayerState();
  const currentWidget = currentWidgetId ? widgetsDict[currentWidgetId] : null;
  const [value, setValue] = useState<
    RendererImagePageToFileMapItem | undefined
  >(undefined);
  const [allValues, setAllValues] = useState<RendererImagePageToFileMapItem[]>(
    []
  );

  const getPageInfoByWidget = useCallback(
    (widget: BaseWidget): RendererImagePageToFileMapItem => {
      const widgetPageId = widget.page_id;
      if (widget.kind === WidgetKind.VideoClip) {
        const videoWidget = widget as VideoClipWidget;
        const isDeprecatedTrimmedWidget =
          videoWidget.stream_url && !videoWidget.options.clip;
        return {
          type: ProjectPageKind.Video,
          url: isDeprecatedTrimmedWidget
            ? videoWidget.stream_url || ''
            : pageToFileUrl[widgetPageId]?.url || '',
          startTime: videoWidget.options.clip?.start_time,
          endTime: videoWidget.options.clip?.end_time,
          videoWidgetId: videoWidget.id,
          isVideoMuted: videoWidget.options.clip?.isMuted,
          videoPlaybackRate: videoWidget.options.clip?.playbackRate,
          isFromLocalStorage: pageToFileUrl[widgetPageId]?.isFromLocalStorage,
          thumbnailUrl: pageToFileUrl[widgetPageId]?.thumbnailUrl,
        };
      } else {
        return pageToFileUrl[widgetPageId];
      }
    },
    [pageToFileUrl]
  );

  useEffect(() => {
    if (currentWidget && currentWidget.kind === WidgetKind.VideoClip) {
      const videoWidget = currentWidget as VideoClipWidget;
      const isDeprecatedTrimmedWidget =
        videoWidget.stream_url && !videoWidget.options.clip;
      setValue({
        type: ProjectPageKind.Video,
        url: isDeprecatedTrimmedWidget
          ? videoWidget.stream_url || ''
          : pageToFileUrl[currentPageId]?.url || '',
        startTime: videoWidget?.options.clip?.start_time,
        endTime: videoWidget?.options.clip?.end_time,
        videoWidgetId: videoWidget.id,
        isVideoMuted: (currentWidget as VideoClipWidget)?.options.clip?.isMuted,
        videoPlaybackRate: (currentWidget as VideoClipWidget)?.options.clip
          ?.playbackRate,
        isFromLocalStorage: pageToFileUrl[currentPageId]?.isFromLocalStorage,
        thumbnailUrl: pageToFileUrl[currentPageId]?.thumbnailUrl,
      });
    } else {
      // TODO https://storylane.atlassian.net/browse/STORY-2164
      if (
        !pageToFileUrl[currentPageId] ||
        pageToFileUrl[currentPageId]?.type === ProjectPageKind.Video
      )
        return;

      setValue(pageToFileUrl[currentPageId]);
    }
  }, [currentPageId, currentWidget, pageToFileUrl]);

  // Fetching up to MAX_FETCH_COUNT next images or videos
  useEffect(() => {
    const valuesForCurrentWidget: RendererImagePageToFileMapItem[] = [];
    let curId = currentWidgetId;

    if (value) {
      valuesForCurrentWidget.push(value);
    }

    while (valuesForCurrentWidget.length < MAX_FETCH_COUNT && curId) {
      const currentWidget = curId ? widgetsDict[curId] : null;
      if (currentWidget) {
        // next widget AND same flow
        const nextWidgetId = widgetsLinkedMap[currentWidget.id]?.next;
        if (nextWidgetId) {
          const nextWidget = widgetsDict[nextWidgetId];
          valuesForCurrentWidget.push(getPageInfoByWidget(nextWidget));
        }

        // next flow AND next widget
        const nextFlowId = flowsLinkedMap[currentWidget.flowId];
        if (!nextWidgetId && nextFlowId) {
          const flowWidgetId = flowsDict[nextFlowId]?.widgets[0];
          if (flowWidgetId) {
            valuesForCurrentWidget.push(
              getPageInfoByWidget(widgetsDict[flowWidgetId])
            );
          }
        }

        // alternative flow
        if (!isVendorForm(currentWidget) && !isVideoClip(currentWidget)) {
          const nextCtaFlowId = (currentWidget.options as unknown as WithCta)
            .cta.flowId;
          const nextCtaWidgetId = (currentWidget.options as unknown as WithCta)
            .cta.widgetId;

          const nextSecondaryFlowId = (
            currentWidget.options as unknown as WithSecondary
          ).secondary.flowId;
          const nextSecondaryWidgetId = (
            currentWidget.options as unknown as WithSecondary
          ).secondary.widgetId;

          const addValueForCurrentWidget = (
            flowId: FlowId,
            widgetId?: WidgetId | null
          ) => {
            const flowWidgetId = widgetId || flowsDict[flowId]?.widgets[0];
            if (flowWidgetId && widgetsDict[flowWidgetId]) {
              valuesForCurrentWidget.push(
                getPageInfoByWidget(widgetsDict[flowWidgetId])
              );
            }
          };

          if (nextCtaFlowId) {
            addValueForCurrentWidget(nextCtaFlowId, nextCtaWidgetId);
          }

          if (nextSecondaryFlowId) {
            addValueForCurrentWidget(
              nextSecondaryFlowId,
              nextSecondaryWidgetId
            );
          }
        }

        curId = nextWidgetId || null;
      } else {
        break;
      }
    }

    setAllValues((prevAllValues) => {
      return uniqWith(
        [...prevAllValues, ...valuesForCurrentWidget],
        (value1, value2) => {
          if (value1.type === ProjectPageKind.Video) {
            return (
              value1.url === value2.url &&
              value1.videoWidgetId === value2.videoWidgetId
            );
          } else {
            return value1.url === value2.url;
          }
        }
      );
    });
  }, [
    value,
    currentWidgetId,
    flowsDict,
    flowsLinkedMap,
    getPageInfoByWidget,
    widgetsDict,
    widgetsLinkedMap,
  ]);

  return {
    image: value,
    allImages: allValues,
  };
}

export default usePageImages;
