import { VFC } from 'react';
import cx from 'classnames';

import styles from './ReplayButton.module.css';
import CustomizableButton from '../CustomizableButton';
import { DemoPlayerButtonConfig } from '../../types';

interface ReplayButtonProps {
  config: DemoPlayerButtonConfig | null;
  onClick: () => void;
  isResponsive: boolean;
  isHtmlProject?: boolean;
}
/**
 * "Replay" button to exit "interactive" tour and restart "walkthrough" tour.
 * @see {@link https://storylane.atlassian.net/wiki/spaces/ENGINEERIN/pages/720926/Demo+Player#Resume-Button Docs}
 *
 */
const ReplayButton: VFC<ReplayButtonProps> = (props) => {
  const { onClick, config, isHtmlProject, isResponsive } = props;

  return (
    <CustomizableButton
      aria-label="Replay demo button"
      id="replay_demo_button"
      className={cx({
        [styles.replayButton]: isHtmlProject,
      })}
      text={config?.value || 'Replay Demo'}
      bgColor={config?.backgroundColor || '#E0007A'}
      onClick={onClick}
      color={config?.color || '#FFFFFF'}
      isResponsive={isResponsive}
    />
  );
};

export default ReplayButton;
