import { createModel } from 'xstate/lib/model';
import { ProjectPageId } from 'shared-components/src/features/projectPages/projectPages.types';
import { FlowId } from 'shared-components/src/features/flow/flow.types';
import { WidgetId } from 'shared-components/src/features/widgets/widgets.types';

import { DemoPlayerLead } from '../types';
import { DemoPlayerContextType } from './machine.types';

type DemoPlayerCreators = {
  events: {
    goToPage: (pageId: ProjectPageId) => { pageId: ProjectPageId };
    continueWalkthrough: () => Record<string, never>;
    goNextWidget: () => Record<string, never>;
    goPrevWidget: () => Record<string, never>;
    startPlayer: () => Record<string, never>;
    startFlowFromChecklist: (flowId: FlowId) => {
      flowId: FlowId;
    };
    startFlowFromButton: (
      flowId: FlowId,
      widgetId?: WidgetId | null
    ) => {
      flowId: FlowId;
      widgetId?: WidgetId | null;
    };
    trackChecklistItemVisit: (itemId: string) => {
      itemId: string;
    };
    dismissWidget: () => Record<string, never>;
    showWidget: () => Record<string, never>;
    maximizeChecklist: () => Record<string, never>;
    minimizeChecklist: () => Record<string, never>;
    restartPlayer: () => Record<string, never>;
    captureLead: (lead: DemoPlayerLead) => {
      lead: DemoPlayerLead;
    };
    goToWidget: (widgetId: WidgetId) => { widgetId: WidgetId };
    replayFlowFromChecklist: (flowId: FlowId) => { flowId: FlowId };
  };
};
export const demoPlayerModel = createModel<
  DemoPlayerContextType,
  DemoPlayerCreators
>(
  {
    checklist: null,
    currentPageId: '',
    config: {
      startPageId: '',
      requestedStartFlowId: null,
      requestedStartWidgetId: null,
      isWalkthroughTourDisabled: false,
      isPreview: false,
      isReplayEnabled: true,
      isRecording: false,
    },
    currentFlowId: null,
    currentWidgetId: null,
    flowsDict: {},
    flowIdSequence: [],
    widgetsDict: {},
    flowsLinkedMap: {},
    widgetsLinkedMap: {},
    isLeadCaptured: false,
    pageIdSequence: [],
    pageLinks: {},
    progress: {
      flows: [],
      checklist: [],
    },
  },
  {
    events: {
      goToPage: (pageId) => ({ pageId }),
      continueWalkthrough: () => ({}),
      goNextWidget: () => ({}),
      goPrevWidget: () => ({}),
      startPlayer: () => ({}),
      startFlowFromChecklist: (flowId: FlowId) => ({
        flowId,
      }),
      startFlowFromButton: (flowId: FlowId, widgetId?: WidgetId | null) => ({
        flowId,
        widgetId,
      }),
      trackChecklistItemVisit: (itemId: string) => ({
        itemId,
      }),
      dismissWidget: () => ({}),
      showWidget: () => ({}),
      maximizeChecklist: () => ({}),
      minimizeChecklist: () => ({}),
      restartPlayer: () => ({}),
      captureLead: (lead: DemoPlayerLead) => ({
        lead,
      }),
      goToWidget: (widgetId) => ({ widgetId }),
      replayFlowFromChecklist: (flowId) => ({ flowId }),
    },
  }
);
