let requestTimerId: number | null | undefined;

/**
 * Callback returns true => unsubscribe
 */
type TCallback<ARGS = unknown> = (..._: ARGS[]) => boolean;

type TSubscriberParams<ARGS = unknown> = {
  cb: TCallback<ARGS>;
  _this?: unknown;
  args?: ARGS[];
  interval?: number;
  elapsedTime: number;
};
let prevFrameNow = -1;
const subscribers: Array<unknown> = [];

export function addRAF<ARGS = unknown>(
  frameParams: Omit<TSubscriberParams<ARGS>, 'elapsedTime'>
) {
  removeRAF<ARGS>(frameParams.cb);
  subscribers.push({
    ...frameParams,
    elapsedTime: 0,
  } as TSubscriberParams<ARGS>);
  runRAF();
}

export function findInRAF<ARGS = unknown>(
  cb: TCallback<ARGS>
): TSubscriberParams<ARGS> | undefined {
  return (subscribers as Array<TSubscriberParams<ARGS>>).find(
    (_cb) => _cb.cb === cb
  );
}

export function removeRAF<ARGS = unknown>(cb: TCallback<ARGS>) {
  const found = findInRAF(cb);
  const index = subscribers.indexOf(found);
  if (index > -1) {
    subscribers.splice(index, 1);
  }
}

export function runRAF() {
  prevFrameNow = Date.now();
  if (subscribers.length && !requestTimerId) {
    requestTimerId = requestAnimationFrame(frame);
  }
}

export function cancelRAF() {
  if (requestTimerId) {
    cancelAnimationFrame(requestTimerId);
    requestTimerId = null;
  }
}

function frame<T = unknown>(this: unknown) {
  requestTimerId = null;
  const frameNow = Date.now();
  const deltaMS = frameNow - prevFrameNow;
  for (let i = 0; i < subscribers.length; i++) {
    const subscriber = (subscribers as Array<TSubscriberParams<T>>)[i];
    let triggered = true;
    if (typeof subscriber.interval === 'number') {
      if (subscriber.elapsedTime >= subscriber.interval) {
        subscriber.elapsedTime = 0;
        triggered = true;
      } else {
        subscriber.elapsedTime += deltaMS;
        triggered = false;
      }
    }
    if (
      triggered &&
      subscriber.cb.apply(
        subscriber._this ?? this,
        Array.isArray(subscriber.args) ? subscriber.args : []
      )
    ) {
      subscribers.splice(i, 1);
      i--;
    }
  }
  runRAF();
}
