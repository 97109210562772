import {
  CSSProperties,
  useCallback,
  useEffect,
  useMemo,
  useState,
  VFC,
} from 'react';
import { WhitelabelLoader } from 'shared-components/src/features/whitelabel/whitelabel.constants';
import RendererHtml from 'shared-components/src/components/renderer/RendererHtml';
import RendererHtmlNew from 'shared-components/src/components/renderer/RendererHtmlNew';
import Spinner from 'shared-components/src/components/ui/Spinner';
import BrandedLoader from 'shared-components/src/components/ui/BrandedLoader';
import debounce from 'lodash-es/debounce';
import { injectProjectScriptsIntoDocument } from 'shared-components/src/utils/injectProjectScriptsIntoDocument';

import { usePageHtmls } from './DemoPlayerRendererHtml.hooks';
import styles from './DemoPlayerRendererHtml.module.css';
import useDemoPlayerImageTokens from './useDemoPlayerImageTokens';
import { useRendererPlayerState } from '../../hooks/useRendererPlayerState';
import { hideTypewriterElements } from './DemoPlayerRendererHtml.utils';

interface RendererDivStyles extends CSSProperties {
  '--renderer-scale-factor': number;
  '--renderer-scale-height': string;
}

interface DemoPlayerRendererHtmlProps {
  isRendererReady: boolean;
  onReady: (isReady: boolean) => void;
}

const DemoPlayerRendererHtml: VFC<DemoPlayerRendererHtmlProps> = ({
  onReady,
  isRendererReady,
}) => {
  const {
    rendererHtmlLoader,
    setRendererElement,
    setWidgetSlotElement,
    screenSizeWarningWidth,
    currentPageId,
    projectIframeScripts,
    isInteractiveState,
    rendererWrapper,
    isRendererHtmlScaleEnabled,
    isHtmlPreRenderingEnabled,
    rendererDimensions,
    isShadowDomEnabled,
  } = useRendererPlayerState();

  const { pageHtml } = usePageHtmls(isRendererReady);
  const { replaceImageTokens } = useDemoPlayerImageTokens();
  const injectCustomScripts = useCallback(
    (loadedDocument: Document) => {
      if (
        loadedDocument &&
        loadedDocument.body // according to TS - `body` is always presented, but that's not true.
      ) {
        projectIframeScripts.forEach((script) =>
          injectProjectScriptsIntoDocument(loadedDocument, script)
        );
      }
      return loadedDocument;
    },
    [projectIframeScripts]
  );

  const handleRendererDocumentLoad = useCallback(
    (loadedDocument: Document) => {
      return hideTypewriterElements(
        replaceImageTokens(injectCustomScripts(loadedDocument))
      );
    },
    [injectCustomScripts, replaceImageTokens]
  );

  /**
   * Calculate and Apply CSS `transform: scale(x);` to improve UX.
   * @see {@link https://storylane.atlassian.net/l/cp/xf6LLZBQ Renderer Scaling Docs}
   */
  const [scale, setScale] = useState({ factor: 1, height: 0 });

  // TODO: change this after server side user agent detection/lib will be implemented
  const isIphone = false; // TODO: https://storylane.atlassian.net/browse/STORY-3317
  // typeof navigator !== 'undefined' && /iPhone/.test(navigator.userAgent);

  useEffect(() => {
    if (isIphone) return;

    const debouncedResizeListener = debounce(() => {
      if (rendererWrapper.current) {
        setScale({
          factor:
            rendererWrapper.current.clientWidth / rendererDimensions.width,
          height: rendererWrapper.current.clientHeight,
        });
      }
    }, 200);

    if (isRendererHtmlScaleEnabled) {
      debouncedResizeListener();
      window.addEventListener('resize', debouncedResizeListener);
    }

    return () => {
      window.removeEventListener('resize', debouncedResizeListener);
      debouncedResizeListener.cancel();
    };
  }, [
    rendererWrapper,
    pageHtml,
    isRendererHtmlScaleEnabled,
    rendererDimensions,
    isIphone,
  ]);

  const rendererStyles = useMemo(
    () => ({
      width:
        isRendererHtmlScaleEnabled && !isIphone
          ? rendererDimensions.width
          : undefined,
      height:
        isRendererHtmlScaleEnabled && !isIphone
          ? `calc(var(--renderer-scale-height) / var(--renderer-scale-factor))`
          : undefined,
      minWidth: screenSizeWarningWidth || 0,
    }),
    [
      isRendererHtmlScaleEnabled,
      isIphone,
      rendererDimensions.width,
      screenSizeWarningWidth,
    ]
  );

  if (!pageHtml) {
    return (
      <div className={styles.root} ref={rendererWrapper}>
        {rendererHtmlLoader === WhitelabelLoader.Default && (
          <div className={styles.preloader}>
            <BrandedLoader />
          </div>
        )}
        {rendererHtmlLoader === WhitelabelLoader.Spinner && (
          <div className={styles.preloader}>
            <Spinner variant="gray" />
          </div>
        )}
        {rendererHtmlLoader === WhitelabelLoader.None && null}
      </div>
    );
  }

  return (
    <div className={styles.root} ref={rendererWrapper}>
      <div
        className={styles.scale}
        data-enabled={isRendererHtmlScaleEnabled && !isIphone}
        style={
          {
            '--renderer-scale-factor': scale.factor,
            '--renderer-scale-height': `${scale.height}px`,
          } as RendererDivStyles
        }
      >
        {isHtmlPreRenderingEnabled ? (
          <RendererHtmlNew
            styles={rendererStyles}
            onDocumentLoad={handleRendererDocumentLoad}
            refCB={setRendererElement}
            contentKey={currentPageId}
            content={pageHtml}
            onReady={onReady}
            isResetScroll={!isInteractiveState}
            isShadowDomEnabled={isShadowDomEnabled}
          />
        ) : (
          <RendererHtml
            styles={rendererStyles}
            onDocumentLoad={handleRendererDocumentLoad}
            refCB={setRendererElement}
            contentKey={currentPageId}
            content={pageHtml}
            onReady={onReady}
            isResetScroll={!isInteractiveState}
            isShadowDomEnabled={isShadowDomEnabled}
          />
        )}
      </div>
      <div
        ref={setWidgetSlotElement}
        className={styles.widgetSlot}
        style={
          {
            '--renderer-scale-factor': scale.factor,
          } as RendererDivStyles
        }
      />
    </div>
  );
};

export default DemoPlayerRendererHtml;
